// Generated by ReScript, PLEASE EDIT WITH CARE

import * as LumiReact from "lumi-rescript/lib/es6/src/LumiReact.bs.mjs";
import * as FontAwesome from "lumi-rescript/lib/es6/src/FontAwesome.bs.mjs";
import * as Locale$LumiI18n from "lumi-i18n/lib/es6/src/Locale.bs.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as I18n$LumiAuthentication from "./I18n.bs.mjs";

function Form$Field(props) {
  var onChange = props.onChange;
  var __className = props.className;
  var className = __className !== undefined ? __className : "";
  var locale = Locale$LumiI18n.useContext();
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("p", {
                    children: [
                      JsxRuntime.jsx("input", {
                            className: "input " + className,
                            autoComplete: props.autoComplete,
                            name: props.name,
                            placeholder: I18n$LumiAuthentication.T.$$Text.string(locale, props.placeholder),
                            required: true,
                            type: props.type_,
                            value: props.value,
                            onChange: (function (e) {
                                onChange(e.target.value);
                              })
                          }),
                      JsxRuntime.jsx("span", {
                            children: props.icon,
                            className: "icon is-small is-left"
                          })
                    ],
                    className: "control has-icons-left"
                  }),
              className: "field"
            });
}

var Field = {
  make: Form$Field
};

function Form$EmailField(props) {
  var value = props.value;
  var error = value !== "" && !props.valid;
  return JsxRuntime.jsx(Form$Field, {
              type_: "email",
              name: "email",
              autoComplete: "username",
              value: value,
              className: error ? "is-danger" : "",
              placeholder: "PhraseEmail",
              onChange: props.onChange,
              icon: JsxRuntime.jsx(FontAwesome.Regular.make, {
                    name: "envelope"
                  })
            });
}

var EmailField = {
  make: Form$EmailField
};

function Form$ExistingPasswordField(props) {
  var value = props.value;
  var error = value !== "" && !props.valid;
  return JsxRuntime.jsx(Form$Field, {
              type_: "password",
              name: "password",
              autoComplete: "current-password",
              value: value,
              className: error ? "is-danger" : "",
              placeholder: "PhrasePassword",
              onChange: props.onChange,
              icon: JsxRuntime.jsx(FontAwesome.Solid.make, {
                    name: "lock"
                  })
            });
}

var ExistingPasswordField = {
  make: Form$ExistingPasswordField
};

function Form$NewPasswordField(props) {
  var value = props.value;
  var error = value !== "" && !props.valid;
  return JsxRuntime.jsx(Form$Field, {
              type_: "password",
              name: "new-password",
              autoComplete: "new-password",
              value: value,
              className: error ? "is-danger" : "",
              placeholder: "PhraseEnterNewPassword",
              onChange: props.onChange,
              icon: JsxRuntime.jsx(FontAwesome.Solid.make, {
                    name: "lock"
                  })
            });
}

var NewPasswordField = {
  make: Form$NewPasswordField
};

function Form$VerifyPasswordField(props) {
  var value = props.value;
  var error = value !== "" && !props.valid;
  return JsxRuntime.jsx(Form$Field, {
              type_: "password",
              name: "verify-password",
              autoComplete: "new-password",
              value: value,
              className: error ? "is-danger" : "",
              placeholder: "PhraseEnterNewPasswordAgain",
              onChange: props.onChange,
              icon: JsxRuntime.jsx(FontAwesome.Solid.make, {
                    name: "lock"
                  })
            });
}

var VerifyPasswordField = {
  make: Form$VerifyPasswordField
};

function Form$Submit(props) {
  var __disabled = props.disabled;
  var __classList = props.classList;
  var classList = __classList !== undefined ? __classList : [];
  var disabled = __disabled !== undefined ? __disabled : false;
  var locale = Locale$LumiI18n.useContext();
  return JsxRuntime.jsx("input", {
              className: LumiReact.classList(classList),
              disabled: disabled,
              type: "submit",
              value: I18n$LumiAuthentication.T.$$Text.string(locale, props.label)
            });
}

var Submit = {
  make: Form$Submit
};

function Form$Message(props) {
  return LumiReact.renderOption(undefined, props.message, (function (message) {
                return JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx("p", {
                                  children: JsxRuntime.jsx(I18n$LumiAuthentication.T.$$Text.make, {
                                        entry: message
                                      })
                                }),
                            className: "notification is-info"
                          });
              }));
}

var Message = {
  make: Form$Message
};

function Form$Error(props) {
  return LumiReact.renderOption(undefined, props.error, (function (err) {
                return JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx("p", {
                                  children: JsxRuntime.jsx(I18n$LumiAuthentication.T.$$Text.make, {
                                        entry: err
                                      })
                                }),
                            className: "notification is-danger"
                          });
              }));
}

var $$Error = {
  make: Form$Error
};

var T;

export {
  T ,
  Field ,
  EmailField ,
  ExistingPasswordField ,
  NewPasswordField ,
  VerifyPasswordField ,
  Submit ,
  Message ,
  $$Error ,
}
/* LumiReact Not a pure module */
