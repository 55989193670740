// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as LumiReact from "lumi-rescript/lib/es6/src/LumiReact.bs.mjs";
import * as V2$Linear from "lumi-linear/lib/es6/src/V2.bs.mjs";
import * as N2f$Linear from "lumi-linear/lib/es6/src/N2f.bs.mjs";
import * as V2f$Linear from "lumi-linear/lib/es6/src/V2f.bs.mjs";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.bs.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as BoundingBox$Linear from "lumi-linear/lib/es6/src/BoundingBox.bs.mjs";
import * as AxesModel$LumiDocuments from "./AxesModel.bs.mjs";
import * as PlaceClass$LumiDocuments from "./PlaceClass.bs.mjs";

import '../../../src/css/place-model.sass'
;

function transform(param, hmirror, vflip, param$1) {
  var pmsHeight = param[1];
  var pmsWidth = param[0];
  var transformAbs = function (param) {
    var y = param[1];
    var x = param[0];
    return [
            hmirror ? pmsWidth - x : x,
            vflip ? pmsHeight - y : y
          ];
  };
  var transformRel = function (param) {
    var y = param[1];
    var x = param[0];
    return [
            hmirror ? -1 * x : x,
            vflip ? -1 * y : y
          ];
  };
  return {
          a: transformAbs(param$1.a),
          b: transformAbs(param$1.b),
          c: transformAbs(param$1.c),
          d: transformAbs(param$1.d),
          up: Core__Option.map(param$1.up, (function (n2) {
                  return N2f$Linear.map(n2, transformRel);
                })),
          width: param$1.width
        };
}

var jsonCodec = Jzon.object6((function (param) {
        return [
                param.a,
                param.b,
                param.c,
                param.d,
                param.up,
                param.width
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  a: param[0],
                  b: param[1],
                  c: param[2],
                  d: param[3],
                  up: param[4],
                  width: param[5]
                }
              };
      }), Jzon.field("a", V2f$Linear.jsonCodec), Jzon.field("b", V2f$Linear.jsonCodec), Jzon.field("c", V2f$Linear.jsonCodec), Jzon.field("d", V2f$Linear.jsonCodec), Jzon.field("up", Jzon.nullable(N2f$Linear.jsonCodec)), Jzon.field("width", Jzon.$$float));

function getScale(param) {
  return V2f$Linear.length(V2f$Linear.minus(param.b, param.a)) / param.width;
}

function toAxesModel(param) {
  var d = param.d;
  var a = param.a;
  return {
          baseFront: V2f$Linear.plus(a, V2f$Linear.scale(V2f$Linear.minus(param.b, a), 0.5)),
          baseBack: V2f$Linear.plus(d, V2f$Linear.scale(V2f$Linear.minus(param.c, d), 0.5)),
          up: param.up,
          side: a,
          sideLength: param.width / 2
        };
}

function toPoly(qm, placeClass) {
  return AxesModel$LumiDocuments.toPoly(toAxesModel(qm), placeClass);
}

function toPolyQuadrilateral(quadModel, placeClass) {
  var up = quadModel.up;
  var d = quadModel.d;
  var c = quadModel.c;
  var b = quadModel.b;
  var a = quadModel.a;
  var topDownPoly = {
    topLeft: d,
    bottomLeft: a,
    bottomRight: b,
    topRight: c
  };
  if (up === undefined) {
    return topDownPoly;
  }
  var quadPoints = [
    a,
    b,
    c,
    d
  ];
  var height = PlaceClass$LumiDocuments.PlaceClass.quadrilateralModelHeight(placeClass);
  var pxPerM = getScale(quadModel);
  var h = V2f$Linear.scaleTo(N2f$Linear.asV2f(up), pxPerM * height);
  var boxPoints = quadPoints.concat(quadPoints.map(function (extra) {
            return V2f$Linear.plus(h, extra);
          }));
  var mbOrientedBB = BoundingBox$Linear.orientedMinimumBoundingBox(boxPoints);
  if (mbOrientedBB === undefined) {
    return topDownPoly;
  }
  var match = BoundingBox$Linear.orientedBoundingBoxPoints(mbOrientedBB);
  return {
          topLeft: match[0],
          bottomLeft: match[3],
          bottomRight: match[2],
          topRight: match[1]
        };
}

function extraPoints(param) {
  return [
          param.a,
          param.b,
          param.c,
          param.d
        ];
}

function mainPoint(param) {
  return V2f$Linear.scale(V2f$Linear.plus(param.a, param.b), 0.5);
}

function scale(a, b, width) {
  return V2f$Linear.length(V2f$Linear.minus(b, a)) / width;
}

function QuadrilateralModel$Svg$Contour(props) {
  var __classList = props.classList;
  var classList = __classList !== undefined ? __classList : [];
  var className = LumiReact.classList([[
            "place-model-base",
            true
          ]].concat(classList));
  var points = props.points.map(function (param) {
          return param[0].toString() + "," + param[1].toString();
        }).join(" ");
  return JsxRuntime.jsx("polygon", {
              className: className,
              points: points,
              vectorEffect: "non-scaling-stroke"
            });
}

var Contour = {
  make: QuadrilateralModel$Svg$Contour
};

function QuadrilateralModel$Svg$Up(props) {
  var __classList = props.classList;
  var b = props.b;
  var a = props.a;
  var classList = __classList !== undefined ? __classList : [];
  var scale$1 = scale(a, b, props.width);
  var mainPoint = V2f$Linear.scale(V2f$Linear.plus(a, b), 0.5);
  var absoluteUp = V2f$Linear.plus(mainPoint, V2f$Linear.scaleTo(N2f$Linear.asV2f(props.up), scale$1 * 0.5));
  var className = LumiReact.classList([[
            "place-model-up",
            true
          ]].concat(classList));
  var match = V2$Linear.map(mainPoint, (function (prim) {
          return prim.toString();
        }));
  var match$1 = V2$Linear.map(absoluteUp, (function (prim) {
          return prim.toString();
        }));
  return JsxRuntime.jsx("line", {
              className: className,
              vectorEffect: "non-scaling-stroke",
              x1: match[0],
              x2: match$1[0],
              y1: match[1],
              y2: match$1[1]
            });
}

var Up = {
  make: QuadrilateralModel$Svg$Up
};

function QuadrilateralModel$Svg(props) {
  var __upClassList = props.upClassList;
  var __baseClassList = props.baseClassList;
  var match = props.quadModel;
  var width = match.width;
  var b = match.b;
  var a = match.a;
  var baseClassList = __baseClassList !== undefined ? __baseClassList : [];
  var upClassList = __upClassList !== undefined ? __upClassList : [];
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(QuadrilateralModel$Svg$Contour, {
                      points: [
                        a,
                        b,
                        match.c,
                        match.d,
                        a
                      ],
                      classList: baseClassList
                    }),
                LumiReact.renderOption(undefined, match.up, (function (up) {
                        return JsxRuntime.jsx(QuadrilateralModel$Svg$Up, {
                                    a: a,
                                    b: b,
                                    width: width,
                                    up: up,
                                    classList: upClassList
                                  });
                      }))
              ]
            });
}

var Svg = {
  scale: scale,
  Contour: Contour,
  Up: Up,
  make: QuadrilateralModel$Svg
};

export {
  transform ,
  jsonCodec ,
  getScale ,
  toAxesModel ,
  toPoly ,
  toPolyQuadrilateral ,
  extraPoints ,
  mainPoint ,
  Svg ,
}
/*  Not a pure module */
