// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as LumiRequest from "lumi-rescript/lib/es6/src/LumiRequest.bs.mjs";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.bs.mjs";
import * as JsCookieJs from "lumi-rescript/src/js-cookie.js";

function addXSRFTokenHeader(req) {
  req.headers["X-XSRF-TOKEN"] = Core__Option.getOr(JsCookieJs.get("XSRF-TOKEN"), "");
  return req;
}

function make(url, methodOpt, headersOpt, body, param) {
  var method = methodOpt !== undefined ? methodOpt : "GET";
  var headers = headersOpt !== undefined ? Caml_option.valFromOption(headersOpt) : ({});
  return addXSRFTokenHeader(LumiRequest.make(url, method, Caml_option.some(headers), body, undefined));
}

export {
  addXSRFTokenHeader ,
  make ,
}
/* LumiRequest Not a pure module */
