// Generated by ReScript, PLEASE EDIT WITH CARE

import * as LumiArray from "./LumiArray.bs.mjs";

function nonEmpty(v) {
  if (v === "") {
    return ;
  } else {
    return v;
  }
}

function commonPrefix(boundary, _a, _b) {
  while(true) {
    var b = _b;
    var a = _a;
    if (a.length > b.length) {
      _b = a;
      _a = b;
      continue ;
    }
    if (b.startsWith(a)) {
      return a;
    }
    var end = boundary !== undefined ? a.lastIndexOf(boundary, a.length - 2 | 0) + 1 | 0 : -1;
    _a = a.slice(0, end);
    continue ;
  };
}

function commonPrefixArray(boundary, xs) {
  if (xs.length === 0) {
    return "";
  } else {
    return LumiArray.reduce1(xs, (function (extra, extra$1) {
                  return commonPrefix(boundary, extra, extra$1);
                }));
  }
}

export {
  nonEmpty ,
  commonPrefix ,
  commonPrefixArray ,
}
/* No side effect */
