// Generated by ReScript, PLEASE EDIT WITH CARE


var intervals = [
  "quarterHour",
  "hour",
  "day",
  "week",
  "month"
];

var metrics = [
  "freeMin",
  "freeMean",
  "occupiedMean",
  "occupiedMax",
  "occupancyPctMin",
  "occupancyPctMean",
  "occupancyPctMax"
];

export {
  intervals ,
  metrics ,
}
/* No side effect */
