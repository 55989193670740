// Generated by ReScript, PLEASE EDIT WITH CARE


var isMouseEvent = (function isMouseEvent(ev) {
    return (ev instanceof MouseEvent);
  });

export {
  isMouseEvent ,
}
/* No side effect */
