// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as LumiJzon from "lumi-rescript/lib/es6/src/LumiJzon.bs.mjs";
import * as Core__Result from "@rescript/core/lib/es6/src/Core__Result.bs.mjs";
import * as Document$LumiStore from "lumi-store/lib/es6/src/Document.bs.mjs";

var jsonCodec = LumiJzon.taggedVariantOptionalContents((function (value) {
        if (typeof value !== "object") {
          return [
                  "default",
                  undefined
                ];
        } else {
          return [
                  "facility-map",
                  Jzon.encodeWith([
                        value._0,
                        value._1
                      ], LumiJzon.tuple2(Document$LumiStore.Key.jsonCodec(), Jzon.nullable(Document$LumiStore.Key.jsonCodec())))
                ];
        }
      }), (function (pair) {
        var other = pair[0];
        switch (other) {
          case "default" :
              return {
                      TAG: "Ok",
                      _0: "Default"
                    };
          case "facility-map" :
              var json = pair[1];
              if (json !== undefined) {
                return Core__Result.map(Jzon.decodeWith(json, LumiJzon.tuple2(Document$LumiStore.Key.jsonCodec(), Jzon.nullable(Document$LumiStore.Key.jsonCodec()))), (function (param) {
                              return {
                                      TAG: "SelectedFacilityMap",
                                      _0: param[0],
                                      _1: param[1]
                                    };
                            }));
              } else {
                return {
                        TAG: "Error",
                        _0: {
                          NAME: "UnexpectedJsonValue",
                          VAL: [
                            [],
                            other
                          ]
                        }
                      };
              }
          default:
            return {
                    TAG: "Error",
                    _0: {
                      NAME: "UnexpectedJsonValue",
                      VAL: [
                        [],
                        other
                      ]
                    }
                  };
        }
      }));

var FacilityMap = {
  jsonCodec: jsonCodec
};

var jsonCodec$1 = LumiJzon.taggedVariantOptionalContents((function (value) {
        if (typeof value !== "object") {
          return [
                  "statistics",
                  undefined
                ];
        } else if (value.TAG === "CurrentOccupation") {
          return [
                  "current-occupation",
                  Jzon.encodeWith(value._0, jsonCodec)
                ];
        } else {
          return [
                  "abandoned-vehicles",
                  Jzon.encodeWith(value._0, jsonCodec)
                ];
        }
      }), (function (pair) {
        var other = pair[0];
        switch (other) {
          case "abandoned-vehicles" :
              var json = pair[1];
              if (json !== undefined) {
                return Core__Result.map(Jzon.decodeWith(json, jsonCodec), (function (facilityMap) {
                              return {
                                      TAG: "AbandonedVehicles",
                                      _0: facilityMap
                                    };
                            }));
              }
              break;
          case "current-occupation" :
              var json$1 = pair[1];
              if (json$1 !== undefined) {
                return Core__Result.map(Jzon.decodeWith(json$1, jsonCodec), (function (facilityMap) {
                              return {
                                      TAG: "CurrentOccupation",
                                      _0: facilityMap
                                    };
                            }));
              }
              break;
          case "statistics" :
              return {
                      TAG: "Ok",
                      _0: "Statistics"
                    };
          default:
            
        }
        return {
                TAG: "Error",
                _0: {
                  NAME: "UnexpectedJsonValue",
                  VAL: [
                    [],
                    other
                  ]
                }
              };
      }));

function toString(route) {
  return Jzon.encodeStringWith(route, jsonCodec$1);
}

function fromString(str) {
  if (str.length === 0) {
    return {
            TAG: "CurrentOccupation",
            _0: "Default"
          };
  }
  var decodeResult = Jzon.decodeStringWith(decodeURIComponent(str), jsonCodec$1);
  if (decodeResult.TAG === "Ok") {
    return decodeResult._0;
  }
  console.error("Route decode error", decodeResult._0);
  return {
          TAG: "CurrentOccupation",
          _0: "Default"
        };
}

function sameTab(a, b) {
  if (typeof a !== "object") {
    if (typeof b !== "object") {
      return true;
    } else {
      return false;
    }
  } else if (a.TAG === "CurrentOccupation") {
    if (typeof b !== "object" || b.TAG !== "CurrentOccupation") {
      return false;
    } else {
      return true;
    }
  } else if (typeof b !== "object" || b.TAG === "CurrentOccupation") {
    return false;
  } else {
    return true;
  }
}

export {
  FacilityMap ,
  jsonCodec$1 as jsonCodec,
  toString ,
  fromString ,
  sameTab ,
}
/* jsonCodec Not a pure module */
