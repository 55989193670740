// Generated by ReScript, PLEASE EDIT WITH CARE

import * as FontAwesome from "lumi-rescript/lib/es6/src/FontAwesome.bs.mjs";
import * as Locale$LumiI18n from "./Locale.bs.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Localization$LumiI18n from "./Localization.bs.mjs";

function stopProp(handler, $$event) {
  $$event.stopPropagation();
  return handler($$event);
}

function LocalePicker(props) {
  var currentLocale = Locale$LumiI18n.useContext();
  var setLocale = Localization$LumiI18n.SetLocale.useContext();
  var locales = Localization$LumiI18n.Locales.useContext();
  return JsxRuntime.jsxs("p", {
              children: [
                JsxRuntime.jsx("span", {
                      children: JsxRuntime.jsx("select", {
                            children: locales.map(function (newLocale) {
                                  return JsxRuntime.jsx("option", {
                                              children: Locale$LumiI18n.name(newLocale),
                                              value: Locale$LumiI18n.toString(newLocale)
                                            }, Locale$LumiI18n.toString(newLocale));
                                }),
                            value: Locale$LumiI18n.toString(currentLocale),
                            onChange: (function (e) {
                                var value = e.target.value;
                                var newLocale = Locale$LumiI18n.fromString(value);
                                if (newLocale !== undefined) {
                                  return setLocale(newLocale);
                                }
                                
                              })
                          }),
                      className: "select"
                    }),
                JsxRuntime.jsx("span", {
                      children: JsxRuntime.jsx(FontAwesome.Solid.make, {
                            name: "language"
                          }),
                      className: "icon is-left"
                    })
              ],
              className: "control has-icons-left",
              onClick: (function (extra) {
                  return stopProp((function (param) {
                                
                              }), extra);
                })
            });
}

var make = LocalePicker;

export {
  stopProp ,
  make ,
}
/* FontAwesome Not a pure module */
