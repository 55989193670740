// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as LumiJzon from "lumi-rescript/lib/es6/src/LumiJzon.bs.mjs";
import * as FontAwesome from "lumi-rescript/lib/es6/src/FontAwesome.bs.mjs";
import * as Core__Result from "@rescript/core/lib/es6/src/Core__Result.bs.mjs";
import * as Schema$LumiStore from "lumi-store/lib/es6/src/Schema.bs.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Document$LumiStore from "lumi-store/lib/es6/src/Document.bs.mjs";
import * as Types$LumiWaylight from "lumi-waylight/lib/es6/src/Types.bs.mjs";
import * as Types$LumiDocuments from "./Types.bs.mjs";
import * as SensorGroups$LumiStaticData from "lumi-static-data/lib/es6/src/SensorGroups.bs.mjs";

var jsonCodec = Jzon.custom((function (value) {
        var tmp;
        tmp = value === "LumicamIndicatorBol" ? "LumicamIndicatorBol" : "LumicamIndicatorEntree";
        return Jzon.encodeWith(tmp, Jzon.string);
      }), (function (json) {
        return Core__Result.flatMap(Jzon.decodeWith(json, Jzon.string), (function (v) {
                      switch (v) {
                        case "LumicamIndicatorBol" :
                            return {
                                    TAG: "Ok",
                                    _0: "LumicamIndicatorBol"
                                  };
                        case "LumicamIndicatorEntree" :
                            return {
                                    TAG: "Ok",
                                    _0: "LumicamIndicatorEntree"
                                  };
                        default:
                          return {
                                  TAG: "Error",
                                  _0: {
                                    NAME: "UnexpectedJsonValue",
                                    VAL: [
                                      [],
                                      v
                                    ]
                                  }
                                };
                      }
                    }));
      }));

var LumicamIndicatorType = {
  jsonCodec: jsonCodec
};

var jsonCodec$1 = Jzon.object1((function (param) {
        return param.indicatorAddress;
      }), (function (indicatorAddress) {
        return {
                TAG: "Ok",
                _0: {
                  indicatorAddress: indicatorAddress
                }
              };
      }), Jzon.field("indicatorAddress", Types$LumiWaylight.Address.jsonCodec));

var Waylight = {
  jsonCodec: jsonCodec$1
};

var indicatorTypeLumicamCodec = LumiJzon.tuple2(jsonCodec, Document$LumiStore.Key.jsonCodec());

var jsonCodec$2 = LumiJzon.taggedVariant((function (value) {
        if (value.TAG === "IndicatorTypeLumicam") {
          return [
                  "IndicatorTypeLumicam",
                  Jzon.encodeWith([
                        value._0,
                        value._1
                      ], indicatorTypeLumicamCodec)
                ];
        } else {
          return [
                  "IndicatorTypeWaylight",
                  Jzon.encodeWith(value._0, jsonCodec$1)
                ];
        }
      }), (function (param) {
        var contents = param[1];
        var tag = param[0];
        switch (tag) {
          case "IndicatorTypeLumicam" :
              return Core__Result.map(Jzon.decodeWith(contents, indicatorTypeLumicamCodec), (function (param) {
                            return {
                                    TAG: "IndicatorTypeLumicam",
                                    _0: param[0],
                                    _1: param[1]
                                  };
                          }));
          case "IndicatorTypeWaylight" :
              return Core__Result.map(Jzon.decodeWith(contents, jsonCodec$1), (function (x) {
                            return {
                                    TAG: "IndicatorTypeWaylight",
                                    _0: x
                                  };
                          }));
          default:
            return {
                    TAG: "Error",
                    _0: {
                      NAME: "UnexpectedJsonValue",
                      VAL: [
                        [],
                        tag
                      ]
                    }
                  };
        }
      }));

var IndicatorType = {
  jsonCodec: jsonCodec$2
};

var jsonCodec$3 = Jzon.object3((function (param) {
        return [
                param.free,
                param.busy,
                param.full
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  free: param[0],
                  busy: param[1],
                  full: param[2]
                }
              };
      }), Jzon.field("free", Types$LumiDocuments.Color.jsonCodec), Jzon.field("busy", Types$LumiDocuments.Color.jsonCodec), Jzon.field("full", Types$LumiDocuments.Color.jsonCodec));

var default_free = Types$LumiDocuments.Color.green;

var default_busy = Types$LumiDocuments.Color.orange;

var default_full = Types$LumiDocuments.Color.red;

var $$default = {
  free: default_free,
  busy: default_busy,
  full: default_full
};

var GroupOccupancyColors = {
  jsonCodec: jsonCodec$3,
  $$default: $$default
};

function freeColor(mode) {
  if (mode.TAG !== "IndicatorModeGroupOccupancy") {
    return mode._0;
  }
  var match = mode._0;
  if (match !== undefined) {
    return match.free;
  } else {
    return default_free;
  }
}

function busyColor(mode) {
  if (mode.TAG !== "IndicatorModeGroupOccupancy") {
    return mode._0;
  }
  var match = mode._0;
  if (match !== undefined) {
    return match.busy;
  } else {
    return default_busy;
  }
}

function fullColor(mode) {
  if (mode.TAG !== "IndicatorModeGroupOccupancy") {
    return mode._0;
  }
  var match = mode._0;
  if (match !== undefined) {
    return match.full;
  } else {
    return default_full;
  }
}

var jsonCodec$4 = LumiJzon.taggedVariant((function (value) {
        if (value.TAG === "IndicatorModeGroupOccupancy") {
          return [
                  "IndicatorModeGroupOccupancy",
                  Jzon.encodeWith(value._0, Jzon.nullable(jsonCodec$3))
                ];
        } else {
          return [
                  "IndicatorModeSingleColor",
                  Jzon.encodeWith(value._0, Types$LumiDocuments.Color.jsonCodec)
                ];
        }
      }), (function (param) {
        var contents = param[1];
        var tag = param[0];
        switch (tag) {
          case "IndicatorModeGroupOccupancy" :
              return Core__Result.map(Jzon.decodeWith(contents, Jzon.nullable(jsonCodec$3)), (function (goc) {
                            return {
                                    TAG: "IndicatorModeGroupOccupancy",
                                    _0: goc
                                  };
                          }));
          case "IndicatorModeSingleColor" :
              return Core__Result.map(Jzon.decodeWith(contents, Types$LumiDocuments.Color.jsonCodec), (function (color) {
                            return {
                                    TAG: "IndicatorModeSingleColor",
                                    _0: color
                                  };
                          }));
          default:
            return {
                    TAG: "Error",
                    _0: {
                      NAME: "UnexpectedJsonValue",
                      VAL: [
                        [],
                        tag
                      ]
                    }
                  };
        }
      }));

var IndicatorMode = {
  freeColor: freeColor,
  busyColor: busyColor,
  fullColor: fullColor,
  jsonCodec: jsonCodec$4
};

var jsonCodec$5 = LumiJzon.taggedVariantOptionalContents((function (v) {
        if (typeof v !== "object") {
          if (v === "IndicatorPlanned") {
            return [
                    "IndicatorPlanned",
                    undefined
                  ];
          } else {
            return [
                    "IndicatorOperational",
                    undefined
                  ];
          }
        } else {
          return [
                  "IndicatorMalfunction",
                  Jzon.encodeWith(v._0, Jzon.string)
                ];
        }
      }), (function (pair) {
        var other = pair[0];
        switch (other) {
          case "IndicatorMalfunction" :
              var json = pair[1];
              if (json !== undefined) {
                return Core__Result.map(Jzon.decodeWith(json, Jzon.string), (function (x) {
                              return {
                                      TAG: "IndicatorMalfunction",
                                      _0: x
                                    };
                            }));
              } else {
                return {
                        TAG: "Error",
                        _0: {
                          NAME: "UnexpectedJsonValue",
                          VAL: [
                            [],
                            other
                          ]
                        }
                      };
              }
          case "IndicatorOperational" :
              return {
                      TAG: "Ok",
                      _0: "IndicatorOperational"
                    };
          case "IndicatorPlanned" :
              return {
                      TAG: "Ok",
                      _0: "IndicatorPlanned"
                    };
          default:
            return {
                    TAG: "Error",
                    _0: {
                      NAME: "UnexpectedJsonValue",
                      VAL: [
                        [],
                        other
                      ]
                    }
                  };
        }
      }));

var IndicatorOperationalState = {
  jsonCodec: jsonCodec$5
};

var jsonCodec$6 = Jzon.object5((function (param) {
        return [
                param.name,
                param.type_,
                param.centralGroupId,
                param.mode,
                param.operationalState
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  name: param[0],
                  type_: param[1],
                  centralGroupId: param[2],
                  mode: param[3],
                  operationalState: param[4]
                }
              };
      }), Jzon.field("name", Jzon.string), Jzon.field("type", jsonCodec$2), Jzon.field("centralGroupId", Jzon.nullable(SensorGroups$LumiStaticData.GroupFullId.jsonCodec)), Jzon.field("mode", jsonCodec$4), Jzon.field("operationalState", jsonCodec$5));

var Indicator = {
  jsonCodec: jsonCodec$6
};

function documentName(param) {
  return param.name;
}

var icon = JsxRuntime.jsx(FontAwesome.Solid.make, {
      name: "traffic-light"
    });

var Schema = Schema$LumiStore.Make({
      documentName: documentName,
      tag: "indicator",
      jsonCodec: jsonCodec$6,
      icon: icon
    });

export {
  LumicamIndicatorType ,
  Waylight ,
  IndicatorType ,
  GroupOccupancyColors ,
  IndicatorMode ,
  IndicatorOperationalState ,
  Indicator ,
  Schema ,
}
/* jsonCodec Not a pure module */
