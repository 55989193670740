// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as LumiOption from "lumi-rescript/lib/es6/src/LumiOption.bs.mjs";
import * as Core__Result from "@rescript/core/lib/es6/src/Core__Result.bs.mjs";
import * as Recti$Linear from "lumi-linear/lib/es6/src/Recti.bs.mjs";

var values = [
  "RightAngle_0",
  "RightAngle_90",
  "RightAngle_180",
  "RightAngle_270"
];

function toInt(rot) {
  switch (rot) {
    case "RightAngle_0" :
        return 0;
    case "RightAngle_90" :
        return 90;
    case "RightAngle_180" :
        return 180;
    case "RightAngle_270" :
        return 270;
    
  }
}

function toDegrees(rot) {
  return toInt(rot);
}

function fromInt(deg) {
  if (deg >= 91) {
    if (deg !== 180) {
      if (deg !== 270) {
        return ;
      } else {
        return "RightAngle_270";
      }
    } else {
      return "RightAngle_180";
    }
  } else if (deg !== 0) {
    if (deg >= 90) {
      return "RightAngle_90";
    } else {
      return ;
    }
  } else {
    return "RightAngle_0";
  }
}

function fromDegrees(deg) {
  return fromInt(deg | 0);
}

function reverse(rot) {
  switch (rot) {
    case "RightAngle_0" :
        return "RightAngle_0";
    case "RightAngle_90" :
        return "RightAngle_270";
    case "RightAngle_180" :
        return "RightAngle_180";
    case "RightAngle_270" :
        return "RightAngle_90";
    
  }
}

function applyToRecti(rot, rect) {
  switch (rot) {
    case "RightAngle_0" :
        return rect;
    case "RightAngle_90" :
        return Recti$Linear.rotate90(rect);
    case "RightAngle_180" :
        return Recti$Linear.rotate180(rect);
    case "RightAngle_270" :
        return Recti$Linear.rotate270(rect);
    
  }
}

var jsonCodec = Jzon.custom((function (rot) {
        return Jzon.encodeWith(toInt(rot), Jzon.$$int);
      }), (function (json) {
        return Core__Result.flatMap(Jzon.decodeWith(json, Jzon.$$int), (function (deg) {
                      return LumiOption.okOr(fromInt(deg), {
                                  NAME: "UnexpectedJsonValue",
                                  VAL: [
                                    [],
                                    deg.toString()
                                  ]
                                });
                    }));
      }));

var RightAngle = {
  values: values,
  toInt: toInt,
  toDegrees: toDegrees,
  fromInt: fromInt,
  fromDegrees: fromDegrees,
  reverse: reverse,
  applyToRecti: applyToRecti,
  jsonCodec: jsonCodec
};

export {
  RightAngle ,
}
/* jsonCodec Not a pure module */
