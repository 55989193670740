// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as Recti$Linear from "lumi-linear/lib/es6/src/Recti.bs.mjs";
import * as Document$LumiStore from "lumi-store/lib/es6/src/Document.bs.mjs";
import * as Prelude$LumiCentral from "./Prelude.bs.mjs";
import * as Sensor$LumiDocuments from "lumi-documents/lib/es6/src/Sensor.bs.mjs";
import * as Occupation$LumiDocuments from "lumi-documents/lib/es6/src/Occupation.bs.mjs";
import * as OccupationSnapshot$LumiDocuments from "lumi-documents/lib/es6/src/OccupationSnapshot.bs.mjs";

var jsonCodec = Jzon.object3((function (param) {
        return [
                param.snapshot,
                param.imageUrl,
                param.placeAABB
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  snapshot: param[0],
                  imageUrl: param[1],
                  placeAABB: param[2]
                }
              };
      }), Jzon.field("snapshot", Prelude$LumiCentral.documentCodec(OccupationSnapshot$LumiDocuments.OccupationSnapshot.jsonCodec)), Jzon.field("imageUrl", Jzon.string), Jzon.field("placeAABB", Recti$Linear.jsonCodec));

var OccupationDetailsSnapshot = {
  jsonCodec: jsonCodec
};

var jsonCodec$1 = Jzon.object3((function (param) {
        return [
                param.occupation,
                param.oldestSnapshot,
                param.latestSnapshot
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  occupation: param[0],
                  oldestSnapshot: param[1],
                  latestSnapshot: param[2]
                }
              };
      }), Jzon.field("occupation", Prelude$LumiCentral.documentCodec(Occupation$LumiDocuments.Occupation.jsonCodec)), Jzon.field("oldestSnapshot", Jzon.nullable(jsonCodec)), Jzon.field("latestSnapshot", Jzon.nullable(jsonCodec)));

var OccupationDetails = {
  jsonCodec: jsonCodec$1
};

var jsonCodec$2 = Jzon.object3((function (param) {
        return [
                param.sensor,
                param.occupations,
                param.currentOccupation
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  sensor: param[0],
                  occupations: param[1],
                  currentOccupation: param[2]
                }
              };
      }), Jzon.field("sensor", Sensor$LumiDocuments.Sensor.jsonCodec), Jzon.field("occupations", Jzon.array(jsonCodec$1)), Jzon.field("currentOccupation", Jzon.nullable(Document$LumiStore.Key.jsonCodec())));

var OccupationHistory = {
  jsonCodec: jsonCodec$2
};

export {
  OccupationDetailsSnapshot ,
  OccupationDetails ,
  OccupationHistory ,
}
/* jsonCodec Not a pure module */
