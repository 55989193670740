// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as LumiJzon from "lumi-rescript/lib/es6/src/LumiJzon.bs.mjs";
import * as V2i$Linear from "lumi-linear/lib/es6/src/V2i.bs.mjs";
import * as FontAwesome from "lumi-rescript/lib/es6/src/FontAwesome.bs.mjs";
import * as M23f$Linear from "lumi-linear/lib/es6/src/M23f.bs.mjs";
import * as StringCodec from "lumi-rescript/lib/es6/src/StringCodec.bs.mjs";
import * as Core__Result from "@rescript/core/lib/es6/src/Core__Result.bs.mjs";
import * as HanwhaVision from "hanwha-vision/lib/es6/src/HanwhaVision.bs.mjs";
import * as MacAddress$IP from "ip/lib/es6/src/MacAddress.bs.mjs";
import * as Schema$LumiStore from "lumi-store/lib/es6/src/Schema.bs.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Document$LumiStore from "lumi-store/lib/es6/src/Document.bs.mjs";
import * as Types$LumiDocuments from "./Types.bs.mjs";
import * as Translation$LumiI18n from "lumi-i18n/lib/es6/src/Translation.bs.mjs";
import * as RightAngle$LumiDocuments from "./Types/RightAngle.bs.mjs";

var options = [
  "Planned",
  "New",
  "Operational",
  "StereoTwin",
  "Spare",
  "Archived"
];

var jsonCodec = Jzon.custom((function (v) {
        var tmp;
        switch (v) {
          case "Planned" :
              tmp = "Planned";
              break;
          case "New" :
              tmp = "New";
              break;
          case "Operational" :
              tmp = "Operational";
              break;
          case "StereoTwin" :
              tmp = "StereoTwin";
              break;
          case "Spare" :
              tmp = "Spare";
              break;
          case "Archived" :
              tmp = "Archived";
              break;
          
        }
        return Jzon.encodeWith(tmp, Jzon.string);
      }), (function (json) {
        return Core__Result.flatMap(Jzon.decodeWith(json, Jzon.string), (function (v) {
                      switch (v) {
                        case "Archived" :
                            return {
                                    TAG: "Ok",
                                    _0: "Archived"
                                  };
                        case "New" :
                            return {
                                    TAG: "Ok",
                                    _0: "New"
                                  };
                        case "Operational" :
                            return {
                                    TAG: "Ok",
                                    _0: "Operational"
                                  };
                        case "Planned" :
                            return {
                                    TAG: "Ok",
                                    _0: "Planned"
                                  };
                        case "Spare" :
                            return {
                                    TAG: "Ok",
                                    _0: "Spare"
                                  };
                        case "StereoTwin" :
                            return {
                                    TAG: "Ok",
                                    _0: "StereoTwin"
                                  };
                        default:
                          return {
                                  TAG: "Error",
                                  _0: {
                                    NAME: "UnexpectedJsonValue",
                                    VAL: [
                                      [],
                                      v
                                    ]
                                  }
                                };
                      }
                    }));
      }));

var stringCodec = StringCodec.jzon(jsonCodec);

function toString(operationalState) {
  switch (operationalState) {
    case "Planned" :
        return "Planned";
    case "New" :
        return "New";
    case "Operational" :
        return "Operational";
    case "StereoTwin" :
        return "Stereo twin";
    case "Spare" :
        return "Spare";
    case "Archived" :
        return "Archived";
    
  }
}

function Camera$CameraOperationalState(props) {
  var operationalState = props.operationalState;
  var tmp;
  switch (operationalState) {
    case "Planned" :
        tmp = JsxRuntime.jsx("span", {
              children: JsxRuntime.jsx(FontAwesome.Solid.make, {
                    name: "person-digging"
                  }),
              className: "icon has-text-warning"
            });
        break;
    case "New" :
        tmp = JsxRuntime.jsx("span", {
              children: JsxRuntime.jsx(FontAwesome.Solid.make, {
                    name: "baby"
                  }),
              className: "icon has-text-warning"
            });
        break;
    case "Operational" :
        tmp = JsxRuntime.jsx("span", {
              children: JsxRuntime.jsx(FontAwesome.Solid.make, {
                    name: "gears"
                  }),
              className: "icon has-text-success"
            });
        break;
    case "StereoTwin" :
        tmp = JsxRuntime.jsx("span", {
              children: JsxRuntime.jsx(FontAwesome.Solid.make, {
                    name: "glasses"
                  }),
              className: "icon has-text-grey-light"
            });
        break;
    case "Spare" :
        tmp = JsxRuntime.jsx("span", {
              children: JsxRuntime.jsx(FontAwesome.Solid.make, {
                    name: "box-open"
                  }),
              className: "icon has-text-grey-light"
            });
        break;
    case "Archived" :
        tmp = JsxRuntime.jsx("span", {
              children: JsxRuntime.jsx(FontAwesome.Solid.make, {
                    name: "broom"
                  }),
              className: "icon has-text-grey-light"
            });
        break;
    
  }
  return JsxRuntime.jsxs("span", {
              children: [
                tmp,
                JsxRuntime.jsx("span", {
                      children: toString(operationalState)
                    })
              ],
              className: "icon-text"
            });
}

var CameraOperationalState = {
  options: options,
  jsonCodec: jsonCodec,
  stringCodec: stringCodec,
  toString: toString,
  make: Camera$CameraOperationalState
};

var jsonCodec$1 = Jzon.custom((function (v) {
        var tmp;
        switch (v) {
          case "Normal" :
              tmp = 0;
              break;
          case "Negative" :
              tmp = 1;
              break;
          case "Grayscale" :
              tmp = 2;
              break;
          case "RedTint" :
              tmp = 3;
              break;
          case "GreenTint" :
              tmp = 4;
              break;
          case "BlueTint" :
              tmp = 5;
              break;
          case "Sepia" :
              tmp = 6;
              break;
          
        }
        return Jzon.encodeWith(tmp, Jzon.$$int);
      }), (function (json) {
        return Core__Result.flatMap(Jzon.decodeWith(json, Jzon.$$int), (function (v) {
                      switch (v) {
                        case 0 :
                            return {
                                    TAG: "Ok",
                                    _0: "Normal"
                                  };
                        case 1 :
                            return {
                                    TAG: "Ok",
                                    _0: "Negative"
                                  };
                        case 2 :
                            return {
                                    TAG: "Ok",
                                    _0: "Grayscale"
                                  };
                        case 3 :
                            return {
                                    TAG: "Ok",
                                    _0: "RedTint"
                                  };
                        case 4 :
                            return {
                                    TAG: "Ok",
                                    _0: "GreenTint"
                                  };
                        case 5 :
                            return {
                                    TAG: "Ok",
                                    _0: "BlueTint"
                                  };
                        case 6 :
                            return {
                                    TAG: "Ok",
                                    _0: "Sepia"
                                  };
                        default:
                          return {
                                  TAG: "Error",
                                  _0: {
                                    NAME: "UnexpectedJsonValue",
                                    VAL: [
                                      [],
                                      v.toString()
                                    ]
                                  }
                                };
                      }
                    }));
      }));

var SpecialDigitalEffect = {
  jsonCodec: jsonCodec$1
};

var jsonCodec$2 = Jzon.custom((function (v) {
        var tmp;
        switch (v) {
          case "Auto" :
              tmp = 0;
              break;
          case "Sunny" :
              tmp = 1;
              break;
          case "Cloudy" :
              tmp = 2;
              break;
          case "Office" :
              tmp = 3;
              break;
          case "Home" :
              tmp = 4;
              break;
          
        }
        return Jzon.encodeWith(tmp, Jzon.$$int);
      }), (function (json) {
        return Core__Result.flatMap(Jzon.decodeWith(json, Jzon.$$int), (function (v) {
                      switch (v) {
                        case 0 :
                            return {
                                    TAG: "Ok",
                                    _0: "Auto"
                                  };
                        case 1 :
                            return {
                                    TAG: "Ok",
                                    _0: "Sunny"
                                  };
                        case 2 :
                            return {
                                    TAG: "Ok",
                                    _0: "Cloudy"
                                  };
                        case 3 :
                            return {
                                    TAG: "Ok",
                                    _0: "Office"
                                  };
                        case 4 :
                            return {
                                    TAG: "Ok",
                                    _0: "Home"
                                  };
                        default:
                          return {
                                  TAG: "Error",
                                  _0: {
                                    NAME: "UnexpectedJsonValue",
                                    VAL: [
                                      [],
                                      v.toString()
                                    ]
                                  }
                                };
                      }
                    }));
      }));

var WhiteBalanceMode = {
  jsonCodec: jsonCodec$2
};

var options$1 = [
  "Auto",
  "Manual"
];

var jsonCodec$3 = Jzon.custom((function (v) {
        var tmp;
        tmp = v === "Manual" ? 0 : 1;
        return Jzon.encodeWith(tmp, Jzon.$$int);
      }), (function (json) {
        return Core__Result.flatMap(Jzon.decodeWith(json, Jzon.$$int), (function (v) {
                      if (v !== 0) {
                        if (v !== 1) {
                          return {
                                  TAG: "Error",
                                  _0: {
                                    NAME: "UnexpectedJsonValue",
                                    VAL: [
                                      [],
                                      v.toString()
                                    ]
                                  }
                                };
                        } else {
                          return {
                                  TAG: "Ok",
                                  _0: "Auto"
                                };
                        }
                      } else {
                        return {
                                TAG: "Ok",
                                _0: "Manual"
                              };
                      }
                    }));
      }));

var stringCodec$1 = StringCodec.jzon(jsonCodec$3);

function translate(locale, entry) {
  if (locale === "ENG") {
    if (entry === "Manual") {
      return "Manual";
    } else {
      return "Auto";
    }
  }
  
}

var T = Translation$LumiI18n.MakeProvider({
      defaultLocale: "ENG",
      translate: translate
    });

var ExposureControlMode = {
  options: options$1,
  jsonCodec: jsonCodec$3,
  stringCodec: stringCodec$1,
  T: T
};

var options$2 = [
  "Auto",
  "Manual"
];

var jsonCodec$4 = Jzon.custom((function (v) {
        var tmp;
        tmp = v === "Manual" ? 0 : 1;
        return Jzon.encodeWith(tmp, Jzon.$$int);
      }), (function (json) {
        return Core__Result.flatMap(Jzon.decodeWith(json, Jzon.$$int), (function (v) {
                      if (v !== 0) {
                        if (v !== 1) {
                          return {
                                  TAG: "Error",
                                  _0: {
                                    NAME: "UnexpectedJsonValue",
                                    VAL: [
                                      [],
                                      v.toString()
                                    ]
                                  }
                                };
                        } else {
                          return {
                                  TAG: "Ok",
                                  _0: "Auto"
                                };
                        }
                      } else {
                        return {
                                TAG: "Ok",
                                _0: "Manual"
                              };
                      }
                    }));
      }));

var stringCodec$2 = StringCodec.jzon(jsonCodec$4);

function translate$1(locale, entry) {
  if (locale === "ENG") {
    if (entry === "Manual") {
      return "Manual";
    } else {
      return "Auto";
    }
  }
  
}

var T$1 = Translation$LumiI18n.MakeProvider({
      defaultLocale: "ENG",
      translate: translate$1
    });

var GainControlMode = {
  options: options$2,
  jsonCodec: jsonCodec$4,
  stringCodec: stringCodec$2,
  T: T$1
};

var boolAsInt = Jzon.custom((function (v) {
        return Jzon.encodeWith(v ? 1 : 0, Jzon.$$int);
      }), (function (json) {
        return Core__Result.flatMap(Jzon.decodeWith(json, Jzon.$$int), (function (v) {
                      if (v !== 0) {
                        if (v !== 1) {
                          return {
                                  TAG: "Error",
                                  _0: {
                                    NAME: "UnexpectedJsonValue",
                                    VAL: [
                                      [],
                                      v.toString()
                                    ]
                                  }
                                };
                        } else {
                          return {
                                  TAG: "Ok",
                                  _0: true
                                };
                        }
                      } else {
                        return {
                                TAG: "Ok",
                                _0: false
                              };
                      }
                    }));
      }));

var $$default = {
  brightness: 0,
  contrast: 0,
  saturation: 0,
  sharpness: 0,
  denoise: 0,
  special_effect: "Normal",
  wb_mode: "Auto",
  awb: true,
  awb_gain: false,
  aec: "Auto",
  aec2: true,
  ae_level: 0,
  aec_value: 295,
  agc: "Auto",
  agc_gain: 2,
  gainceiling: 248,
  bpc: true,
  wpc: true,
  raw_gma: true,
  lenc: true,
  hmirror: true,
  vflip: false,
  dcw: true,
  colorbar: false,
  quality: 15
};

var jsonCodec$5 = Jzon.object25((function (param) {
        return [
                param.brightness,
                param.contrast,
                param.saturation,
                param.sharpness,
                param.denoise,
                param.special_effect,
                param.wb_mode,
                param.awb,
                param.awb_gain,
                param.aec,
                param.aec2,
                param.ae_level,
                param.aec_value,
                param.agc,
                param.agc_gain,
                param.gainceiling,
                param.bpc,
                param.wpc,
                param.raw_gma,
                param.lenc,
                param.hmirror,
                param.vflip,
                param.dcw,
                param.colorbar,
                param.quality
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  brightness: param[0],
                  contrast: param[1],
                  saturation: param[2],
                  sharpness: param[3],
                  denoise: param[4],
                  special_effect: param[5],
                  wb_mode: param[6],
                  awb: param[7],
                  awb_gain: param[8],
                  aec: param[9],
                  aec2: param[10],
                  ae_level: param[11],
                  aec_value: param[12],
                  agc: param[13],
                  agc_gain: param[14],
                  gainceiling: param[15],
                  bpc: param[16],
                  wpc: param[17],
                  raw_gma: param[18],
                  lenc: param[19],
                  hmirror: param[20],
                  vflip: param[21],
                  dcw: param[22],
                  colorbar: param[23],
                  quality: param[24]
                }
              };
      }), Jzon.field("brightness", Jzon.$$int), Jzon.field("contrast", Jzon.$$int), Jzon.field("saturation", Jzon.$$int), Jzon.field("sharpness", Jzon.$$int), Jzon.field("denoise", Jzon.$$int), Jzon.field("special_effect", jsonCodec$1), Jzon.field("wb_mode", jsonCodec$2), Jzon.field("awb", boolAsInt), Jzon.field("awb_gain", boolAsInt), Jzon.field("aec", jsonCodec$3), Jzon.field("aec2", boolAsInt), Jzon.field("ae_level", Jzon.$$int), Jzon.field("aec_value", Jzon.$$int), Jzon.field("agc", jsonCodec$4), Jzon.field("agc_gain", Jzon.$$int), Jzon.field("gainceiling", Jzon.$$int), Jzon.field("bpc", boolAsInt), Jzon.field("wpc", boolAsInt), Jzon.field("raw_gma", boolAsInt), Jzon.field("lenc", boolAsInt), Jzon.field("hmirror", boolAsInt), Jzon.field("vflip", boolAsInt), Jzon.field("dcw", boolAsInt), Jzon.field("colorbar", boolAsInt), Jzon.default(Jzon.field("quality", Jzon.$$int), 15));

var Config = {
  SpecialDigitalEffect: SpecialDigitalEffect,
  WhiteBalanceMode: WhiteBalanceMode,
  ExposureControlMode: ExposureControlMode,
  GainControlMode: GainControlMode,
  boolAsInt: boolAsInt,
  $$default: $$default,
  jsonCodec: jsonCodec$5
};

var $$default$1 = {
  cameraConfig: $$default
};

var jsonCodec$6 = Jzon.object1((function (param) {
        return param.cameraConfig;
      }), (function (cameraConfig) {
        return {
                TAG: "Ok",
                _0: {
                  cameraConfig: cameraConfig
                }
              };
      }), Jzon.field("cameraConfig", jsonCodec$5));

var Lumicam = {
  Config: Config,
  $$default: $$default$1,
  jsonCodec: jsonCodec$6
};

var jsonCodec$7 = LumiJzon.taggedVariantOptionalContents((function (value) {
        if (typeof value !== "object") {
          return [
                  "AutoFocus",
                  undefined
                ];
        } else {
          return [
                  "Focus",
                  Jzon.encodeWith(value._0, Jzon.$$int)
                ];
        }
      }), (function (param) {
        var contents = param[1];
        var tag = param[0];
        switch (tag) {
          case "AutoFocus" :
              return {
                      TAG: "Ok",
                      _0: "AutoFocus"
                    };
          case "Focus" :
              if (contents !== undefined) {
                return Core__Result.map(Jzon.decodeWith(contents, Jzon.$$int), (function (x) {
                              return {
                                      TAG: "Focus",
                                      _0: x
                                    };
                            }));
              } else {
                return {
                        TAG: "Error",
                        _0: {
                          NAME: "UnexpectedJsonValue",
                          VAL: [
                            [],
                            tag
                          ]
                        }
                      };
              }
          default:
            return {
                    TAG: "Error",
                    _0: {
                      NAME: "UnexpectedJsonValue",
                      VAL: [
                        [],
                        tag
                      ]
                    }
                  };
        }
      }));

var PTZFocus = {
  jsonCodec: jsonCodec$7
};

var jsonCodec$8 = LumiJzon.taggedVariantOptionalContents((function (value) {
        if (typeof value !== "object") {
          return [
                  "AutoIris",
                  undefined
                ];
        } else {
          return [
                  "Iris",
                  Jzon.encodeWith(value._0, Jzon.$$int)
                ];
        }
      }), (function (param) {
        var contents = param[1];
        var tag = param[0];
        switch (tag) {
          case "AutoIris" :
              return {
                      TAG: "Ok",
                      _0: "AutoIris"
                    };
          case "Iris" :
              if (contents !== undefined) {
                return Core__Result.map(Jzon.decodeWith(contents, Jzon.$$int), (function (x) {
                              return {
                                      TAG: "Iris",
                                      _0: x
                                    };
                            }));
              } else {
                return {
                        TAG: "Error",
                        _0: {
                          NAME: "UnexpectedJsonValue",
                          VAL: [
                            [],
                            tag
                          ]
                        }
                      };
              }
          default:
            return {
                    TAG: "Error",
                    _0: {
                      NAME: "UnexpectedJsonValue",
                      VAL: [
                        [],
                        tag
                      ]
                    }
                  };
        }
      }));

var PTZIris = {
  jsonCodec: jsonCodec$8
};

var jsonCodec$9 = Jzon.object6((function (param) {
        return [
                param.pan,
                param.tilt,
                param.zoom,
                param.speed,
                param.focus,
                param.iris
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  pan: param[0],
                  tilt: param[1],
                  zoom: param[2],
                  speed: param[3],
                  focus: param[4],
                  iris: param[5]
                }
              };
      }), Jzon.field("pan", Jzon.$$float), Jzon.field("tilt", Jzon.$$float), Jzon.field("zoom", Jzon.$$int), Jzon.field("speed", Jzon.$$int), Jzon.field("focus", jsonCodec$7), Jzon.field("iris", jsonCodec$8));

function toString$1(ptz) {
  return Jzon.encodeStringWith(ptz, jsonCodec$9);
}

var PTZ = {
  jsonCodec: jsonCodec$9,
  toString: toString$1
};

var jsonCodec$10 = Jzon.object5((function (param) {
        return [
                param.resolution,
                param.rank,
                param.compression,
                param.source,
                param.ptz
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  resolution: param[0],
                  rank: param[1],
                  compression: param[2],
                  source: param[3],
                  ptz: param[4]
                }
              };
      }), Jzon.field("resolution", V2i$Linear.jsonCodec), Jzon.field("rank", Jzon.$$int), Jzon.field("compression", Jzon.$$int), Jzon.field("source", Jzon.$$int), Jzon.field("ptz", Jzon.nullable(jsonCodec$9)));

var Viewport = {
  jsonCodec: jsonCodec$10
};

var $$default$2 = {
  protocol: "Http",
  certValidation: true,
  user: "root",
  password: "lumicam",
  viewport: {
    resolution: [
      1920,
      1080
    ],
    rank: 0,
    compression: 5,
    source: 1,
    ptz: undefined
  }
};

var jsonCodec$11 = Jzon.object5((function (param) {
        return [
                param.protocol,
                param.certValidation,
                param.user,
                param.password,
                param.viewport
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  protocol: param[0],
                  certValidation: param[1],
                  user: param[2],
                  password: param[3],
                  viewport: param[4]
                }
              };
      }), Jzon.field("protocol", Types$LumiDocuments.Protocol.jsonCodec), Jzon.field("certValidation", Jzon.bool), Jzon.field("user", Jzon.string), Jzon.field("password", Jzon.string), Jzon.field("viewport", jsonCodec$10));

var Axis = {
  Viewport: Viewport,
  $$default: $$default$2,
  jsonCodec: jsonCodec$11
};

var $$default$3 = {
  imageCameraDayNightMode: "ImageCameraDayNightModeAuto",
  imageWhiteBalanceMode: "ImageWhiteBalanceModeAWC",
  imageFlipHorizontal: false,
  imageFlipVertical: false,
  imageFlipRotate: "ImageFlipRotate0",
  imageSSDREnable: true,
  imageSSDRLevel: 10,
  imageSSDRDynamicRange: "ImageSSDRDynamicRangeWide",
  imageIRledMode: "ImageIRledModeAuto",
  systemDateSyncType: "SystemDateSyncTypeNTP"
};

var jsonCodec$12 = Jzon.object10((function (param) {
        return [
                param.imageCameraDayNightMode,
                param.imageWhiteBalanceMode,
                param.imageFlipHorizontal,
                param.imageFlipVertical,
                param.imageFlipRotate,
                param.imageSSDREnable,
                param.imageSSDRLevel,
                param.imageSSDRDynamicRange,
                param.imageIRledMode,
                param.systemDateSyncType
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  imageCameraDayNightMode: param[0],
                  imageWhiteBalanceMode: param[1],
                  imageFlipHorizontal: param[2],
                  imageFlipVertical: param[3],
                  imageFlipRotate: param[4],
                  imageSSDREnable: param[5],
                  imageSSDRLevel: param[6],
                  imageSSDRDynamicRange: param[7],
                  imageIRledMode: param[8],
                  systemDateSyncType: param[9]
                }
              };
      }), Jzon.field("imageCameraDayNightMode", HanwhaVision.ImageCameraDayNightMode.jsonCodec), Jzon.field("imageWhiteBalanceMode", HanwhaVision.ImageWhiteBalanceMode.jsonCodec), Jzon.field("imageFlipHorizontal", Jzon.bool), Jzon.field("imageFlipVertical", Jzon.bool), Jzon.field("imageFlipRotate", HanwhaVision.ImageFlipRotate.jsonCodec), Jzon.field("imageSSDREnable", Jzon.bool), Jzon.field("imageSSDRLevel", Jzon.$$int), Jzon.field("imageSSDRDynamicRange", HanwhaVision.ImageSSDRDynamicRange.jsonCodec), Jzon.field("imageIRledMode", HanwhaVision.ImageIRledMode.jsonCodec), Jzon.field("systemDateSyncType", HanwhaVision.SystemDateSyncType.jsonCodec));

var Config$1 = {
  $$default: $$default$3,
  jsonCodec: jsonCodec$12
};

var $$default$4 = {
  user: "admin",
  password: "lumicam42!",
  config: $$default$3
};

var jsonCodec$13 = Jzon.object3((function (param) {
        return [
                param.user,
                param.password,
                param.config
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  user: param[0],
                  password: param[1],
                  config: param[2]
                }
              };
      }), Jzon.field("user", Jzon.string), Jzon.field("password", Jzon.string), Jzon.field("config", jsonCodec$12));

var HanwhaVision$1 = {
  Config: Config$1,
  $$default: $$default$4,
  jsonCodec: jsonCodec$13
};

var options$3 = [
  "Axis",
  "HanwhaVision",
  "Lumicam"
];

var jsonCodec$14 = Jzon.custom((function (value) {
        var tmp;
        switch (value) {
          case "Axis" :
              tmp = "axis";
              break;
          case "HanwhaVision" :
              tmp = "hanwha vision";
              break;
          case "Lumicam" :
              tmp = "lumicam";
              break;
          
        }
        return Jzon.encodeWith(tmp, Jzon.string);
      }), (function (json) {
        return Core__Result.flatMap(Jzon.decodeWith(json, Jzon.string), (function (str) {
                      switch (str) {
                        case "axis" :
                            return {
                                    TAG: "Ok",
                                    _0: "Axis"
                                  };
                        case "hanwha vision" :
                            return {
                                    TAG: "Ok",
                                    _0: "HanwhaVision"
                                  };
                        case "lumicam" :
                            return {
                                    TAG: "Ok",
                                    _0: "Lumicam"
                                  };
                        default:
                          return {
                                  TAG: "Error",
                                  _0: {
                                    NAME: "UnexpectedJsonValue",
                                    VAL: [
                                      [],
                                      str
                                    ]
                                  }
                                };
                      }
                    }));
      }));

var stringCodec$3 = StringCodec.jzon(jsonCodec$14);

function translate$2(locale, entry) {
  switch (entry) {
    case "Axis" :
        return "Axis";
    case "HanwhaVision" :
        return "Hanwha Vision";
    case "Lumicam" :
        return "Lumicam";
    
  }
}

var T$2 = Translation$LumiI18n.MakeProvider({
      defaultLocale: "ENG",
      translate: translate$2
    });

var Enum = {
  options: options$3,
  jsonCodec: jsonCodec$14,
  stringCodec: stringCodec$3,
  T: T$2
};

function toEnum(cameraType) {
  switch (cameraType.TAG) {
    case "AxisType" :
        return "Axis";
    case "HanwhaVisionType" :
        return "HanwhaVision";
    case "LumicamType" :
        return "Lumicam";
    
  }
}

function fromEnum($$enum) {
  switch ($$enum) {
    case "Axis" :
        return {
                TAG: "AxisType",
                _0: $$default$2
              };
    case "HanwhaVision" :
        return {
                TAG: "HanwhaVisionType",
                _0: $$default$4
              };
    case "Lumicam" :
        return {
                TAG: "LumicamType",
                _0: $$default$1
              };
    
  }
}

var jsonCodec$15 = LumiJzon.taggedVariant((function (value) {
        switch (value.TAG) {
          case "AxisType" :
              return [
                      "AxisType",
                      Jzon.encodeWith(value._0, jsonCodec$11)
                    ];
          case "HanwhaVisionType" :
              return [
                      "HanwhaVisionType",
                      Jzon.encodeWith(value._0, jsonCodec$13)
                    ];
          case "LumicamType" :
              return [
                      "LumicamType",
                      Jzon.encodeWith(value._0, jsonCodec$6)
                    ];
          
        }
      }), (function (param) {
        var contents = param[1];
        var tag = param[0];
        switch (tag) {
          case "AxisType" :
              return Core__Result.map(Jzon.decodeWith(contents, jsonCodec$11), (function (x) {
                            return {
                                    TAG: "AxisType",
                                    _0: x
                                  };
                          }));
          case "HanwhaVisionType" :
              return Core__Result.map(Jzon.decodeWith(contents, jsonCodec$13), (function (x) {
                            return {
                                    TAG: "HanwhaVisionType",
                                    _0: x
                                  };
                          }));
          case "LumicamType" :
              return Core__Result.map(Jzon.decodeWith(contents, jsonCodec$6), (function (x) {
                            return {
                                    TAG: "LumicamType",
                                    _0: x
                                  };
                          }));
          default:
            return {
                    TAG: "Error",
                    _0: {
                      NAME: "UnexpectedJsonValue",
                      VAL: [
                        [],
                        tag
                      ]
                    }
                  };
        }
      }));

var CameraType = {
  Enum: Enum,
  toEnum: toEnum,
  fromEnum: fromEnum,
  jsonCodec: jsonCodec$15
};

var jsonCodec$16 = Jzon.object4((function (param) {
        return [
                param.imgBlobKey,
                param.imgSize,
                param.rotation,
                param.transformToPlaceModelSpace
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  imgBlobKey: param[0],
                  imgSize: param[1],
                  rotation: param[2],
                  transformToPlaceModelSpace: param[3]
                }
              };
      }), Jzon.field("imgBlobKey", Document$LumiStore.Key.jsonCodec()), Jzon.field("imgSize", V2i$Linear.jsonCodec), Jzon.field("rotation", RightAngle$LumiDocuments.RightAngle.jsonCodec), Jzon.field("transformToPlaceModelSpace", M23f$Linear.jsonCodec));

var ReferenceImage = {
  jsonCodec: jsonCodec$16
};

var jsonCodec$17 = Jzon.object12((function (param) {
        return [
                param.name,
                param.description,
                param.macAddress,
                param.staticHostname,
                param.port,
                param.rotation,
                param.type_,
                param.referenceImage,
                param.referenceBrightnessPolygon,
                param.referenceBrightnessThreshold,
                param.operationalState,
                param.facilityServer
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  name: param[0],
                  description: param[1],
                  macAddress: param[2],
                  staticHostname: param[3],
                  port: param[4],
                  rotation: param[5],
                  type_: param[6],
                  referenceImage: param[7],
                  referenceBrightnessPolygon: param[8],
                  referenceBrightnessThreshold: param[9],
                  operationalState: param[10],
                  facilityServer: param[11]
                }
              };
      }), Jzon.field("name", Jzon.string), Jzon.field("description", Jzon.string), Jzon.field("macAddress", Jzon.nullable(MacAddress$IP.jsonCodec)), Jzon.field("staticHostname", Jzon.nullable(Jzon.string)), Jzon.field("port", Jzon.$$int), Jzon.field("rotation", RightAngle$LumiDocuments.RightAngle.jsonCodec), Jzon.field("type", jsonCodec$15), Jzon.field("referenceImage", Jzon.nullable(jsonCodec$16)), Jzon.field("referenceBrightnessPolygon", Jzon.nullable(Jzon.array(V2i$Linear.jsonCodec))), Jzon.field("referenceBrightnessThreshold", Jzon.nullable(Jzon.$$float)), Jzon.field("operationalState", jsonCodec), Jzon.field("facilityServer", Document$LumiStore.Key.jsonCodec()));

var Camera = {
  jsonCodec: jsonCodec$17
};

function documentName(param) {
  return param.name;
}

var icon = JsxRuntime.jsx(FontAwesome.Solid.make, {
      name: "camera"
    });

var Schema = Schema$LumiStore.Make({
      documentName: documentName,
      tag: "camera",
      jsonCodec: jsonCodec$17,
      icon: icon
    });

export {
  CameraOperationalState ,
  Lumicam ,
  PTZFocus ,
  PTZIris ,
  PTZ ,
  Axis ,
  HanwhaVision$1 as HanwhaVision,
  CameraType ,
  ReferenceImage ,
  Camera ,
  Schema ,
}
/* jsonCodec Not a pure module */
