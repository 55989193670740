// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as V2f$Linear from "./V2f.bs.mjs";
import * as Core__Result from "@rescript/core/lib/es6/src/Core__Result.bs.mjs";

function asV2f(v2) {
  return v2._0;
}

function normalize(v2) {
  return {
          TAG: "N2",
          _0: V2f$Linear.scaleTo(v2, 1)
        };
}

function map(v2, f) {
  return normalize(f(v2._0));
}

var jsonCodec = Jzon.custom((function (n2) {
        return Jzon.encodeWith(n2._0, V2f$Linear.jsonCodec);
      }), (function (json) {
        return Core__Result.map(Jzon.decodeWith(json, V2f$Linear.jsonCodec), normalize);
      }));

export {
  asV2f ,
  normalize ,
  map ,
  jsonCodec ,
}
/* jsonCodec Not a pure module */
