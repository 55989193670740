// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as V3$Linear from "./V3.bs.mjs";

var zero = V3$Linear.fill(0);

function plus(param, param$1) {
  return [
          param[0] + param$1[0],
          param[1] + param$1[1],
          param[2] + param$1[2]
        ];
}

function minus(param, param$1) {
  return [
          param[0] - param$1[0],
          param[1] - param$1[1],
          param[2] - param$1[2]
        ];
}

function scale(vec, factor) {
  return V3$Linear.map(vec, (function (v) {
                return v * factor;
              }));
}

function dot(param, param$1) {
  return param[0] * param$1[0] + param[1] * param$1[1] + param[2] * param$1[2];
}

function quadrance(vec) {
  return dot(vec, vec);
}

function length(vec) {
  return Math.sqrt(dot(vec, vec));
}

function angle(a, b) {
  return Math.acos(dot(a, b) / (Math.sqrt(dot(a, a)) * Math.sqrt(dot(b, b))));
}

var jsonCodec = V3$Linear.jsonCodec(Jzon.$$float);

export {
  zero ,
  plus ,
  minus ,
  scale ,
  dot ,
  quadrance ,
  length ,
  angle ,
  jsonCodec ,
}
/* zero Not a pure module */
