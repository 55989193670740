// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsCookieJs from "lumi-rescript/src/js-cookie.js";

var Impl = {};

function set(name, value, expires, path, domain, secure, sameSite, param) {
  JsCookieJs.set(name, value, {
        expires: expires,
        path: path,
        domain: domain,
        secure: secure,
        sameSite: sameSite
      });
}

export {
  Impl ,
  set ,
}
/* lumi-rescript/src/js-cookie.js Not a pure module */
