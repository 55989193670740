// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as LumiDate from "lumi-rescript/lib/es6/src/LumiDate.bs.mjs";
import * as FontAwesome from "lumi-rescript/lib/es6/src/FontAwesome.bs.mjs";
import * as Schema$LumiStore from "lumi-store/lib/es6/src/Schema.bs.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Document$LumiStore from "lumi-store/lib/es6/src/Document.bs.mjs";

var jsonCodec = Jzon.object3((function (param) {
        return [
                param.place,
                param.beginTime,
                param.endTime
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  place: param[0],
                  beginTime: param[1],
                  endTime: param[2]
                }
              };
      }), Jzon.field("place", Document$LumiStore.Key.jsonCodec()), Jzon.field("beginTime", LumiDate.DateTime.jsonCodec), Jzon.field("endTime", Jzon.nullable(LumiDate.DateTime.jsonCodec)));

var Occupation = {
  jsonCodec: jsonCodec
};

function documentName(param) {
  
}

var icon = JsxRuntime.jsx(FontAwesome.Solid.make, {
      name: "clock"
    });

var Schema = Schema$LumiStore.Make({
      documentName: documentName,
      tag: "occupation",
      jsonCodec: jsonCodec,
      icon: icon
    });

export {
  Occupation ,
  Schema ,
}
/* jsonCodec Not a pure module */
