// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.bs.mjs";
import * as Locale$LumiI18n from "./Locale.bs.mjs";

function MakeProvider(P) {
  var translate = function (locale, entry) {
    var initTranslation = P.translate(locale, entry);
    var defaultLocale = P.defaultLocale;
    var translation = defaultLocale !== undefined && defaultLocale !== locale ? Core__Option.orElse(initTranslation, P.translate(defaultLocale, entry)) : initTranslation;
    return Core__Option.getOr(translation, "⚠ no translation ⚠");
  };
  var string = function (locale, entry) {
    return translate(locale, entry);
  };
  var Translation$MakeProvider$Text = function (props) {
    var locale = Locale$LumiI18n.useContext();
    return translate(locale, props.entry);
  };
  var $$Text = {
    string: string,
    make: Translation$MakeProvider$Text
  };
  return {
          translate: translate,
          $$Text: $$Text
        };
}

export {
  MakeProvider ,
}
/* Locale-LumiI18n Not a pure module */
