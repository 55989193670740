// Generated by ReScript, PLEASE EDIT WITH CARE

import * as FontAwesome from "lumi-rescript/lib/es6/src/FontAwesome.bs.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Translation$Dashboard from "./Translation.bs.mjs";

function PrintView(props) {
  var dispatch = props.dispatch;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("nav", {
                      children: JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx("div", {
                                  children: JsxRuntime.jsxs("div", {
                                        children: [
                                          JsxRuntime.jsxs("a", {
                                                children: [
                                                  JsxRuntime.jsx("span", {
                                                        children: JsxRuntime.jsx(FontAwesome.Solid.make, {
                                                              name: "times-circle"
                                                            }),
                                                        className: "icon"
                                                      }),
                                                  JsxRuntime.jsx("span", {
                                                        children: JsxRuntime.jsx(Translation$Dashboard.Provider.$$Text.make, {
                                                              entry: "Close"
                                                            }),
                                                        className: "is-hidden-mobile"
                                                      })
                                                ],
                                                className: "button is-danger",
                                                onClick: (function (param) {
                                                    dispatch("ClosePrint");
                                                  })
                                              }),
                                          JsxRuntime.jsxs("a", {
                                                children: [
                                                  JsxRuntime.jsx("span", {
                                                        children: JsxRuntime.jsx(FontAwesome.Solid.make, {
                                                              name: "print"
                                                            }),
                                                        className: "icon"
                                                      }),
                                                  JsxRuntime.jsx("span", {
                                                        children: JsxRuntime.jsx(Translation$Dashboard.Provider.$$Text.make, {
                                                              entry: "Print"
                                                            }),
                                                        className: "is-hidden-mobile"
                                                      })
                                                ],
                                                className: "button is-success",
                                                onClick: (function (param) {
                                                    window.print();
                                                  })
                                              })
                                        ],
                                        className: "buttons"
                                      }),
                                  className: "navbar-item"
                                }),
                            className: "navbar-brand"
                          }),
                      className: "navbar is-primary",
                      role: "navigation"
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("div", {
                            children: props.element,
                            className: "container"
                          }),
                      className: "print-wrapper scrollable"
                    })
              ]
            });
}

var make = PrintView;

export {
  make ,
}
/* FontAwesome Not a pure module */
