// Generated by ReScript, PLEASE EDIT WITH CARE


function isUpperPlace(placeClass) {
  if (typeof placeClass !== "object") {
    if (placeClass === "PlaceClassVertical") {
      return true;
    } else {
      return false;
    }
  }
  if (placeClass.TAG !== "PlaceClassGutter") {
    return false;
  }
  switch (placeClass._0) {
    case "GutterUpper" :
        return true;
    case "GutterLower" :
    case "GutterAngled" :
        return false;
    
  }
}

export {
  isUpperPlace ,
}
/* No side effect */
