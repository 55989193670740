// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as LumiReact from "./LumiReact.bs.mjs";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.bs.mjs";
import * as ReactDOMStyle from "@rescript/react/lib/es6/src/ReactDOMStyle.bs.mjs";
import * as JsxRuntime from "react/jsx-runtime";

import '@fortawesome/fontawesome-free/css/all.css'
;

function FontAwesome$Icon(props) {
  var __inverse = props.inverse;
  var stack = props.stack;
  var __rotate = props.rotate;
  var __pulse = props.pulse;
  var __spin = props.spin;
  var spin = __spin !== undefined ? __spin : false;
  var pulse = __pulse !== undefined ? __pulse : false;
  var rotate = __rotate !== undefined ? __rotate : 0;
  var inverse = __inverse !== undefined ? __inverse : false;
  var match;
  var exit = 0;
  if (rotate < 180) {
    if (rotate !== 0) {
      if (rotate !== 90) {
        exit = 1;
      } else {
        match = [
          ["fa-rotate-90"],
          undefined
        ];
      }
    } else {
      match = [
        [],
        undefined
      ];
    }
  } else if (rotate !== 180) {
    if (rotate !== 270) {
      exit = 1;
    } else {
      match = [
        ["fa-rotate-270"],
        undefined
      ];
    }
  } else {
    match = [
      ["fa-rotate-180"],
      undefined
    ];
  }
  if (exit === 1) {
    match = [
      ["fa-rotate-by"],
      [
        "--fa-rotate-angle",
        rotate.toString() + "deg"
      ]
    ];
  }
  var style = Core__Option.map(match[1], (function (param) {
          return ReactDOMStyle.unsafeAddProp({}, param[0], param[1]);
        }));
  return JsxRuntime.jsx("i", {
              className: LumiReact.classList([
                      [
                        props.variant,
                        true
                      ],
                      [
                        "fa-" + props.name,
                        true
                      ],
                      [
                        "fa-spin",
                        spin
                      ],
                      [
                        "fa-pulse",
                        pulse
                      ],
                      [
                        "fa-stack-1x",
                        Caml_obj.equal(stack, "Small")
                      ],
                      [
                        "fa-stack-2x",
                        Caml_obj.equal(stack, "Large")
                      ],
                      [
                        "fa-inverse",
                        inverse
                      ]
                    ].concat(match[0].map(function (x) {
                            return [
                                    x,
                                    true
                                  ];
                          }))),
              style: style
            });
}

var Icon = {
  make: FontAwesome$Icon
};

function FontAwesome$Solid(props) {
  var __inverse = props.inverse;
  var __rotate = props.rotate;
  var __pulse = props.pulse;
  var __spin = props.spin;
  var spin = __spin !== undefined ? __spin : false;
  var pulse = __pulse !== undefined ? __pulse : false;
  var rotate = __rotate !== undefined ? __rotate : 0;
  var inverse = __inverse !== undefined ? __inverse : false;
  return JsxRuntime.jsx(FontAwesome$Icon, {
              variant: "fas",
              name: props.name,
              spin: spin,
              pulse: pulse,
              rotate: rotate,
              stack: props.stack,
              inverse: inverse
            });
}

var Solid = {
  make: FontAwesome$Solid
};

function FontAwesome$Regular(props) {
  var __inverse = props.inverse;
  var __rotate = props.rotate;
  var __pulse = props.pulse;
  var __spin = props.spin;
  var spin = __spin !== undefined ? __spin : false;
  var pulse = __pulse !== undefined ? __pulse : false;
  var rotate = __rotate !== undefined ? __rotate : 0;
  var inverse = __inverse !== undefined ? __inverse : false;
  return JsxRuntime.jsx(FontAwesome$Icon, {
              variant: "far",
              name: props.name,
              spin: spin,
              pulse: pulse,
              rotate: rotate,
              stack: props.stack,
              inverse: inverse
            });
}

var Regular = {
  make: FontAwesome$Regular
};

function FontAwesome$Brand(props) {
  var __inverse = props.inverse;
  var __rotate = props.rotate;
  var __pulse = props.pulse;
  var __spin = props.spin;
  var spin = __spin !== undefined ? __spin : false;
  var pulse = __pulse !== undefined ? __pulse : false;
  var rotate = __rotate !== undefined ? __rotate : 0;
  var inverse = __inverse !== undefined ? __inverse : false;
  return JsxRuntime.jsx(FontAwesome$Icon, {
              variant: "fab",
              name: props.name,
              spin: spin,
              pulse: pulse,
              rotate: rotate,
              stack: props.stack,
              inverse: inverse
            });
}

var Brand = {
  make: FontAwesome$Brand
};

function FontAwesome$Stack(props) {
  return JsxRuntime.jsx("span", {
              children: props.children,
              className: "fa-stack"
            });
}

var Stack = {
  make: FontAwesome$Stack
};

export {
  Icon ,
  Solid ,
  Regular ,
  Brand ,
  Stack ,
}
/*  Not a pure module */
