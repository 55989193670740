// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as V2$Linear from "./V2.bs.mjs";

var zero = V2$Linear.fill(0);

function plus(param, param$1) {
  return [
          param[0] + param$1[0],
          param[1] + param$1[1]
        ];
}

function minus(param, param$1) {
  return [
          param[0] - param$1[0],
          param[1] - param$1[1]
        ];
}

function scale(vec, factor) {
  return V2$Linear.map(vec, (function (v) {
                return v * factor;
              }));
}

function dot(param, param$1) {
  return param[0] * param$1[0] + param[1] * param$1[1];
}

function quadrance(vec) {
  return dot(vec, vec);
}

function length(vec) {
  return Math.sqrt(dot(vec, vec));
}

function scaleTo(vec, len) {
  var l = Math.sqrt(dot(vec, vec));
  if (l !== 0) {
    return scale(vec, len / l);
  } else {
    return zero;
  }
}

function rotate(param, a) {
  var y = param[1];
  var x = param[0];
  return [
          x * Math.cos(a) - y * Math.sin(a),
          x * Math.sin(a) + y * Math.cos(a)
        ];
}

function perpendicularCW(param) {
  return [
          param[1],
          - param[0]
        ];
}

function perpendicularCCW(param) {
  return [
          - param[1],
          param[0]
        ];
}

function angle(a, b) {
  var l = Math.sqrt(dot(a, a)) * Math.sqrt(dot(b, b));
  if (l !== 0) {
    return Math.acos(dot(a, b) / l);
  } else {
    return 0;
  }
}

function dirAngle(param, param$1) {
  return Math.atan2(param[1], param[0]) - Math.atan2(param$1[1], param$1[0]);
}

function asV3f(param, f) {
  var match = f([
        param[0],
        param[1],
        1
      ]);
  return [
          match[0],
          match[1]
        ];
}

function crossZ(param, param$1) {
  return param[0] * param$1[1] - param[1] * param$1[0];
}

var jsonCodec = V2$Linear.jsonCodec(Jzon.$$float);

export {
  zero ,
  plus ,
  minus ,
  scale ,
  dot ,
  quadrance ,
  length ,
  scaleTo ,
  rotate ,
  perpendicularCW ,
  perpendicularCCW ,
  angle ,
  dirAngle ,
  asV3f ,
  crossZ ,
  jsonCodec ,
}
/* zero Not a pure module */
