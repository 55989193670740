// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as V3$Linear from "./V3.bs.mjs";
import * as M23$Linear from "./M23.bs.mjs";
import * as N2f$Linear from "./N2f.bs.mjs";
import * as V2f$Linear from "./V2f.bs.mjs";
import * as V3f$Linear from "./V3f.bs.mjs";
import * as M33f$Linear from "./M33f.bs.mjs";

var zero = M23$Linear.fill(0);

function toM33f(param) {
  return [
          param[0],
          param[1],
          [
            0,
            0,
            1
          ]
        ];
}

function fromM33f(param) {
  return [
          param[0],
          param[1]
        ];
}

function inverse(m) {
  return fromM33f(M33f$Linear.inverse(toM33f(m)));
}

var jsonCodec = M23$Linear.jsonCodec(Jzon.$$float);

function scale(m, s) {
  return M23$Linear.map(m, (function (e) {
                return e * s;
              }));
}

function v2f(mat, vec) {
  return V2f$Linear.asV3f(vec, (function (vec) {
                return V3$Linear.map(toM33f(mat), (function (row) {
                              return V3f$Linear.dot(row, vec);
                            }));
              }));
}

function n2f(m23, n2) {
  var origin$p = v2f(m23, V2f$Linear.zero);
  var fromOrigin$p = v2f(m23, N2f$Linear.asV2f(n2));
  return N2f$Linear.normalize(V2f$Linear.minus(fromOrigin$p, origin$p));
}

var Mult = {
  v2f: v2f,
  n2f: n2f
};

export {
  zero ,
  toM33f ,
  fromM33f ,
  inverse ,
  jsonCodec ,
  scale ,
  Mult ,
}
/* zero Not a pure module */
