// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";

var numberRegExp = /[0-9]+/g;

function padNumbersInString(length, str) {
  return str.replace(numberRegExp, (function (match, param, param$1) {
                return match.padStart(length, "0");
              }));
}

function stableSortOn(array, key) {
  return Belt_SortArray.stableSortBy(array, (function (a, b) {
                return Caml_obj.compare(key(a), key(b));
              }));
}

export {
  numberRegExp ,
  padNumbersInString ,
  stableSortOn ,
}
/* No side effect */
