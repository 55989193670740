// Generated by ReScript, PLEASE EDIT WITH CARE

import * as LumiReact from "lumi-rescript/lib/es6/src/LumiReact.bs.mjs";
import * as FontAwesome from "lumi-rescript/lib/es6/src/FontAwesome.bs.mjs";
import * as Locale$LumiI18n from "lumi-i18n/lib/es6/src/Locale.bs.mjs";
import * as Route$Dashboard from "./Route.bs.mjs";
import * as Assets$Dashboard from "./Assets.bs.mjs";
import * as Router$Dashboard from "./Router.bs.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Document$LumiStore from "lumi-store/lib/es6/src/Document.bs.mjs";
import * as Localization$LumiI18n from "lumi-i18n/lib/es6/src/Localization.bs.mjs";
import * as PrefersDark$LumiBulma from "lumi-bulma/lib/es6/src/PrefersDark.bs.mjs";
import * as Translation$Dashboard from "./Translation.bs.mjs";
import * as Barrier$LumiAuthentication from "lumi-authentication/lib/es6/src/Barrier.bs.mjs";

function NavBar(props) {
  var dispatch = props.dispatch;
  var route = props.route;
  var kiosk = props.kiosk;
  var navBarActive = props.navBarActive;
  var locale = Locale$LumiI18n.useContext();
  var setLocale = Localization$LumiI18n.SetLocale.useContext();
  var locales = Localization$LumiI18n.Locales.useContext();
  var accountDoc = Barrier$LumiAuthentication.Account.useContext();
  var toAuth = Barrier$LumiAuthentication.Dispatch.useContext();
  var match = PrefersDark$LumiBulma.useForceDarkMode();
  var setForceDarkMode = match[1];
  var forceDarkMode = match[0];
  var currentFacilityMapRoute;
  currentFacilityMapRoute = typeof route !== "object" ? "Default" : route._0;
  var pages = [
    [
      "CurrentOccupation",
      {
        TAG: "CurrentOccupation",
        _0: currentFacilityMapRoute
      },
      JsxRuntime.jsx(FontAwesome.Brand.make, {
            name: "product-hunt"
          })
    ],
    [
      "AbandonedVehicles",
      {
        TAG: "AbandonedVehicles",
        _0: currentFacilityMapRoute
      },
      JsxRuntime.jsx(FontAwesome.Solid.make, {
            name: "stopwatch"
          })
    ],
    [
      "Statistics",
      "Statistics",
      JsxRuntime.jsx(FontAwesome.Solid.make, {
            name: "chart-area"
          })
    ]
  ];
  return JsxRuntime.jsxs("nav", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx("img", {
                                    height: "28",
                                    src: Assets$Dashboard.logo
                                  }),
                              className: "navbar-item"
                            }),
                        pages.map(function (param, index) {
                              var targetRoute = param[1];
                              return JsxRuntime.jsxs("a", {
                                          children: [
                                            JsxRuntime.jsx("span", {
                                                  children: param[2],
                                                  className: "icon"
                                                }),
                                            JsxRuntime.jsx("span", {
                                                  children: JsxRuntime.jsx(Translation$Dashboard.Provider.$$Text.make, {
                                                        entry: param[0]
                                                      }),
                                                  className: "is-hidden-mobile"
                                                })
                                          ],
                                          className: LumiReact.classList([
                                                [
                                                  "navbar-item",
                                                  true
                                                ],
                                                [
                                                  "is-active",
                                                  Route$Dashboard.sameTab(route, targetRoute)
                                                ]
                                              ]),
                                          onClick: (function (param) {
                                              Router$Dashboard.navigate(targetRoute);
                                            })
                                        }, index.toString());
                            }),
                        JsxRuntime.jsxs("a", {
                              children: [
                                JsxRuntime.jsx("span", {}),
                                JsxRuntime.jsx("span", {}),
                                JsxRuntime.jsx("span", {})
                              ],
                              className: LumiReact.classList([
                                    [
                                      "navbar-burger",
                                      true
                                    ],
                                    [
                                      "burger",
                                      true
                                    ],
                                    [
                                      "is-active",
                                      navBarActive
                                    ]
                                  ]),
                              role: "button",
                              onClick: (function (param) {
                                  dispatch("ToggleNavBar");
                                })
                            })
                      ],
                      className: "navbar-brand"
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: pages.map(function (param, index) {
                                    var targetRoute = param[1];
                                    return JsxRuntime.jsxs("a", {
                                                children: [
                                                  JsxRuntime.jsx("span", {
                                                        children: param[2],
                                                        className: "icon"
                                                      }),
                                                  JsxRuntime.jsx("span", {
                                                        children: JsxRuntime.jsx(Translation$Dashboard.Provider.$$Text.make, {
                                                              entry: param[0]
                                                            })
                                                      })
                                                ],
                                                className: LumiReact.classList([
                                                      [
                                                        "navbar-item",
                                                        true
                                                      ],
                                                      [
                                                        "is-active",
                                                        Route$Dashboard.sameTab(route, targetRoute)
                                                      ]
                                                    ]),
                                                onClick: (function (param) {
                                                    Router$Dashboard.navigate(targetRoute);
                                                  })
                                              }, index.toString());
                                  }),
                              className: LumiReact.classList([
                                    [
                                      "navbar-start",
                                      true
                                    ],
                                    [
                                      "is-hidden",
                                      !navBarActive
                                    ]
                                  ])
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsxs("a", {
                                              children: [
                                                JsxRuntime.jsx("span", {
                                                      children: JsxRuntime.jsx(FontAwesome.Solid.make, {
                                                            name: "globe"
                                                          }),
                                                      className: "icon"
                                                    }),
                                                JsxRuntime.jsx("span", {
                                                      children: JsxRuntime.jsx(Translation$Dashboard.Provider.$$Text.make, {
                                                            entry: "ChangeLanguage"
                                                          })
                                                    })
                                              ],
                                              className: "navbar-link icon-text is-flex-wrap-nowrap"
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: locales.map(function (newLocale) {
                                                    return JsxRuntime.jsx("a", {
                                                                children: Locale$LumiI18n.name(newLocale),
                                                                className: LumiReact.classList([
                                                                      [
                                                                        "navbar-item",
                                                                        true
                                                                      ],
                                                                      [
                                                                        "is-active",
                                                                        newLocale === locale
                                                                      ]
                                                                    ]),
                                                                onClick: (function (param) {
                                                                    setLocale(newLocale);
                                                                  })
                                                              }, Locale$LumiI18n.toString(newLocale));
                                                  }),
                                              className: "navbar-dropdown is-right"
                                            })
                                      ],
                                      className: "navbar-item has-dropdown is-hoverable"
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        kiosk ? JsxRuntime.jsxs("span", {
                                                children: [
                                                  JsxRuntime.jsx("span", {
                                                        children: JsxRuntime.jsx(FontAwesome.Solid.make, {
                                                              name: "wrench"
                                                            }),
                                                        className: "icon"
                                                      }),
                                                  JsxRuntime.jsx("span", {
                                                        children: JsxRuntime.jsx(Translation$Dashboard.Provider.$$Text.make, {
                                                              entry: "Settings"
                                                            })
                                                      })
                                                ],
                                                className: "navbar-link icon-text"
                                              }) : JsxRuntime.jsxs("span", {
                                                children: [
                                                  JsxRuntime.jsx("span", {
                                                        children: JsxRuntime.jsx(FontAwesome.Solid.make, {
                                                              name: "user"
                                                            }),
                                                        className: "icon"
                                                      }),
                                                  JsxRuntime.jsx("span", {
                                                        children: Document$LumiStore.contents(accountDoc).name
                                                      })
                                                ],
                                                className: "navbar-link icon-text"
                                              }),
                                        JsxRuntime.jsxs("div", {
                                              children: [
                                                JsxRuntime.jsxs("a", {
                                                      children: [
                                                        JsxRuntime.jsx("span", {
                                                              children: JsxRuntime.jsx(FontAwesome.Solid.make, {
                                                                    name: forceDarkMode ? "check-square" : "square"
                                                                  }),
                                                              className: "icon"
                                                            }),
                                                        JsxRuntime.jsx("span", {
                                                              children: JsxRuntime.jsx(Translation$Dashboard.Provider.$$Text.make, {
                                                                    entry: "ForceDarkMode"
                                                                  })
                                                            })
                                                      ],
                                                      className: "navbar-item icon-text is-flex-wrap-nowrap",
                                                      onClick: (function (param) {
                                                          setForceDarkMode(function (param) {
                                                                return !forceDarkMode;
                                                              });
                                                        })
                                                    }),
                                                kiosk ? null : JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                                        children: [
                                                          JsxRuntime.jsxs("a", {
                                                                children: [
                                                                  JsxRuntime.jsx("span", {
                                                                        children: JsxRuntime.jsx(FontAwesome.Solid.make, {
                                                                              name: "lock"
                                                                            }),
                                                                        className: "icon"
                                                                      }),
                                                                  JsxRuntime.jsx("span", {
                                                                        children: JsxRuntime.jsx(Translation$Dashboard.Provider.$$Text.make, {
                                                                              entry: "ChangePassword"
                                                                            })
                                                                      })
                                                                ],
                                                                className: "navbar-item icon-text is-flex-wrap-nowrap",
                                                                onClick: (function (param) {
                                                                    dispatch({
                                                                          TAG: "ChangePassword",
                                                                          _0: true
                                                                        });
                                                                  })
                                                              }),
                                                          JsxRuntime.jsxs("a", {
                                                                children: [
                                                                  JsxRuntime.jsx("span", {
                                                                        children: JsxRuntime.jsx(FontAwesome.Solid.make, {
                                                                              name: "sign-out-alt"
                                                                            }),
                                                                        className: "icon"
                                                                      }),
                                                                  JsxRuntime.jsx("span", {
                                                                        children: JsxRuntime.jsx(Translation$Dashboard.Provider.$$Text.make, {
                                                                              entry: "LogOut"
                                                                            })
                                                                      })
                                                                ],
                                                                className: "navbar-item icon-text is-flex-wrap-nowrap",
                                                                onClick: (function (param) {
                                                                    toAuth("PerformLogout");
                                                                  })
                                                              })
                                                        ]
                                                      })
                                              ],
                                              className: "navbar-dropdown is-right"
                                            })
                                      ],
                                      className: "navbar-item has-dropdown is-hoverable"
                                    })
                              ],
                              className: "navbar-end"
                            })
                      ],
                      className: LumiReact.classList([
                            [
                              "navbar-menu",
                              true
                            ],
                            [
                              "is-active",
                              navBarActive
                            ]
                          ])
                    })
              ],
              className: "navbar is-primary",
              role: "navigation"
            });
}

var make = NavBar;

export {
  make ,
}
/* LumiReact Not a pure module */
