// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Core__Int from "@rescript/core/lib/es6/src/Core__Int.bs.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Core__JSON from "@rescript/core/lib/es6/src/Core__JSON.bs.mjs";
import * as LumiOption from "./LumiOption.bs.mjs";
import * as LumiResult from "./LumiResult.bs.mjs";
import * as Belt_MapInt from "rescript/lib/es6/belt_MapInt.js";
import * as Core__Error from "@rescript/core/lib/es6/src/Core__Error.bs.mjs";
import * as Core__Result from "@rescript/core/lib/es6/src/Core__Result.bs.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";

var KeyCodec = {};

function keyCustom(encode, decode) {
  return {
          encode: encode,
          decode: decode
        };
}

function taggedVariant(destruct, construct) {
  return Jzon.object2(destruct, construct, Jzon.field("tag", Jzon.string), Jzon.field("contents", Jzon.json));
}

function taggedVariantOptionalContents(destruct, construct) {
  return Jzon.object2(destruct, construct, Jzon.field("tag", Jzon.string), Jzon.optional(Jzon.field("contents", Jzon.json)));
}

function keyValuePairs(valuesCodec) {
  return Jzon.custom((function (pairs) {
                return Object.fromEntries(pairs.map(function (param) {
                                return [
                                        param[0],
                                        Jzon.encodeWith(param[1], valuesCodec)
                                      ];
                              }));
              }), (function (json) {
                return Core__Result.flatMap(Jzon.asObject(json), (function (obj) {
                              var match = Belt_Array.unzip(Object.entries(obj).map(function (param) {
                                        var key = param[0];
                                        return [
                                                key,
                                                Core__Result.mapError(Jzon.decodeWith(param[1], valuesCodec), (function (__x) {
                                                        return Jzon.DecodingError.prependLocation(__x, {
                                                                    TAG: "Field",
                                                                    _0: key
                                                                  });
                                                      }))
                                              ];
                                      }));
                              var keys = match[0];
                              return Core__Result.map(LumiResult.sequence(match[1]), (function (vals) {
                                            return Belt_Array.zip(keys, vals);
                                          }));
                            }));
              }));
}

function mapString(valuesCodec) {
  var arrayCodec = keyValuePairs(valuesCodec);
  return Jzon.custom((function (map) {
                return Jzon.encodeWith(Belt_MapString.toArray(map), arrayCodec);
              }), (function (json) {
                return Core__Result.map(Jzon.decodeWith(json, arrayCodec), Belt_MapString.fromArray);
              }));
}

function mapInt(valuesCodec) {
  return Jzon.custom((function (map) {
                return Object.fromEntries(Belt_MapInt.toArray(map).map(function (param) {
                                return [
                                        param[0].toString(),
                                        Jzon.encodeWith(param[1], valuesCodec)
                                      ];
                              }));
              }), (function (json) {
                return Core__Result.map(Core__Result.flatMap(Jzon.asObject(json), (function (dict) {
                                  return LumiResult.sequence(Object.entries(dict).map(function (param) {
                                                  return LumiResult.sequence2([
                                                              LumiOption.okOr(Core__Int.fromString(undefined, param[0]), {
                                                                    NAME: "UnexpectedJsonValue",
                                                                    VAL: [
                                                                      [],
                                                                      "not an int"
                                                                    ]
                                                                  }),
                                                              Jzon.decodeWith(param[1], valuesCodec)
                                                            ]);
                                                }));
                                })), Belt_MapInt.fromArray);
              }));
}

function set(valueCodec, id) {
  return Jzon.custom((function (set) {
                return Jzon.encodeWith(Belt_Set.toArray(set), Jzon.array(valueCodec));
              }), (function (json) {
                return Core__Result.map(Jzon.decodeWith(json, Jzon.array(valueCodec)), (function (x) {
                              return Belt_Set.fromArray(x, id);
                            }));
              }));
}

var setString = Jzon.custom((function (set) {
        return Jzon.encodeWith(Belt_SetString.toArray(set), Jzon.array(Jzon.string));
      }), (function (json) {
        return Core__Result.map(Jzon.decodeWith(json, Jzon.array(Jzon.string)), Belt_SetString.fromArray);
      }));

function tuple2(elementCodec1, elementCodec2) {
  return Jzon.custom((function (param) {
                return [
                        Jzon.encodeWith(param[0], elementCodec1),
                        Jzon.encodeWith(param[1], elementCodec2)
                      ];
              }), (function (json) {
                var match = Core__JSON.Classify.classify(json);
                if (typeof match === "object" && match.TAG === "Array") {
                  var match$1 = match._0;
                  if (match$1.length === 2) {
                    var elemJson1 = match$1[0];
                    var elemJson2 = match$1[1];
                    var match$2 = Jzon.decodeWith(elemJson1, elementCodec1);
                    var match$3 = Jzon.decodeWith(elemJson2, elementCodec2);
                    if (match$2.TAG === "Ok") {
                      if (match$3.TAG === "Ok") {
                        return {
                                TAG: "Ok",
                                _0: [
                                  match$2._0,
                                  match$3._0
                                ]
                              };
                      } else {
                        return {
                                TAG: "Error",
                                _0: match$3._0
                              };
                      }
                    } else {
                      return {
                              TAG: "Error",
                              _0: match$2._0
                            };
                    }
                  }
                  
                }
                return {
                        TAG: "Error",
                        _0: {
                          NAME: "UnexpectedJsonType",
                          VAL: [
                            [],
                            "array[2]",
                            json
                          ]
                        }
                      };
              }));
}

function tuple3(elementCodec1, elementCodec2, elementCodec3) {
  return Jzon.custom((function (param) {
                return [
                        Jzon.encodeWith(param[0], elementCodec1),
                        Jzon.encodeWith(param[1], elementCodec2),
                        Jzon.encodeWith(param[2], elementCodec3)
                      ];
              }), (function (json) {
                var match = Core__JSON.Classify.classify(json);
                if (typeof match === "object" && match.TAG === "Array") {
                  var match$1 = match._0;
                  if (match$1.length === 3) {
                    var elemJson1 = match$1[0];
                    var elemJson2 = match$1[1];
                    var elemJson3 = match$1[2];
                    var match$2 = Jzon.decodeWith(elemJson1, elementCodec1);
                    var match$3 = Jzon.decodeWith(elemJson2, elementCodec2);
                    var match$4 = Jzon.decodeWith(elemJson3, elementCodec3);
                    if (match$2.TAG === "Ok") {
                      if (match$3.TAG === "Ok") {
                        if (match$4.TAG === "Ok") {
                          return {
                                  TAG: "Ok",
                                  _0: [
                                    match$2._0,
                                    match$3._0,
                                    match$4._0
                                  ]
                                };
                        } else {
                          return {
                                  TAG: "Error",
                                  _0: match$4._0
                                };
                        }
                      } else {
                        return {
                                TAG: "Error",
                                _0: match$3._0
                              };
                      }
                    } else {
                      return {
                              TAG: "Error",
                              _0: match$2._0
                            };
                    }
                  }
                  
                }
                return {
                        TAG: "Error",
                        _0: {
                          NAME: "UnexpectedJsonType",
                          VAL: [
                            [],
                            "array[3]",
                            json
                          ]
                        }
                      };
              }));
}

var unit = Jzon.custom((function () {
        return [];
      }), (function (json) {
        var match = Core__JSON.Classify.classify(json);
        if (typeof match === "object" && match.TAG === "Array" && match._0.length === 0) {
          return {
                  TAG: "Ok",
                  _0: undefined
                };
        }
        return {
                TAG: "Error",
                _0: {
                  NAME: "UnexpectedJsonType",
                  VAL: [
                    [],
                    "[]",
                    json
                  ]
                }
              };
      }));

function singleConstructor(singleValue) {
  return Jzon.custom((function (value) {
                if (Caml_obj.equal(value, singleValue)) {
                  return [];
                } else {
                  return Core__Error.panic("Received different value than the specified single constructor");
                }
              }), (function (json) {
                var match = Core__JSON.Classify.classify(json);
                if (typeof match === "object" && match.TAG === "Array" && match._0.length === 0) {
                  return {
                          TAG: "Ok",
                          _0: singleValue
                        };
                }
                return {
                        TAG: "Error",
                        _0: {
                          NAME: "UnexpectedJsonType",
                          VAL: [
                            [],
                            "[]",
                            json
                          ]
                        }
                      };
              }));
}

function iso(xCodec, xToY, yToX) {
  return Jzon.custom((function (yValue) {
                return Jzon.encodeWith(yToX(yValue), xCodec);
              }), (function (json) {
                return Core__Result.map(Jzon.decodeWith(json, xCodec), xToY);
              }));
}

function stringCodec(sc) {
  return Jzon.custom((function (v) {
                return Jzon.encodeWith(sc.toString(v), Jzon.string);
              }), (function (json) {
                return Core__Result.flatMap(Jzon.decodeWith(json, Jzon.string), (function (str) {
                              return LumiOption.okOr(sc.fromString(str), {
                                          NAME: "UnexpectedJsonValue",
                                          VAL: [
                                            [],
                                            str
                                          ]
                                        });
                            }));
              }));
}

function map(keyCodec, valuesCodec, id) {
  var keyEncode = keyCodec.encode;
  if (keyEncode.TAG === "ToJsonKeyString") {
    var keyDecode = keyCodec.decode;
    var keyEncode$1 = keyEncode._0;
    if (keyDecode.TAG === "FromJsonKeyString") {
      var keyDecode$1 = keyDecode._0;
      var encode = function (map) {
        return Object.fromEntries(Belt_Map.toArray(map).map(function (param) {
                        return [
                                keyEncode$1(param[0]),
                                Jzon.encodeWith(param[1], valuesCodec)
                              ];
                      }));
      };
      var decode = function (json) {
        return Core__Result.map(Core__Result.flatMap(Jzon.asObject(json), (function (dict) {
                          return LumiResult.sequence(Object.entries(dict).map(function (param) {
                                          return LumiResult.sequence2([
                                                      keyDecode$1(param[0]),
                                                      Jzon.decodeWith(param[1], valuesCodec)
                                                    ]);
                                        }));
                        })), (function (res) {
                      return Belt_Map.fromArray(res, id);
                    }));
      };
      return Jzon.custom(encode, decode);
    }
    throw {
          RE_EXN_ID: "Invalid_argument",
          _1: "invalid codec",
          Error: new Error()
        };
  }
  var keyDecode$2 = keyCodec.decode;
  if (keyDecode$2.TAG === "FromJsonKeyString") {
    throw {
          RE_EXN_ID: "Invalid_argument",
          _1: "invalid codec",
          Error: new Error()
        };
  }
  var keyCodec$1 = Jzon.custom(keyEncode._0, keyDecode$2._0);
  var mapCodec = Jzon.array(tuple2(keyCodec$1, valuesCodec));
  var encode$1 = function (map) {
    return Jzon.encodeWith(Belt_Map.toArray(map), mapCodec);
  };
  var decode$1 = function (json) {
    return Core__Result.map(Jzon.decodeWith(json, mapCodec), (function (res) {
                  return Belt_Map.fromArray(res, id);
                }));
  };
  return Jzon.custom(encode$1, decode$1);
}

export {
  KeyCodec ,
  keyCustom ,
  taggedVariant ,
  taggedVariantOptionalContents ,
  keyValuePairs ,
  mapString ,
  mapInt ,
  set ,
  setString ,
  tuple2 ,
  tuple3 ,
  unit ,
  singleConstructor ,
  iso ,
  stringCodec ,
  map ,
}
/* setString Not a pure module */
