// Generated by ReScript, PLEASE EDIT WITH CARE

import * as FontAwesome from "lumi-rescript/lib/es6/src/FontAwesome.bs.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Translation$Dashboard from "./Translation.bs.mjs";

function Panel$Loading(props) {
  return JsxRuntime.jsxs("p", {
              children: [
                JsxRuntime.jsx("span", {
                      children: JsxRuntime.jsx(FontAwesome.Solid.make, {
                            name: "spinner",
                            spin: true
                          }),
                      className: "panel-icon"
                    }),
                JsxRuntime.jsx(Translation$Dashboard.Provider.$$Text.make, {
                      entry: "Loading"
                    })
              ],
              className: "panel-block"
            });
}

var Loading = {
  make: Panel$Loading
};

export {
  Loading ,
}
/* FontAwesome Not a pure module */
