// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as V2$Linear from "./V2.bs.mjs";
import * as V2f$Linear from "./V2f.bs.mjs";

var zero = V2$Linear.fill(0);

function plus(param, param$1) {
  return [
          param[0] + param$1[0] | 0,
          param[1] + param$1[1] | 0
        ];
}

function minus(param, param$1) {
  return [
          param[0] - param$1[0] | 0,
          param[1] - param$1[1] | 0
        ];
}

function scale(vec, factor) {
  return V2$Linear.map(vec, (function (v) {
                return Math.imul(v, factor);
              }));
}

function dot(param, param$1) {
  return Math.imul(param[0], param$1[0]) + Math.imul(param[1], param$1[1]) | 0;
}

function quadrance(vec) {
  return dot(vec, vec);
}

function length(vec) {
  return Math.sqrt(dot(vec, vec));
}

function angle(a, b) {
  var af = V2$Linear.map(a, (function (prim) {
          return prim;
        }));
  var bf = V2$Linear.map(b, (function (prim) {
          return prim;
        }));
  return V2f$Linear.angle(af, bf);
}

function dirAngle(a, b) {
  var af = V2$Linear.map(a, (function (prim) {
          return prim;
        }));
  var bf = V2$Linear.map(b, (function (prim) {
          return prim;
        }));
  return V2f$Linear.dirAngle(af, bf);
}

var jsonCodec = V2$Linear.jsonCodec(Jzon.$$int);

export {
  zero ,
  plus ,
  minus ,
  scale ,
  dot ,
  quadrance ,
  length ,
  angle ,
  dirAngle ,
  jsonCodec ,
}
/* zero Not a pure module */
