// Generated by ReScript, PLEASE EDIT WITH CARE


var Interval = {};

var Locale = {};

var Duration = {};

var Day = {};

var Formats = {
  dateOnly: "PPP",
  $$short: "Pp",
  $$long: "PPPp",
  full: "PPPPp"
};

export {
  Interval ,
  Locale ,
  Duration ,
  Day ,
  Formats ,
}
/* No side effect */
