// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as Belt_Id from "rescript/lib/es6/belt_Id.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Core__Array from "@rescript/core/lib/es6/src/Core__Array.bs.mjs";
import * as JsxRuntime from "react/jsx-runtime";

var re = /^[0-9a-f]{2}(:[0-9a-f]{2}){5}$/i;

function fromString(str) {
  if (re.test(str)) {
    return str;
  }
  
}

function toString(str) {
  return str;
}

var stringCodec = {
  toString: toString,
  fromString: fromString
};

function toFloat(str) {
  return parseInt(str.split(":").join(""), 16);
}

function fromFloat(num) {
  var hex = num.toString(16).padStart(12, "0");
  if (isFinite(num) && num >= 0 && hex.length === 12) {
    return Core__Array.fromInitializer(6, (function (i) {
                    return hex.substring((i << 1), (i << 1) + 2 | 0);
                  })).join(":");
  }
  
}

function nextMac(mac) {
  var num = toFloat(mac);
  return fromFloat(num + 1);
}

var cmp = Caml_obj.compare;

var Cmp = Belt_Id.MakeComparableU({
      cmp: cmp
    });

function MacAddress(props) {
  return JsxRuntime.jsx("span", {
              children: props.macAddress,
              className: "is-family-monospace"
            });
}

var jsonCodec = Jzon.string;

var make = MacAddress;

export {
  re ,
  fromString ,
  toString ,
  stringCodec ,
  toFloat ,
  fromFloat ,
  nextMac ,
  Cmp ,
  jsonCodec ,
  make ,
}
/* Cmp Not a pure module */
