// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as LumiDate from "lumi-rescript/lib/es6/src/LumiDate.bs.mjs";
import * as FontAwesome from "lumi-rescript/lib/es6/src/FontAwesome.bs.mjs";
import * as Schema$LumiStore from "lumi-store/lib/es6/src/Schema.bs.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Document$LumiStore from "lumi-store/lib/es6/src/Document.bs.mjs";
import * as Types$LumiDocuments from "./Types.bs.mjs";

var jsonCodec = Jzon.object3((function (param) {
        return [
                param.sensorKey,
                param.measurement,
                param.changeTime
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  sensorKey: param[0],
                  measurement: param[1],
                  changeTime: param[2]
                }
              };
      }), Jzon.field("sensor", Document$LumiStore.Key.jsonCodec()), Jzon.field("measurement", Types$LumiDocuments.SensorMeasurement.jsonCodec), Jzon.field("changeTime", LumiDate.DateTime.jsonCodec));

var SensorState = {
  jsonCodec: jsonCodec
};

function occupied(sensorState) {
  return sensorState.measurement.free === 0;
}

function documentName(param) {
  
}

var icon = JsxRuntime.jsx(FontAwesome.Solid.make, {
      name: "chart-line"
    });

var Schema = Schema$LumiStore.Make({
      documentName: documentName,
      tag: "sensor-state",
      jsonCodec: jsonCodec,
      icon: icon
    });

export {
  SensorState ,
  occupied ,
  Schema ,
}
/* jsonCodec Not a pure module */
