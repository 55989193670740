// Generated by ReScript, PLEASE EDIT WITH CARE

import * as LumiReact from "lumi-rescript/lib/es6/src/LumiReact.bs.mjs";
import * as Locale from "date-fns/locale";

var options = [
  "DAN",
  "DEU",
  "ENG",
  "FRA",
  "ITA",
  "NLD",
  "SPA",
  "SWE"
];

function toString(locale) {
  switch (locale) {
    case "DAN" :
        return "da_DA";
    case "DEU" :
        return "de_DE";
    case "ENG" :
        return "en_GB";
    case "FRA" :
        return "fr_FR";
    case "ITA" :
        return "it_IT";
    case "NLD" :
        return "nl_NL";
    case "SPA" :
        return "es_ES";
    case "SWE" :
        return "sv_SV";
    
  }
}

function fromString(str) {
  switch (str) {
    case "da" :
    case "da-DA" :
    case "da_DA" :
        return "DAN";
    case "de" :
    case "de-DE" :
    case "de_DE" :
        return "DEU";
    case "en" :
    case "en-GB" :
    case "en_GB" :
        return "ENG";
    case "es" :
    case "es-ES" :
    case "es_ES" :
        return "SPA";
    case "fr" :
    case "fr-FR" :
    case "fr_FR" :
        return "FRA";
    case "it" :
    case "it-IT" :
    case "it_IT" :
        return "ITA";
    case "nl" :
    case "nl-NL" :
    case "nl_NL" :
        return "NLD";
    case "sv" :
    case "sv-SV" :
    case "sv_SV" :
        return "SWE";
    default:
      return ;
  }
}

function toIntlLocale(locale) {
  switch (locale) {
    case "DAN" :
        return "da-DA";
    case "DEU" :
        return "de-DE";
    case "ENG" :
        return "en-GB";
    case "FRA" :
        return "fr-FR";
    case "ITA" :
        return "it-it";
    case "NLD" :
        return "nl-NL";
    case "SPA" :
        return "es-es";
    case "SWE" :
        return "sv-SV";
    
  }
}

function toDateFnsLocale(locale) {
  switch (locale) {
    case "DAN" :
        return Locale.da;
    case "DEU" :
        return Locale.de;
    case "ENG" :
        return Locale.enGB;
    case "FRA" :
        return Locale.fr;
    case "ITA" :
        return Locale.it;
    case "NLD" :
        return Locale.nl;
    case "SPA" :
        return Locale.es;
    case "SWE" :
        return Locale.sv;
    
  }
}

function name(locale) {
  switch (locale) {
    case "DAN" :
        return "Dansk";
    case "DEU" :
        return "Deutsch";
    case "ENG" :
        return "English";
    case "FRA" :
        return "Français";
    case "ITA" :
        return "Italiano";
    case "NLD" :
        return "Nederlands";
    case "SPA" :
        return "Español";
    case "SWE" :
        return "Svenska";
    
  }
}

var include = LumiReact.Context.Make({
      $$default: "ENG"
    });

var context = include.context;

var useContext = include.useContext;

var Provider = include.Provider;

export {
  options ,
  toString ,
  fromString ,
  toIntlLocale ,
  toDateFnsLocale ,
  name ,
  context ,
  useContext ,
  Provider ,
}
/* include Not a pure module */
