// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Document$LumiStore from "lumi-store/lib/es6/src/Document.bs.mjs";
import * as LumiExt$LumiDocuments from "lumi-documents/lib/es6/src/LumiExt.bs.mjs";

function documentCodec(contentCodec) {
  return Document$LumiStore.$$Document.jsonCodec(LumiExt$LumiDocuments.jsonCodec, contentCodec);
}

export {
  documentCodec ,
}
/* Document-LumiStore Not a pure module */
