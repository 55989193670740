// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as V2f$Linear from "lumi-linear/lib/es6/src/V2f.bs.mjs";
import * as Transform$Linear from "lumi-linear/lib/es6/src/Transform.bs.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as AxesModel$LumiDocuments from "./AxesModel.bs.mjs";
import * as StaplePlaceCount$LumiDocuments from "./StaplePlaceCount.bs.mjs";

import '../../../src/css/place-model.sass'
;

var jsonCodec = Jzon.object3((function (param) {
        return [
                param.axes,
                param.flipSide,
                param.staplePlaceCount
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  axes: param[0],
                  flipSide: param[1],
                  staplePlaceCount: param[2]
                }
              };
      }), Jzon.field("axes", AxesModel$LumiDocuments.jsonCodec), Jzon.field("flipSide", Jzon.bool), Jzon.field("staplePlaceCount", StaplePlaceCount$LumiDocuments.jsonCodec));

function transform(placeModelSpaceSize, hmirror, vflip, sm) {
  return {
          axes: AxesModel$LumiDocuments.transform(placeModelSpaceSize, hmirror, vflip, sm.axes),
          flipSide: sm.flipSide,
          staplePlaceCount: sm.staplePlaceCount
        };
}

function toAxesModel(sm) {
  var displacementDirection = sm.flipSide ? -1 : 1;
  var match = sm.staplePlaceCount;
  var relativeDisplacement;
  relativeDisplacement = match === "OnePlace" ? 1 / 2 : 1 / 4;
  var sideRel = V2f$Linear.minus(sm.axes.side, sm.axes.baseFront);
  var displacement = V2f$Linear.scale(sideRel, relativeDisplacement * displacementDirection);
  var m23 = Transform$Linear.M23f.translation(displacement);
  return AxesModel$LumiDocuments.warpAffine(AxesModel$LumiDocuments.elongateBase(sm.axes, 1.3), m23);
}

function toPoly(sm, placeClass) {
  return AxesModel$LumiDocuments.toPoly(toAxesModel(sm), placeClass);
}

function extraPoints(sm) {
  return AxesModel$LumiDocuments.extraPoints(sm.axes).concat(AxesModel$LumiDocuments.extraPoints(toAxesModel(sm)));
}

function mainPoint(sm) {
  return AxesModel$LumiDocuments.mainPoint(toAxesModel(sm));
}

function StapleModel$Svg(props) {
  var __upClassList = props.upClassList;
  var __sideClassList = props.sideClassList;
  var __baseClassList = props.baseClassList;
  var stapleModel = props.stapleModel;
  var baseClassList = __baseClassList !== undefined ? __baseClassList : [];
  var sideClassList = __sideClassList !== undefined ? __sideClassList : [];
  var upClassList = __upClassList !== undefined ? __upClassList : [];
  var displacedAxes = toAxesModel(stapleModel);
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(AxesModel$LumiDocuments.Svg.make, {
                      axesModel: stapleModel.axes,
                      baseClassList: baseClassList,
                      sideClassList: sideClassList,
                      upClassList: upClassList
                    }),
                JsxRuntime.jsx(AxesModel$LumiDocuments.Svg.Base.make, {
                      baseFront: displacedAxes.baseFront,
                      baseBack: displacedAxes.baseBack,
                      classList: baseClassList.concat([[
                              "place-model-projection",
                              true
                            ]])
                    })
              ]
            });
}

var Svg = {
  make: StapleModel$Svg
};

export {
  jsonCodec ,
  transform ,
  toAxesModel ,
  toPoly ,
  extraPoints ,
  mainPoint ,
  Svg ,
}
/*  Not a pure module */
