// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Spinner from "lumi-rescript/lib/es6/src/Spinner.bs.mjs";
import * as LumiSort from "lumi-rescript/lib/es6/src/LumiSort.bs.mjs";
import * as LumiReact from "lumi-rescript/lib/es6/src/LumiReact.bs.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as LumiRequest from "lumi-rescript/lib/es6/src/LumiRequest.bs.mjs";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.bs.mjs";
import * as LumiReactReducer from "lumi-rescript/lib/es6/src/LumiReactReducer.bs.mjs";
import * as NavBar$Dashboard from "./NavBar.bs.mjs";
import * as Router$Dashboard from "./Router.bs.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Document$LumiStore from "lumi-store/lib/es6/src/Document.bs.mjs";
import * as PrintView$Dashboard from "./PrintView.bs.mjs";
import * as RescriptReactRouter from "@rescript/react/lib/es6/src/RescriptReactRouter.bs.mjs";
import * as Statistics$Dashboard from "./Statistics.bs.mjs";
import * as Translation$Dashboard from "./Translation.bs.mjs";
import * as Stallingsnet$LumiCentral from "lumi-central/lib/es6/src/Stallingsnet.bs.mjs";
import * as Barrier$LumiAuthentication from "lumi-authentication/lib/es6/src/Barrier.bs.mjs";
import * as AbandonedVehicles$Dashboard from "./AbandonedVehicles.bs.mjs";
import * as CurrentOccupation$Dashboard from "./CurrentOccupation.bs.mjs";
import * as ChangePassword$LumiAuthentication from "lumi-authentication/lib/es6/src/ChangePassword.bs.mjs";

function Application(props) {
  var accountDoc = Barrier$LumiAuthentication.Account.useContext();
  var toAuth = Barrier$LumiAuthentication.Dispatch.useContext();
  var route = Router$Dashboard.useRoute();
  var match = LumiReactReducer.useReducer((function () {
          var url = RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined);
          var urlSearchParams = new URLSearchParams(url.search);
          return {
                  TAG: "InitWithEffect",
                  _0: {
                    navBarActive: false,
                    kiosk: Core__Option.isSome(Caml_option.nullable_to_opt(urlSearchParams.get("kiosk"))),
                    changePassword: false,
                    facilityInfos: undefined,
                    print: undefined
                  },
                  _1: (function (param) {
                      var dispatch = param.dispatch;
                      return LumiRequest.resultFutureEffect(Stallingsnet$LumiCentral.getFacilities(), (function (facilities) {
                                    dispatch({
                                          TAG: "FacilityInfosReceived",
                                          _0: facilities
                                        });
                                  }), (function (err) {
                                    console.error("getFacilities failed", err);
                                    Barrier$LumiAuthentication.checkServerError(err, toAuth);
                                  }));
                    })
                };
        }), undefined, (function (state, action) {
          if (typeof action !== "object") {
            if (action === "ToggleNavBar") {
              return {
                      TAG: "Update",
                      _0: {
                        navBarActive: !state.navBarActive,
                        kiosk: state.kiosk,
                        changePassword: state.changePassword,
                        facilityInfos: state.facilityInfos,
                        print: state.print
                      }
                    };
            } else {
              return {
                      TAG: "Update",
                      _0: {
                        navBarActive: state.navBarActive,
                        kiosk: state.kiosk,
                        changePassword: state.changePassword,
                        facilityInfos: state.facilityInfos,
                        print: undefined
                      }
                    };
            }
          }
          switch (action.TAG) {
            case "FacilityInfosReceived" :
                return {
                        TAG: "Update",
                        _0: {
                          navBarActive: state.navBarActive,
                          kiosk: state.kiosk,
                          changePassword: state.changePassword,
                          facilityInfos: LumiSort.stableSortOn(action._0, (function (param) {
                                  return Document$LumiStore.contents(param.facility).name;
                                })),
                          print: state.print
                        }
                      };
            case "ChangePassword" :
                return {
                        TAG: "Update",
                        _0: {
                          navBarActive: state.navBarActive,
                          kiosk: state.kiosk,
                          changePassword: action._0,
                          facilityInfos: state.facilityInfos,
                          print: state.print
                        }
                      };
            case "Print" :
                return {
                        TAG: "Update",
                        _0: {
                          navBarActive: state.navBarActive,
                          kiosk: state.kiosk,
                          changePassword: state.changePassword,
                          facilityInfos: state.facilityInfos,
                          print: Caml_option.some(action._0)
                        }
                      };
            
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var print = state.print;
  var kiosk = state.kiosk;
  var isPrinting = Core__Option.isSome(print);
  var match$1 = state.facilityInfos;
  var tmp;
  tmp = match$1 !== undefined ? (
      match$1.length !== 0 ? (
          typeof route !== "object" ? JsxRuntime.jsx(Statistics$Dashboard.make, {
                  facilityInfos: state.facilityInfos,
                  kiosk: kiosk
                }) : (
              route.TAG === "CurrentOccupation" ? JsxRuntime.jsx(CurrentOccupation$Dashboard.make, {
                      route: route._0,
                      facilityInfos: state.facilityInfos,
                      toApplication: dispatch
                    }) : JsxRuntime.jsx(AbandonedVehicles$Dashboard.make, {
                      route: route._0,
                      facilityInfos: state.facilityInfos,
                      toApplication: dispatch
                    })
            )
        ) : JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(Translation$Dashboard.Provider.$$Text.make, {
                    entry: {
                      TAG: "NoAvailableFacilitiesForAccount",
                      _0: Document$LumiStore.contents(accountDoc).name
                    }
                  }),
              className: "notification is-danger"
            })
    ) : JsxRuntime.jsx(Spinner.make, {});
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                print !== undefined ? JsxRuntime.jsx(PrintView$Dashboard.make, {
                        element: Caml_option.valFromOption(print),
                        dispatch: dispatch
                      }) : JsxRuntime.jsx(NavBar$Dashboard.make, {
                        navBarActive: state.navBarActive,
                        kiosk: kiosk,
                        route: route,
                        dispatch: dispatch
                      }),
                state.changePassword ? JsxRuntime.jsx(ChangePassword$LumiAuthentication.make, {
                        onClose: (function () {
                            dispatch({
                                  TAG: "ChangePassword",
                                  _0: false
                                });
                          })
                      }) : null,
                JsxRuntime.jsx("div", {
                      children: tmp,
                      className: LumiReact.classList([
                            [
                              "columns",
                              true
                            ],
                            [
                              "fill-available",
                              true
                            ],
                            [
                              "is-hidden",
                              isPrinting
                            ]
                          ])
                    })
              ]
            });
}

var T;

var make = Application;

export {
  T ,
  make ,
}
/* Spinner Not a pure module */
