// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as FontAwesome from "lumi-rescript/lib/es6/src/FontAwesome.bs.mjs";
import * as Schema$LumiStore from "lumi-store/lib/es6/src/Schema.bs.mjs";
import * as JsxRuntime from "react/jsx-runtime";

var jsonCodec = Jzon.object2((function (param) {
        return [
                param.email,
                param.name
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  email: param[0],
                  name: param[1]
                }
              };
      }), Jzon.field("email", Jzon.nullable(Jzon.string)), Jzon.field("name", Jzon.string));

function documentName(param) {
  return param.name;
}

var icon = JsxRuntime.jsx(FontAwesome.Solid.make, {
      name: "user"
    });

var Schema = Schema$LumiStore.Make({
      documentName: documentName,
      tag: "account",
      jsonCodec: jsonCodec,
      icon: icon
    });

export {
  jsonCodec ,
  Schema ,
}
/* jsonCodec Not a pure module */
