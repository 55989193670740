// Generated by ReScript, PLEASE EDIT WITH CARE

import * as FontAwesome from "./FontAwesome.bs.mjs";
import * as JsxRuntime from "react/jsx-runtime";

function Spinner(props) {
  return JsxRuntime.jsx("span", {
              children: JsxRuntime.jsx(FontAwesome.Solid.make, {
                    name: "spinner",
                    spin: true
                  }),
              className: "icon"
            });
}

var make = Spinner;

export {
  make ,
}
/* FontAwesome Not a pure module */
