// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as Document$LumiStore from "lumi-store/lib/es6/src/Document.bs.mjs";

var jsonCodec = Jzon.object2((function (param) {
        return [
                param.owner,
                param.author
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  owner: param[0],
                  author: param[1]
                }
              };
      }), Jzon.field("_extOwner", Document$LumiStore.Key.jsonCodec()), Jzon.field("_extAuthor", Document$LumiStore.Key.jsonCodec()));

export {
  jsonCodec ,
}
/* jsonCodec Not a pure module */
