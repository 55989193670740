// Generated by ReScript, PLEASE EDIT WITH CARE

import * as D3 from "lumi-rescript/lib/es6/src/D3.bs.mjs";
import * as D3$1 from "d3";
import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as LumiSVG from "lumi-rescript/lib/es6/src/LumiSVG.bs.mjs";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as LumiJzon from "lumi-rescript/lib/es6/src/LumiJzon.bs.mjs";
import * as LumiSort from "lumi-rescript/lib/es6/src/LumiSort.bs.mjs";
import * as LumiEvent from "lumi-rescript/lib/es6/src/LumiEvent.bs.mjs";
import * as LumiReact from "lumi-rescript/lib/es6/src/LumiReact.bs.mjs";
import * as V2$Linear from "lumi-linear/lib/es6/src/V2.bs.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as LumiString from "lumi-rescript/lib/es6/src/LumiString.bs.mjs";
import * as V2f$Linear from "lumi-linear/lib/es6/src/V2f.bs.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/lib/es6/src/Core__Array.bs.mjs";
import * as FontAwesome from "lumi-rescript/lib/es6/src/FontAwesome.bs.mjs";
import * as M23f$Linear from "lumi-linear/lib/es6/src/M23f.bs.mjs";
import * as M33f$Linear from "lumi-linear/lib/es6/src/M33f.bs.mjs";
import * as Rect$Linear from "lumi-linear/lib/es6/src/Rect.bs.mjs";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.bs.mjs";
import * as Core__Result from "@rescript/core/lib/es6/src/Core__Result.bs.mjs";
import * as Rectf$Linear from "lumi-linear/lib/es6/src/Rectf.bs.mjs";
import * as Recti$Linear from "lumi-linear/lib/es6/src/Recti.bs.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Degrees$Linear from "lumi-linear/lib/es6/src/Degrees.bs.mjs";
import * as LumiDrawCommand from "lumi-draw-command/lib/es6/src/LumiDrawCommand.bs.mjs";
import * as Caml_splice_call from "rescript/lib/es6/caml_splice_call.js";
import * as CamlinternalLazy from "rescript/lib/es6/camlinternalLazy.js";
import * as Schema$LumiStore from "lumi-store/lib/es6/src/Schema.bs.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as BoundingBox$Linear from "lumi-linear/lib/es6/src/BoundingBox.bs.mjs";
import * as Document$LumiStore from "lumi-store/lib/es6/src/Document.bs.mjs";
import * as Camera$LumiDocuments from "./Camera.bs.mjs";
import * as Sensor$LumiDocuments from "./Sensor.bs.mjs";
import * as Display$LumiDocuments from "./Display.bs.mjs";
import * as Indicator$LumiDocuments from "./Indicator.bs.mjs";

function shapeVertices(shape) {
  switch (shape.TAG) {
    case "Rect" :
        var match = shape._0;
        var height = match[1];
        var width = match[0];
        return [
                [
                  0,
                  0
                ],
                [
                  width,
                  0
                ],
                [
                  width,
                  height
                ],
                [
                  0,
                  height
                ]
              ];
    case "Polygon" :
        return shape._0;
    case "Transformation" :
        var transform = (function (__x) {
              return function (extra) {
                return M33f$Linear.Mult.v3f(__x, extra);
              };
            })(M23f$Linear.toM33f(shape._0));
        return shapeVertices(shape._1).map(function (__x) {
                    return V2f$Linear.asV3f(__x, transform);
                  });
    
  }
}

function aabb(shape) {
  return BoundingBox$Linear.axisAlignedBoundingBox(shapeVertices(shape));
}

function decompose(_transforms, _shape) {
  while(true) {
    var shape = _shape;
    var transforms = _transforms;
    switch (shape.TAG) {
      case "Rect" :
      case "Polygon" :
          return [
                  transforms,
                  shape
                ];
      case "Transformation" :
          _shape = shape._1;
          _transforms = transforms.concat([shape._0]);
          continue ;
      
    }
  };
}

function toElement(shape, children) {
  switch (shape.TAG) {
    case "Rect" :
        var match = V2$Linear.map(shape._0, (function (prim) {
                return prim.toString();
              }));
        return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                    children: [
                      JsxRuntime.jsx("rect", {
                            height: match[1],
                            width: match[0],
                            vectorEffect: "non-scaling-stroke"
                          }),
                      children
                    ]
                  });
    case "Polygon" :
        var points = shape._0.map(function (point) {
                var match = V2$Linear.map(point, (function (prim) {
                        return prim.toString();
                      }));
                return match[0] + "," + match[1];
              }).join(" ");
        return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                    children: [
                      JsxRuntime.jsx("polygon", {
                            points: points,
                            vectorEffect: "non-scaling-stroke"
                          }),
                      children
                    ]
                  });
    case "Transformation" :
        var transform = LumiSVG.Transform.Matrix.fromM23f(shape._0);
        return JsxRuntime.jsx("g", {
                    children: toElement(shape._1, children),
                    transform: transform
                  });
    
  }
}

function FacilityMap$Shape(props) {
  var __children = props.children;
  var __classList = props.classList;
  var classList = __classList !== undefined ? __classList : [];
  var children = __children !== undefined ? Caml_option.valFromOption(__children) : null;
  var className = LumiReact.classList(classList);
  return JsxRuntime.jsx("g", {
              children: toElement(props.shape, children),
              className: className,
              id: props.id,
              onClick: props.onClick
            });
}

var lazyTransformation = {
  LAZY_DONE: false,
  VAL: (function () {
      return LumiJzon.tuple2(M23f$Linear.jsonCodec, CamlinternalLazy.force(lazyShape));
    })
};

var lazyShape = {
  LAZY_DONE: false,
  VAL: (function () {
      return LumiJzon.taggedVariant((function (shape) {
                    switch (shape.TAG) {
                      case "Rect" :
                          return [
                                  "Rect",
                                  Jzon.encodeWith(shape._0, V2f$Linear.jsonCodec)
                                ];
                      case "Polygon" :
                          return [
                                  "Polygon",
                                  Jzon.encodeWith(shape._0, Jzon.array(V2f$Linear.jsonCodec))
                                ];
                      case "Transformation" :
                          return [
                                  "Transformation",
                                  Jzon.encodeWith([
                                        shape._0,
                                        shape._1
                                      ], CamlinternalLazy.force(lazyTransformation))
                                ];
                      
                    }
                  }), (function (param) {
                    var json = param[1];
                    var tag = param[0];
                    switch (tag) {
                      case "Polygon" :
                          return Core__Result.map(Jzon.decodeWith(json, Jzon.array(V2f$Linear.jsonCodec)), (function (ps) {
                                        return {
                                                TAG: "Polygon",
                                                _0: ps
                                              };
                                      }));
                      case "Rect" :
                          return Core__Result.map(Jzon.decodeWith(json, V2f$Linear.jsonCodec), (function (r) {
                                        return {
                                                TAG: "Rect",
                                                _0: r
                                              };
                                      }));
                      case "Transformation" :
                          return Core__Result.map(Jzon.decodeWith(json, CamlinternalLazy.force(lazyTransformation)), (function (param) {
                                        return {
                                                TAG: "Transformation",
                                                _0: param[0],
                                                _1: param[1]
                                              };
                                      }));
                      default:
                        return {
                                TAG: "Error",
                                _0: {
                                  NAME: "UnexpectedJsonValue",
                                  VAL: [
                                    [{
                                        TAG: "Field",
                                        _0: "tag"
                                      }],
                                    tag
                                  ]
                                }
                              };
                    }
                  }));
    })
};

var transformation = CamlinternalLazy.force(lazyTransformation);

var jsonCodec = CamlinternalLazy.force(lazyShape);

var Shape = {
  shapeVertices: shapeVertices,
  aabb: aabb,
  decompose: decompose,
  toElement: toElement,
  make: FacilityMap$Shape,
  lazyTransformation: lazyTransformation,
  lazyShape: lazyShape,
  transformation: transformation,
  jsonCodec: jsonCodec
};

var jsonCodec$1 = Jzon.object2((function (param) {
        return [
                param.sensor,
                param.shape
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  sensor: param[0],
                  shape: param[1]
                }
              };
      }), Jzon.field("sensor", Jzon.nullable(Document$LumiStore.Key.jsonCodec())), Jzon.field("shape", jsonCodec));

var Spot = {
  jsonCodec: jsonCodec$1
};

function jsonCodec$2() {
  return Jzon.object3((function (param) {
                return [
                        param.key,
                        param.position,
                        param.orientation
                      ];
              }), (function (param) {
                return {
                        TAG: "Ok",
                        _0: {
                          key: param[0],
                          position: param[1],
                          orientation: param[2]
                        }
                      };
              }), Jzon.field("key", Jzon.nullable(Document$LumiStore.Key.jsonCodec())), Jzon.field("position", V2f$Linear.jsonCodec), Jzon.field("orientation", Jzon.nullable(V2f$Linear.jsonCodec)));
}

var Device = {
  jsonCodec: jsonCodec$2
};

var jsonCodec$3 = Jzon.object2((function (param) {
        return [
                param.name,
                param.index
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  name: param[0],
                  index: param[1]
                }
              };
      }), Jzon.field("name", Jzon.string), Jzon.field("index", Jzon.$$int));

function sortKey(info) {
  return [
          info.index,
          info.name
        ];
}

var FacilityMapInfo = {
  jsonCodec: jsonCodec$3,
  sortKey: sortKey
};

var jsonCodec$4 = Jzon.object6((function (param) {
        return [
                param.background,
                param.spots,
                param.cameras,
                param.indicators,
                param.displays,
                param.name
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  background: param[0],
                  spots: param[1],
                  cameras: param[2],
                  indicators: param[3],
                  displays: param[4],
                  name: param[5]
                }
              };
      }), Jzon.field("background", jsonCodec), Jzon.field("spots", LumiJzon.mapString(jsonCodec$1)), Jzon.field("cameras", LumiJzon.mapString(jsonCodec$2())), Jzon.field("indicators", LumiJzon.mapString(jsonCodec$2())), Jzon.field("displays", LumiJzon.mapString(jsonCodec$2())), Jzon.field("name", Jzon.string));

var FacilityMap = {
  jsonCodec: jsonCodec$4
};

function documentName(param) {
  return param.name;
}

var icon = JsxRuntime.jsx(FontAwesome.Solid.make, {
      name: "map-location"
    });

var Schema = Schema$LumiStore.Make({
      documentName: documentName,
      tag: "facility-map",
      jsonCodec: jsonCodec$4,
      icon: icon
    });

import '../../../src/css/facility-map-svg.sass'
;

var include = LumiReact.Context.Make({
      $$default: 15
    });

var useContext = include.useContext;

var Provider = include.Provider;

function fromShape(shape) {
  var points = shapeVertices(shape);
  var shifted = Core__Option.getOr(Core__Option.map(points[0], (function (first) {
              return points.slice(1).concat([first]);
            })), []);
  return Caml_splice_call.spliceApply(Math.min, [Belt_Array.zipBy(points, shifted, V2f$Linear.minus).map(V2f$Linear.length)]);
}

var Scale_context = include.context;

var Scale = {
  $$default: 15,
  context: Scale_context,
  useContext: useContext,
  Provider: Provider,
  fromShape: fromShape
};

function MakeKeyClasses(Cmp) {
  var empty = Belt_Map.make(Cmp);
  var fromArray = function (__x) {
    return Belt_Map.fromArray(__x, Cmp);
  };
  var merge = function (a, b) {
    return Belt_Map.merge(a, b, (function (_key, a, b) {
                  if (a !== undefined) {
                    if (b !== undefined) {
                      return a.concat(b);
                    } else {
                      return a;
                    }
                  } else if (b !== undefined) {
                    return b;
                  } else {
                    return ;
                  }
                }));
  };
  var mergeLast = function (a, b) {
    return Belt_Map.merge(a, b, (function (_key, a, b) {
                  if (a !== undefined) {
                    if (b !== undefined) {
                      return b;
                    } else {
                      return a;
                    }
                  } else if (b !== undefined) {
                    return b;
                  } else {
                    return ;
                  }
                }));
  };
  return {
          empty: empty,
          fromArray: fromArray,
          merge: merge,
          mergeLast: mergeLast,
          Cmp: Cmp
        };
}

function MakeKeyLabels(Cmp) {
  var empty = Belt_Map.make(Cmp);
  var fromArray = function (__x) {
    return Belt_Map.fromArray(__x, Cmp);
  };
  var mergeLast = function (a, b) {
    return Belt_Map.merge(a, b, (function (_key, a, b) {
                  if (a !== undefined) {
                    if (b !== undefined) {
                      return b;
                    } else {
                      return a;
                    }
                  } else if (b !== undefined) {
                    return b;
                  } else {
                    return ;
                  }
                }));
  };
  return {
          empty: empty,
          fromArray: fromArray,
          mergeLast: mergeLast,
          Cmp: Cmp
        };
}

function MakeKeyStyles(Cmp) {
  var empty = Belt_Map.make(Cmp);
  var fromArray = function (__x) {
    return Belt_Map.fromArray(__x, Cmp);
  };
  return {
          empty: empty,
          fromArray: fromArray,
          Cmp: Cmp
        };
}

var fromArray = Belt_MapString.fromArray;

function merge(a, b) {
  return Belt_MapString.merge(a, b, (function (_key, a, b) {
                if (a !== undefined) {
                  if (b !== undefined) {
                    return a.concat(b);
                  } else {
                    return a;
                  }
                } else if (b !== undefined) {
                  return b;
                } else {
                  return ;
                }
              }));
}

var SvgIdClasses = {
  empty: undefined,
  fromArray: fromArray,
  merge: merge
};

function FacilityMap$SVG$Container(props) {
  var __onSvgElement = props.onSvgElement;
  var __zoomFilter = props.zoomFilter;
  var onZoom = props.onZoom;
  var onDeselectAll = props.onDeselectAll;
  var __children = props.children;
  var match = props.facilityMap;
  var background = match.background;
  var children = __children !== undefined ? Caml_option.valFromOption(__children) : null;
  var zoomFilter = __zoomFilter !== undefined ? __zoomFilter : (function ($$event) {
        if (LumiEvent.isMouseEvent($$event) === false || $$event.type === "wheel") {
          return true;
        } else {
          return $$event.button === 2;
        }
      });
  var onSvgElement = __onSvgElement !== undefined ? __onSvgElement : (function (param) {
        
      });
  var zoomBehavior = React.useRef(D3$1.zoom().scaleExtent([
                1,
                15
              ]).filter(zoomFilter).on("zoom", (function (param) {
              var svg = this ;
              var transform = param.transform;
              D3$1.select(svg).select("g.zoomable").attr("transform", transform.toString());
              Core__Option.forEach(onZoom, (function (onZoom) {
                      onZoom(D3.Zoom.Transform.toM33f(transform));
                    }));
            })));
  var aabb$1 = Rectf$Linear.scaleCenter(aabb(background), 1.02);
  var scale = Core__Option.getOr(Core__Option.map(Belt_MapString.valuesToArray(match.spots)[0], (function (param) {
              return fromShape(param.shape);
            })), 15);
  var onSVGElement = LumiReact.useEffectWithCallbackRef((function (__x) {
          React.useEffect(Curry.__1(__x), [background]);
        }), (function (mbSvgElement) {
          onSvgElement(mbSvgElement);
          Core__Option.forEach(mbSvgElement, (function (svg) {
                  zoomBehavior.current.translateExtent(Rectf$Linear.bounds(aabb$1));
                  D3$1.select(svg).call(zoomBehavior.current).on("contextmenu", (function ($$event, param) {
                          $$event.preventDefault();
                        }));
                }));
        }));
  var onClick = onDeselectAll !== undefined ? (function (param) {
        onDeselectAll();
      }) : undefined;
  return JsxRuntime.jsx(Provider.make, {
              value: scale,
              children: JsxRuntime.jsx("svg", {
                    children: JsxRuntime.jsxs("g", {
                          children: [
                            JsxRuntime.jsx(FacilityMap$Shape, {
                                  shape: background,
                                  classList: [[
                                      "facility-map-background",
                                      true
                                    ]],
                                  onClick: onClick
                                }),
                            children
                          ],
                          className: "zoomable"
                        }),
                    ref: Caml_option.some(onSVGElement),
                    className: "facility-map-svg",
                    onMouseDown: props.onMouseDown,
                    onMouseMove: props.onMouseMove,
                    onMouseUp: props.onMouseUp,
                    preserveAspectRatio: "xMidYMid meet",
                    viewBox: LumiSVG.ViewBox.Float.fromRect(aabb$1)
                  })
            });
}

var Container = {
  make: FacilityMap$SVG$Container
};

var Cmp = Sensor$LumiDocuments.Schema.CmpKey;

var empty = Belt_Map.make(Cmp);

function fromArray$1(__x) {
  return Belt_Map.fromArray(__x, Cmp);
}

function mergeLast(a, b) {
  return Belt_Map.merge(a, b, (function (_key, a, b) {
                if (a !== undefined) {
                  if (b !== undefined) {
                    return b;
                  } else {
                    return a;
                  }
                } else if (b !== undefined) {
                  return b;
                } else {
                  return ;
                }
              }));
}

var SensorLabels = {
  empty: empty,
  fromArray: fromArray$1,
  mergeLast: mergeLast,
  Cmp: Cmp
};

var Cmp$1 = Sensor$LumiDocuments.Schema.CmpKey;

var empty$1 = Belt_Map.make(Cmp$1);

function fromArray$2(__x) {
  return Belt_Map.fromArray(__x, Cmp$1);
}

function merge$1(a, b) {
  return Belt_Map.merge(a, b, (function (_key, a, b) {
                if (a !== undefined) {
                  if (b !== undefined) {
                    return a.concat(b);
                  } else {
                    return a;
                  }
                } else if (b !== undefined) {
                  return b;
                } else {
                  return ;
                }
              }));
}

function mergeLast$1(a, b) {
  return Belt_Map.merge(a, b, (function (_key, a, b) {
                if (a !== undefined) {
                  if (b !== undefined) {
                    return b;
                  } else {
                    return a;
                  }
                } else if (b !== undefined) {
                  return b;
                } else {
                  return ;
                }
              }));
}

var SensorClasses = {
  empty: empty$1,
  fromArray: fromArray$2,
  merge: merge$1,
  mergeLast: mergeLast$1,
  Cmp: Cmp$1
};

var Cmp$2 = Sensor$LumiDocuments.Schema.CmpKey;

var empty$2 = Belt_Map.make(Cmp$2);

function fromArray$3(__x) {
  return Belt_Map.fromArray(__x, Cmp$2);
}

var SensorStyles = {
  empty: empty$2,
  fromArray: fromArray$3,
  Cmp: Cmp$2
};

function FacilityMap$SVG$Spots$Spot(props) {
  var style = props.style;
  var scale = useContext();
  var match = decompose([], props.shape);
  var baseShape = match[1];
  var transforms = match[0];
  var className = LumiReact.classList([[
            "facility-map-spot",
            true
          ]].concat(props.classList));
  var transform = LumiString.nonEmpty(LumiSVG.Transform.list(transforms.map(LumiSVG.Transform.Matrix.fromM23f)));
  var tmp;
  switch (baseShape.TAG) {
    case "Rect" :
        var match$1 = V2$Linear.map(baseShape._0, (function (prim) {
                return prim.toString();
              }));
        tmp = JsxRuntime.jsx("rect", {
              style: style,
              height: match$1[1],
              width: match$1[0],
              transform: transform,
              vectorEffect: "non-scaling-stroke"
            });
        break;
    case "Polygon" :
        var points = baseShape._0.map(function (point) {
                var match = V2$Linear.map(point, (function (prim) {
                        return prim.toString();
                      }));
                return match[0] + "," + match[1];
              }).join(" ");
        tmp = JsxRuntime.jsx("polygon", {
              style: style,
              points: points,
              transform: transform,
              vectorEffect: "non-scaling-stroke"
            });
        break;
    case "Transformation" :
        throw {
              RE_EXN_ID: "Assert_failure",
              _1: [
                "FacilityMap.res",
                408,
                38
              ],
              Error: new Error()
            };
    
  }
  return JsxRuntime.jsxs("g", {
              children: [
                tmp,
                LumiReact.renderOption(undefined, props.label, (function (label) {
                        var tmp;
                        switch (baseShape.TAG) {
                          case "Rect" :
                              var match = baseShape._0;
                              var height = match[1];
                              var width = match[0];
                              tmp = [
                                [
                                  0,
                                  0
                                ],
                                [
                                  width,
                                  0
                                ],
                                [
                                  width,
                                  height
                                ],
                                [
                                  0,
                                  height
                                ]
                              ];
                              break;
                          case "Polygon" :
                              tmp = baseShape._0;
                              break;
                          case "Transformation" :
                              throw {
                                    RE_EXN_ID: "Assert_failure",
                                    _1: [
                                      "FacilityMap.res",
                                      416,
                                      40
                                    ],
                                    Error: new Error()
                                  };
                          
                        }
                        var shapeCenter = Rectf$Linear.center(BoundingBox$Linear.axisAlignedBoundingBox(tmp));
                        var center = Core__Array.reduce(transforms, shapeCenter, (function (vec, mat) {
                                return M23f$Linear.Mult.v2f(mat, vec);
                              }));
                        var match$1 = V2$Linear.map(center, (function (prim) {
                                return prim.toString();
                              }));
                        var fontSize = (scale * 0.3).toString() + "px";
                        return JsxRuntime.jsx("text", {
                                    children: label,
                                    className: "facility-map-spot-label is-unselectable",
                                    dominantBaseline: "central",
                                    fontSize: fontSize,
                                    textAnchor: "middle",
                                    x: match$1[0],
                                    y: match$1[1]
                                  });
                      }))
              ],
              className: className,
              id: props.svgId,
              onClick: props.onClick
            });
}

var Spot$1 = {
  make: FacilityMap$SVG$Spots$Spot
};

function FacilityMap$SVG$Spots(props) {
  var onClickSpot = props.onClickSpot;
  var __sensorStyles = props.sensorStyles;
  var __sensorClasses = props.sensorClasses;
  var __sensorLabels = props.sensorLabels;
  var __svgIdClasses = props.svgIdClasses;
  var svgIdClasses = __svgIdClasses !== undefined ? Caml_option.valFromOption(__svgIdClasses) : undefined;
  var sensorLabels = __sensorLabels !== undefined ? Caml_option.valFromOption(__sensorLabels) : empty;
  var sensorClasses = __sensorClasses !== undefined ? Caml_option.valFromOption(__sensorClasses) : empty$1;
  var sensorStyles = __sensorStyles !== undefined ? Caml_option.valFromOption(__sensorStyles) : empty$2;
  return JsxRuntime.jsx("g", {
              children: Belt_MapString.toArray(props.facilityMap.spots).map(function (param) {
                    var match = param[1];
                    var mbSensorKey = match.sensor;
                    var svgId = param[0];
                    var label = Core__Option.flatMap(mbSensorKey, (function (x) {
                            return Belt_Map.get(sensorLabels, x);
                          }));
                    var classList = [
                        [[
                            "is-clickable",
                            Core__Option.isSome(onClickSpot)
                          ]],
                        Belt_MapString.getWithDefault(svgIdClasses, svgId, []),
                        Core__Option.getOr(Core__Option.flatMap(mbSensorKey, (function (x) {
                                    return Belt_Map.get(sensorClasses, x);
                                  })), [])
                      ].flat();
                    var style = Core__Option.flatMap(mbSensorKey, (function (x) {
                            return Belt_Map.get(sensorStyles, x);
                          }));
                    var onClick = onClickSpot !== undefined ? (function ($$event) {
                          onClickSpot($$event, svgId, mbSensorKey);
                        }) : undefined;
                    return JsxRuntime.jsx(FacilityMap$SVG$Spots$Spot, {
                                svgId: svgId,
                                shape: match.shape,
                                label: label,
                                classList: classList,
                                style: style,
                                onClick: onClick
                              }, svgId);
                  }),
              className: "facility-map-spots"
            });
}

var Spots = {
  SensorLabels: SensorLabels,
  SensorClasses: SensorClasses,
  SensorStyles: SensorStyles,
  Spot: Spot$1,
  make: FacilityMap$SVG$Spots
};

function FacilityMap$SVG$Cone(props) {
  var __closed = props.closed;
  var angle = props.angle;
  var edgeLength = props.edgeLength;
  var orientation = props.orientation;
  var position = props.position;
  var closed = __closed !== undefined ? __closed : true;
  var match = V2$Linear.map(V2f$Linear.plus(V2f$Linear.rotate(V2f$Linear.scaleTo(orientation, edgeLength), -0.5 * angle), position), (function (prim) {
          return prim.toString();
        }));
  var c1y = match[1];
  var c1x = match[0];
  var match$1 = V2$Linear.map(V2f$Linear.plus(V2f$Linear.scaleTo(orientation, edgeLength * 1.4), position), (function (prim) {
          return prim.toString();
        }));
  var match$2 = V2$Linear.map(V2f$Linear.plus(V2f$Linear.rotate(V2f$Linear.scaleTo(orientation, edgeLength), 0.5 * angle), position), (function (prim) {
          return prim.toString();
        }));
  var c2y = match$2[1];
  var c2x = match$2[0];
  var match$3 = V2$Linear.map(position, (function (prim) {
          return prim.toString();
        }));
  var py = match$3[1];
  var px = match$3[0];
  var d = closed ? "M " + c1x + "," + c1y + " Q " + match$1[0] + "," + match$1[1] + " " + c2x + "," + c2y + " L " + px + "," + py + " Z" : "M " + c1x + "," + c1y + " L " + px + "," + py + " L " + c2x + "," + c2y;
  var className = LumiReact.classList(props.classList);
  return JsxRuntime.jsx("path", {
              className: className,
              style: props.style,
              d: d,
              vectorEffect: "non-scaling-stroke"
            });
}

var Cone = {
  make: FacilityMap$SVG$Cone
};

var Cmp$3 = Camera$LumiDocuments.Schema.CmpKey;

var empty$3 = Belt_Map.make(Cmp$3);

function fromArray$4(__x) {
  return Belt_Map.fromArray(__x, Cmp$3);
}

function mergeLast$2(a, b) {
  return Belt_Map.merge(a, b, (function (_key, a, b) {
                if (a !== undefined) {
                  if (b !== undefined) {
                    return b;
                  } else {
                    return a;
                  }
                } else if (b !== undefined) {
                  return b;
                } else {
                  return ;
                }
              }));
}

var CameraLabels = {
  empty: empty$3,
  fromArray: fromArray$4,
  mergeLast: mergeLast$2,
  Cmp: Cmp$3
};

var Cmp$4 = Camera$LumiDocuments.Schema.CmpKey;

var empty$4 = Belt_Map.make(Cmp$4);

function fromArray$5(__x) {
  return Belt_Map.fromArray(__x, Cmp$4);
}

function merge$2(a, b) {
  return Belt_Map.merge(a, b, (function (_key, a, b) {
                if (a !== undefined) {
                  if (b !== undefined) {
                    return a.concat(b);
                  } else {
                    return a;
                  }
                } else if (b !== undefined) {
                  return b;
                } else {
                  return ;
                }
              }));
}

function mergeLast$3(a, b) {
  return Belt_Map.merge(a, b, (function (_key, a, b) {
                if (a !== undefined) {
                  if (b !== undefined) {
                    return b;
                  } else {
                    return a;
                  }
                } else if (b !== undefined) {
                  return b;
                } else {
                  return ;
                }
              }));
}

var CameraClasses = {
  empty: empty$4,
  fromArray: fromArray$5,
  merge: merge$2,
  mergeLast: mergeLast$3,
  Cmp: Cmp$4
};

function FacilityMap$SVG$Cameras$DeviceCamera(props) {
  var classList = props.classList;
  var orientation = props.orientation;
  var position = props.position;
  var scale = useContext();
  var className = LumiReact.classList([[
            "facility-map-device-camera",
            true
          ]].concat(classList));
  var tmp;
  if (orientation !== undefined) {
    var angle = Degrees$Linear.toRadians(80);
    var classList$1 = [[
          "facility-map-device-camera-cone",
          true
        ]].concat(classList);
    var edgeLength = scale * 3;
    var classList$2 = [[
        "facility-map-device-camera-dot",
        true
      ]];
    var edgeLength$1 = scale * 0.9;
    tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsx(FacilityMap$SVG$Cone, {
                  position: position,
                  orientation: orientation,
                  edgeLength: edgeLength,
                  angle: angle,
                  classList: classList$1
                }),
            JsxRuntime.jsx(FacilityMap$SVG$Cone, {
                  position: position,
                  orientation: orientation,
                  edgeLength: edgeLength$1,
                  angle: angle,
                  classList: classList$2
                })
          ]
        });
  } else {
    var match = V2$Linear.map(position, (function (prim) {
            return prim.toString();
          }));
    var className$1 = LumiReact.classList([[
              "facility-map-device-camera-dot",
              true
            ]].concat(classList));
    tmp = JsxRuntime.jsx("circle", {
          className: className$1,
          cx: match[0],
          cy: match[1],
          r: "1",
          vectorEffect: "non-scaling-stroke"
        });
  }
  return JsxRuntime.jsxs("g", {
              children: [
                tmp,
                LumiReact.renderOption(undefined, props.label, (function (label) {
                        var match = V2$Linear.map(V2f$Linear.plus(Core__Option.mapOr(orientation, [
                                      0,
                                      0
                                    ], (function (__x) {
                                        return V2f$Linear.scaleTo(__x, scale * 0.55);
                                      })), position), (function (prim) {
                                return prim.toString();
                              }));
                        var fontSize = (scale * 0.25).toString() + "px";
                        return JsxRuntime.jsx("text", {
                                    children: label,
                                    className: "facility-map-device-camera-label is-unselectable",
                                    dominantBaseline: "central",
                                    fontSize: fontSize,
                                    textAnchor: "middle",
                                    x: match[0],
                                    y: match[1]
                                  });
                      }))
              ],
              className: className,
              id: props.svgId,
              onClick: props.onClick
            });
}

var DeviceCamera = {
  make: FacilityMap$SVG$Cameras$DeviceCamera
};

function FacilityMap$SVG$Cameras(props) {
  var onClickCamera = props.onClickCamera;
  var __cameraClasses = props.cameraClasses;
  var __cameraLabels = props.cameraLabels;
  var __svgIdClasses = props.svgIdClasses;
  var svgIdClasses = __svgIdClasses !== undefined ? Caml_option.valFromOption(__svgIdClasses) : undefined;
  var cameraLabels = __cameraLabels !== undefined ? Caml_option.valFromOption(__cameraLabels) : empty$3;
  var cameraClasses = __cameraClasses !== undefined ? Caml_option.valFromOption(__cameraClasses) : empty$4;
  return JsxRuntime.jsx("g", {
              children: LumiSort.stableSortOn(Belt_MapString.toArray(props.facilityMap.cameras).map(function (param) {
                            var camera = param[1];
                            return [
                                    param[0],
                                    camera,
                                    Core__Option.flatMap(camera.key, (function (x) {
                                            return Belt_Map.get(cameraLabels, x);
                                          }))
                                  ];
                          }), (function (param) {
                          return Core__Option.map(param[2], (function (extra) {
                                        return LumiSort.padNumbersInString(5, extra);
                                      }));
                        })).toReversed().map(function (param) {
                    var match = param[1];
                    var mbCameraKey = match.key;
                    var svgId = param[0];
                    var classList = [
                        [[
                            "is-clickable",
                            Core__Option.isSome(onClickCamera)
                          ]],
                        Belt_MapString.getWithDefault(svgIdClasses, svgId, []),
                        Core__Option.getOr(Core__Option.flatMap(mbCameraKey, (function (x) {
                                    return Belt_Map.get(cameraClasses, x);
                                  })), [])
                      ].flat();
                    var onClick = onClickCamera !== undefined ? (function ($$event) {
                          onClickCamera($$event, svgId, mbCameraKey);
                        }) : undefined;
                    return JsxRuntime.jsx(FacilityMap$SVG$Cameras$DeviceCamera, {
                                svgId: svgId,
                                position: match.position,
                                orientation: match.orientation,
                                label: param[2],
                                classList: classList,
                                onClick: onClick
                              }, svgId);
                  }),
              className: "facility-map-cameras"
            });
}

var Cameras = {
  CameraLabels: CameraLabels,
  CameraClasses: CameraClasses,
  DeviceCamera: DeviceCamera,
  make: FacilityMap$SVG$Cameras
};

var Cmp$5 = Indicator$LumiDocuments.Schema.CmpKey;

var empty$5 = Belt_Map.make(Cmp$5);

function fromArray$6(__x) {
  return Belt_Map.fromArray(__x, Cmp$5);
}

function mergeLast$4(a, b) {
  return Belt_Map.merge(a, b, (function (_key, a, b) {
                if (a !== undefined) {
                  if (b !== undefined) {
                    return b;
                  } else {
                    return a;
                  }
                } else if (b !== undefined) {
                  return b;
                } else {
                  return ;
                }
              }));
}

var IndicatorLabels = {
  empty: empty$5,
  fromArray: fromArray$6,
  mergeLast: mergeLast$4,
  Cmp: Cmp$5
};

var Cmp$6 = Indicator$LumiDocuments.Schema.CmpKey;

var empty$6 = Belt_Map.make(Cmp$6);

function fromArray$7(__x) {
  return Belt_Map.fromArray(__x, Cmp$6);
}

function merge$3(a, b) {
  return Belt_Map.merge(a, b, (function (_key, a, b) {
                if (a !== undefined) {
                  if (b !== undefined) {
                    return a.concat(b);
                  } else {
                    return a;
                  }
                } else if (b !== undefined) {
                  return b;
                } else {
                  return ;
                }
              }));
}

function mergeLast$5(a, b) {
  return Belt_Map.merge(a, b, (function (_key, a, b) {
                if (a !== undefined) {
                  if (b !== undefined) {
                    return b;
                  } else {
                    return a;
                  }
                } else if (b !== undefined) {
                  return b;
                } else {
                  return ;
                }
              }));
}

var IndicatorClasses = {
  empty: empty$6,
  fromArray: fromArray$7,
  merge: merge$3,
  mergeLast: mergeLast$5,
  Cmp: Cmp$6
};

var Cmp$7 = Indicator$LumiDocuments.Schema.CmpKey;

var empty$7 = Belt_Map.make(Cmp$7);

function fromArray$8(__x) {
  return Belt_Map.fromArray(__x, Cmp$7);
}

var IndicatorStyles = {
  empty: empty$7,
  fromArray: fromArray$8,
  Cmp: Cmp$7
};

function FacilityMap$SVG$Indicators$DeviceIndicator(props) {
  var style = props.style;
  var classList = props.classList;
  var orientation = props.orientation;
  var position = props.position;
  var scale = useContext();
  var className = LumiReact.classList([[
            "facility-map-indicator",
            true
          ]].concat(classList));
  var tmp;
  if (orientation !== undefined) {
    var angle = Degrees$Linear.toRadians(80);
    var classList$1 = [[
        "facility-map-indicator-body",
        true
      ]];
    var edgeLength = scale * 0.9;
    tmp = JsxRuntime.jsx(FacilityMap$SVG$Cone, {
          position: position,
          orientation: orientation,
          edgeLength: edgeLength,
          angle: angle,
          classList: classList$1,
          style: style
        });
  } else {
    var match = V2$Linear.map(position, (function (prim) {
            return prim.toString();
          }));
    var className$1 = LumiReact.classList([[
              "facility-map-indicator",
              true
            ]].concat(classList));
    tmp = JsxRuntime.jsx("circle", {
          className: className$1,
          style: style,
          cx: match[0],
          cy: match[1],
          r: "1",
          vectorEffect: "non-scaling-stroke"
        });
  }
  return JsxRuntime.jsxs("g", {
              children: [
                tmp,
                LumiReact.renderOption(undefined, props.label, (function (label) {
                        var match = V2$Linear.map(V2f$Linear.plus(Core__Option.mapOr(orientation, [
                                      0,
                                      0
                                    ], (function (__x) {
                                        return V2f$Linear.scaleTo(__x, scale * 0.55);
                                      })), position), (function (prim) {
                                return prim.toString();
                              }));
                        var fontSize = (scale * 0.25).toString() + "px";
                        return JsxRuntime.jsx("text", {
                                    children: label,
                                    className: "facility-map-indicator-label is-unselectable",
                                    dominantBaseline: "central",
                                    fontSize: fontSize,
                                    textAnchor: "middle",
                                    x: match[0],
                                    y: match[1]
                                  });
                      }))
              ],
              className: className,
              id: props.svgId,
              onClick: props.onClick
            });
}

var DeviceIndicator = {
  make: FacilityMap$SVG$Indicators$DeviceIndicator
};

function FacilityMap$SVG$Indicators(props) {
  var onClickIndicator = props.onClickIndicator;
  var __indicatorStyles = props.indicatorStyles;
  var __indicatorClasses = props.indicatorClasses;
  var __indicatorLabels = props.indicatorLabels;
  var __svgIdClasses = props.svgIdClasses;
  var match = props.facilityMap;
  var svgIdClasses = __svgIdClasses !== undefined ? Caml_option.valFromOption(__svgIdClasses) : undefined;
  var indicatorLabels = __indicatorLabels !== undefined ? Caml_option.valFromOption(__indicatorLabels) : empty$5;
  var indicatorClasses = __indicatorClasses !== undefined ? Caml_option.valFromOption(__indicatorClasses) : empty$6;
  var indicatorStyles = __indicatorStyles !== undefined ? Caml_option.valFromOption(__indicatorStyles) : empty$7;
  var lumicamIndicators = Belt_Map.fromArray(props.lumicamsAsIndicators ? Core__Array.filterMap(Belt_Map.toArray(props.indicators), (function (param) {
                var match = param[1].type_;
                if (match.TAG === "IndicatorTypeLumicam") {
                  return [
                          match._1,
                          param[0]
                        ];
                }
                
              })) : [], Camera$LumiDocuments.Schema.CmpKey);
  return JsxRuntime.jsxs("g", {
              children: [
                Belt_MapString.toArray(match.indicators).map(function (param) {
                      var match = param[1];
                      var mbIndicatorKey = match.key;
                      var svgId = param[0];
                      var label = Core__Option.flatMap(mbIndicatorKey, (function (x) {
                              return Belt_Map.get(indicatorLabels, x);
                            }));
                      var classList = [
                          [[
                              "is-clickable",
                              Core__Option.isSome(onClickIndicator)
                            ]],
                          Belt_MapString.getWithDefault(svgIdClasses, svgId, []),
                          Core__Option.getOr(Core__Option.flatMap(mbIndicatorKey, (function (x) {
                                      return Belt_Map.get(indicatorClasses, x);
                                    })), [])
                        ].flat();
                      var style = Core__Option.flatMap(mbIndicatorKey, (function (x) {
                              return Belt_Map.get(indicatorStyles, x);
                            }));
                      var onClick = onClickIndicator !== undefined ? (function ($$event) {
                            onClickIndicator($$event, svgId, mbIndicatorKey);
                          }) : undefined;
                      return JsxRuntime.jsx(FacilityMap$SVG$Indicators$DeviceIndicator, {
                                  svgId: svgId,
                                  position: match.position,
                                  orientation: match.orientation,
                                  label: label,
                                  classList: classList,
                                  style: style,
                                  onClick: onClick
                                }, svgId);
                    }),
                Core__Array.filterMap(Belt_MapString.toArray(match.cameras), (function (param) {
                        var match = param[1];
                        var orientation = match.orientation;
                        var position = match.position;
                        var svgId = param[0];
                        return Core__Option.map(Core__Option.flatMap(match.key, (function (x) {
                                          return Belt_Map.get(lumicamIndicators, x);
                                        })), (function (indicatorKey) {
                                      var label = Belt_Map.get(indicatorLabels, indicatorKey);
                                      var classList = [
                                          [[
                                              "is-clickable",
                                              Core__Option.isSome(onClickIndicator)
                                            ]],
                                          Core__Option.getOr(Belt_Map.get(indicatorClasses, indicatorKey), [])
                                        ].flat();
                                      var style = Belt_Map.get(indicatorStyles, indicatorKey);
                                      var onClick = onClickIndicator !== undefined ? (function ($$event) {
                                            onClickIndicator($$event, svgId, indicatorKey);
                                          }) : undefined;
                                      return JsxRuntime.jsx(FacilityMap$SVG$Indicators$DeviceIndicator, {
                                                  svgId: svgId,
                                                  position: position,
                                                  orientation: orientation,
                                                  label: label,
                                                  classList: classList,
                                                  style: style,
                                                  onClick: onClick
                                                }, svgId);
                                    }));
                      }))
              ],
              className: "facility-map-indicators"
            });
}

var Indicators = {
  IndicatorLabels: IndicatorLabels,
  IndicatorClasses: IndicatorClasses,
  IndicatorStyles: IndicatorStyles,
  DeviceIndicator: DeviceIndicator,
  make: FacilityMap$SVG$Indicators
};

var Cmp$8 = Display$LumiDocuments.Schema.CmpKey;

var empty$8 = Belt_Map.make(Cmp$8);

function fromArray$9(__x) {
  return Belt_Map.fromArray(__x, Cmp$8);
}

function mergeLast$6(a, b) {
  return Belt_Map.merge(a, b, (function (_key, a, b) {
                if (a !== undefined) {
                  if (b !== undefined) {
                    return b;
                  } else {
                    return a;
                  }
                } else if (b !== undefined) {
                  return b;
                } else {
                  return ;
                }
              }));
}

var DisplayLabels = {
  empty: empty$8,
  fromArray: fromArray$9,
  mergeLast: mergeLast$6,
  Cmp: Cmp$8
};

var Cmp$9 = Display$LumiDocuments.Schema.CmpKey;

var empty$9 = Belt_Map.make(Cmp$9);

function fromArray$10(__x) {
  return Belt_Map.fromArray(__x, Cmp$9);
}

function merge$4(a, b) {
  return Belt_Map.merge(a, b, (function (_key, a, b) {
                if (a !== undefined) {
                  if (b !== undefined) {
                    return a.concat(b);
                  } else {
                    return a;
                  }
                } else if (b !== undefined) {
                  return b;
                } else {
                  return ;
                }
              }));
}

function mergeLast$7(a, b) {
  return Belt_Map.merge(a, b, (function (_key, a, b) {
                if (a !== undefined) {
                  if (b !== undefined) {
                    return b;
                  } else {
                    return a;
                  }
                } else if (b !== undefined) {
                  return b;
                } else {
                  return ;
                }
              }));
}

var DisplayClasses = {
  empty: empty$9,
  fromArray: fromArray$10,
  merge: merge$4,
  mergeLast: mergeLast$7,
  Cmp: Cmp$9
};

var empty$10 = Belt_Map.make(Display$LumiDocuments.Schema.CmpKey);

function make(displaysWithDisplayStates) {
  return Belt_Map.fromArray(Core__Array.filterMap(displaysWithDisplayStates, (function (param) {
                    var display = param[1];
                    var displayKey = param[0];
                    return Core__Option.map(param[2].lastUpdate, (function (param) {
                                  var aabb = Recti$Linear.boundingBoxFromPoints(display.regions.flatMap(function (param) {
                                            var match = Recti$Linear.bounds(param.rect);
                                            return [
                                                    match[0],
                                                    match[1]
                                                  ];
                                          }));
                                  return [
                                          displayKey,
                                          [
                                            aabb,
                                            param.this
                                          ]
                                        ];
                                }));
                  })), Display$LumiDocuments.Schema.CmpKey);
}

var DisplayContents = {
  empty: empty$10,
  make: make
};

function FacilityMap$SVG$Displays$DeviceDisplay(props) {
  var position = props.position;
  var label = Core__Option.orElse(props.label, "Unknown display");
  var scale = useContext();
  var className = LumiReact.classList([[
            "facility-map-display",
            true
          ]].concat(props.classList));
  var rectSize_0 = scale * 4;
  var rectSize_1 = scale * 3;
  var rectSize = [
    rectSize_0,
    rectSize_1
  ];
  var rectPos = V2f$Linear.minus(position, V2f$Linear.scale(rectSize, 0.5));
  var match = V2$Linear.map(rectPos, (function (prim) {
          return prim.toString();
        }));
  var match$1 = V2$Linear.map(rectSize, (function (prim) {
          return prim.toString();
        }));
  return JsxRuntime.jsxs("g", {
              children: [
                LumiReact.renderOption(undefined, props.orientation, (function (orientation) {
                        var edgeLength = scale * 3;
                        var angle = Math.PI * 0.75;
                        return JsxRuntime.jsx(FacilityMap$SVG$Cone, {
                                    position: position,
                                    orientation: orientation,
                                    edgeLength: edgeLength,
                                    angle: angle,
                                    classList: [[
                                        "facility-map-display-orientation",
                                        true
                                      ]],
                                    closed: false
                                  });
                      })),
                JsxRuntime.jsx("rect", {
                      className: "facility-map-display-rect",
                      height: match$1[1],
                      width: match$1[0],
                      vectorEffect: "non-scaling-stroke",
                      x: match[0],
                      y: match[1]
                    }),
                LumiReact.renderOption(undefined, label, (function (label) {
                        var match = V2$Linear.map(V2f$Linear.plus(rectPos, [
                                  rectSize_0 * 0.5,
                                  scale * -0.2
                                ]), (function (prim) {
                                return prim.toString();
                              }));
                        var fontSize = (scale * 0.55).toString() + "px";
                        return JsxRuntime.jsx("text", {
                                    children: label,
                                    className: "facility-map-display-label is-unselectable",
                                    dominantBaseline: "text-bottom",
                                    fontSize: fontSize,
                                    textAnchor: "middle",
                                    x: match[0],
                                    y: match[1]
                                  });
                      })),
                LumiReact.renderOption(undefined, props.contents, (function (param) {
                        var aabb = param[0];
                        var match = Rect$Linear.map(aabb, (function (prim) {
                                return prim;
                              }));
                        var match$1 = match.size;
                        var scale = Math.min(rectSize_0 / match$1[0], rectSize_1 / match$1[1]) * 0.9;
                        var aabbCenter = V2f$Linear.scale(Rectf$Linear.center(Rect$Linear.map(aabb, (function (prim) {
                                        return prim;
                                      }))), scale);
                        var transform = LumiSVG.Transform.list([
                              LumiSVG.Transform.Float.translate(V2f$Linear.minus(position, aabbCenter)),
                              LumiSVG.Transform.Float.scale([
                                    scale,
                                    scale
                                  ])
                            ]);
                        var match$2 = Rect$Linear.map(aabb, (function (prim) {
                                return prim.toString();
                              }));
                        var match$3 = match$2.size;
                        var match$4 = match$2.pos;
                        return JsxRuntime.jsxs("g", {
                                    children: [
                                      JsxRuntime.jsx("rect", {
                                            height: match$3[1],
                                            width: match$3[0],
                                            fill: "black",
                                            stroke: "none",
                                            x: match$4[0],
                                            y: match$4[1]
                                          }),
                                      param[1].map(function (command, idx) {
                                            return JsxRuntime.jsx(LumiDrawCommand.LumiDrawCommand.SVG.make, {
                                                        command: command
                                                      }, idx.toString());
                                          })
                                    ],
                                    className: "facility-map-display-contents",
                                    transform: transform
                                  });
                      }))
              ],
              className: className,
              id: props.svgId,
              onClick: props.onClick
            });
}

var DeviceDisplay = {
  make: FacilityMap$SVG$Displays$DeviceDisplay
};

function FacilityMap$SVG$Displays(props) {
  var onClickDisplay = props.onClickDisplay;
  var __displayContents = props.displayContents;
  var __displayClasses = props.displayClasses;
  var __displayLabels = props.displayLabels;
  var __svgIdClasses = props.svgIdClasses;
  var svgIdClasses = __svgIdClasses !== undefined ? Caml_option.valFromOption(__svgIdClasses) : undefined;
  var displayLabels = __displayLabels !== undefined ? Caml_option.valFromOption(__displayLabels) : empty$8;
  var displayClasses = __displayClasses !== undefined ? Caml_option.valFromOption(__displayClasses) : empty$9;
  var displayContents = __displayContents !== undefined ? Caml_option.valFromOption(__displayContents) : empty$10;
  return JsxRuntime.jsx("g", {
              children: LumiSort.stableSortOn(Belt_MapString.toArray(props.facilityMap.displays).map(function (param) {
                          var display = param[1];
                          return [
                                  param[0],
                                  display,
                                  Core__Option.flatMap(display.key, (function (x) {
                                          return Belt_Map.get(displayLabels, x);
                                        }))
                                ];
                        }), (function (param) {
                        return Core__Option.map(param[2], (function (extra) {
                                      return LumiSort.padNumbersInString(5, extra);
                                    }));
                      })).map(function (param) {
                    var match = param[1];
                    var mbDisplayKey = match.key;
                    var svgId = param[0];
                    var classList = [
                        [[
                            "is-clickable",
                            Core__Option.isSome(onClickDisplay)
                          ]],
                        Belt_MapString.getWithDefault(svgIdClasses, svgId, []),
                        Core__Option.getOr(Core__Option.flatMap(mbDisplayKey, (function (x) {
                                    return Belt_Map.get(displayClasses, x);
                                  })), [])
                      ].flat();
                    var onClick = onClickDisplay !== undefined ? (function ($$event) {
                          onClickDisplay($$event, svgId, mbDisplayKey);
                        }) : undefined;
                    var contents = Core__Option.flatMap(mbDisplayKey, (function (x) {
                            return Belt_Map.get(displayContents, x);
                          }));
                    return JsxRuntime.jsx(FacilityMap$SVG$Displays$DeviceDisplay, {
                                svgId: svgId,
                                position: match.position,
                                orientation: match.orientation,
                                label: param[2],
                                classList: classList,
                                onClick: onClick,
                                contents: contents
                              }, svgId);
                  }),
              className: "facility-map-displays"
            });
}

var Displays = {
  DisplayLabels: DisplayLabels,
  DisplayClasses: DisplayClasses,
  DisplayContents: DisplayContents,
  DeviceDisplay: DeviceDisplay,
  make: FacilityMap$SVG$Displays
};

var SVG = {
  Scale: Scale,
  MakeKeyClasses: MakeKeyClasses,
  MakeKeyLabels: MakeKeyLabels,
  MakeKeyStyles: MakeKeyStyles,
  SvgIdClasses: SvgIdClasses,
  Container: Container,
  Spots: Spots,
  Cone: Cone,
  Cameras: Cameras,
  Indicators: Indicators,
  Displays: Displays
};

export {
  Shape ,
  Spot ,
  Device ,
  FacilityMapInfo ,
  FacilityMap ,
  Schema ,
  SVG ,
}
/* transformation Not a pure module */
