// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Spinner from "lumi-rescript/lib/es6/src/Spinner.bs.mjs";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/lib/es6/src/Core__Array.bs.mjs";
import * as FontAwesome from "lumi-rescript/lib/es6/src/FontAwesome.bs.mjs";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.bs.mjs";
import * as Util$Dashboard from "./Util.bs.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Types$LumiDocuments from "lumi-documents/lib/es6/src/Types.bs.mjs";
import * as FacilityMap$Dashboard from "./FacilityMap.bs.mjs";
import * as Translation$Dashboard from "./Translation.bs.mjs";
import * as FacilityMap$LumiDocuments from "lumi-documents/lib/es6/src/FacilityMap.bs.mjs";
import * as SensorState$LumiDocuments from "lumi-documents/lib/es6/src/SensorState.bs.mjs";

function calcSensorClasses(placeClassClasses, sensorStates) {
  return FacilityMap$LumiDocuments.SVG.Spots.SensorClasses.merge(placeClassClasses, FacilityMap$LumiDocuments.SVG.Spots.SensorClasses.fromArray(sensorStates.map(function (sensorState) {
                      var occupied = SensorState$LumiDocuments.occupied(sensorState);
                      return [
                              sensorState.sensorKey,
                              [
                                [
                                  "facility-map-spot-free",
                                  !occupied
                                ],
                                [
                                  "facility-map-spot-occupied",
                                  occupied
                                ]
                              ]
                            ];
                    })));
}

function calcStats(pred, sensorStates) {
  var match = Core__Array.reduce(sensorStates.filter(pred).map(function (sensorState) {
            return sensorState.measurement;
          }), Types$LumiDocuments.SensorMeasurement.empty, Types$LumiDocuments.SensorMeasurement.add);
  var error = match.error;
  var occupied = match.occupied;
  var free = match.free;
  return {
          capacity: (free + occupied | 0) + error | 0,
          free: free,
          occupied: occupied + error | 0
        };
}

function isUpperPlace(sensorInfos, param) {
  return Core__Option.getOr(Core__Option.map(Belt_Map.get(sensorInfos, param.sensorKey), (function (param) {
                    return Util$Dashboard.isUpperPlace(param.placeClass);
                  })), false);
}

function CurrentOccupation$Footer$StatsRow(props) {
  var stats = props.stats;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("span", {
                              children: props.icon,
                              className: "panel-icon"
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(Translation$Dashboard.Provider.$$Text.make, {
                                      entry: props.label
                                    }),
                                JsxRuntime.jsx("span", {
                                      children: stats.capacity,
                                      className: "tag is-light"
                                    })
                              ],
                              className: "field is-grouped is-flex-grow-1 is-justify-content-space-between"
                            })
                      ],
                      className: "panel-block has-text-weight-bold"
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("span", {
                            children: JsxRuntime.jsx("progress", {
                                  className: "progress is-inline-block is-info",
                                  max: stats.capacity.toString(),
                                  value: stats.occupied.toString()
                                }),
                            className: "control"
                          }),
                      className: "panel-block"
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsxs("div", {
                                          children: [
                                            JsxRuntime.jsx("span", {
                                                  children: stats.occupied,
                                                  className: "tag is-occupied"
                                                }),
                                            JsxRuntime.jsx("span", {
                                                  children: JsxRuntime.jsx(Translation$Dashboard.Provider.$$Text.make, {
                                                        entry: "Occupied"
                                                      }),
                                                  className: "tag"
                                                })
                                          ],
                                          className: "tags has-addons"
                                        }),
                                    className: "control"
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsxs("div", {
                                          children: [
                                            JsxRuntime.jsx("span", {
                                                  children: JsxRuntime.jsx(Translation$Dashboard.Provider.$$Text.make, {
                                                        entry: "Free"
                                                      }),
                                                  className: "tag"
                                                }),
                                            JsxRuntime.jsx("span", {
                                                  children: stats.free,
                                                  className: "tag is-free"
                                                })
                                          ],
                                          className: "tags has-addons"
                                        }),
                                    className: "control"
                                  })
                            ],
                            className: "field is-grouped is-flex-grow-1 is-justify-content-space-between"
                          }),
                      className: "panel-block"
                    })
              ]
            });
}

var StatsRow = {
  make: CurrentOccupation$Footer$StatsRow
};

function CurrentOccupation$Footer(props) {
  var match = props.stats;
  var lowerStats = match[1];
  var upperStats = match[0];
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                upperStats.capacity > 0 && lowerStats.capacity > 0 ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                        children: [
                          JsxRuntime.jsx(CurrentOccupation$Footer$StatsRow, {
                                label: "UpperPlaces",
                                icon: JsxRuntime.jsx(FontAwesome.Solid.make, {
                                      name: "ellipsis"
                                    }),
                                stats: upperStats
                              }),
                          JsxRuntime.jsx(CurrentOccupation$Footer$StatsRow, {
                                label: "LowerPlaces",
                                icon: JsxRuntime.jsx(FontAwesome.Solid.make, {
                                      name: "minus"
                                    }),
                                stats: lowerStats
                              })
                        ]
                      }) : null,
                JsxRuntime.jsx(CurrentOccupation$Footer$StatsRow, {
                      label: "AllPlaces",
                      icon: JsxRuntime.jsx(FontAwesome.Solid.make, {
                            name: "vector-square"
                          }),
                      stats: match[2]
                    })
              ]
            });
}

var Footer = {
  StatsRow: StatsRow,
  make: CurrentOccupation$Footer
};

function CurrentOccupation(props) {
  var facilityInfos = props.facilityInfos;
  var buildRoute = function (x) {
    return {
            TAG: "CurrentOccupation",
            _0: x
          };
  };
  var facilityMaps = FacilityMap$Dashboard.useFacilityMaps(props.route, facilityInfos, buildRoute);
  var dispatch = facilityMaps.dispatch;
  var match = facilityMaps.state;
  var sensorStates = match.sensorStates;
  var parentSensorClasses = match.sensorClasses;
  var sensorInfos = match.sensorInfos;
  var facilityMap = match.facilityMap;
  var sensorClasses = React.useMemo((function () {
          return calcSensorClasses(parentSensorClasses, sensorStates);
        }), [
        parentSensorClasses,
        sensorStates
      ]);
  var stats = React.useMemo((function () {
          var upper = calcStats((function (extra) {
                  return isUpperPlace(sensorInfos, extra);
                }), sensorStates);
          var lower = calcStats((function (extra) {
                  return !(function (extra) {
                              return isUpperPlace(sensorInfos, extra);
                            })(extra);
                }), sensorStates);
          var total = calcStats((function (param) {
                  return true;
                }), sensorStates);
          return [
                  upper,
                  lower,
                  total
                ];
        }), [
        parentSensorClasses,
        sensorStates
      ]);
  var onSelectSensor = React.useCallback((function (mbSensorKey) {
          dispatch({
                TAG: "SelectSensor",
                _0: mbSensorKey
              });
        }), []);
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(FacilityMap$Dashboard.Sidebar.make, {
                      facilityMaps: facilityMaps,
                      facilityInfos: facilityInfos,
                      toApplication: props.toApplication,
                      buildRoute: buildRoute,
                      children: Caml_option.some(facilityMap !== undefined ? JsxRuntime.jsx(CurrentOccupation$Footer, {
                                  stats: stats
                                }) : null)
                    }),
                JsxRuntime.jsx("div", {
                      children: facilityMap !== undefined ? JsxRuntime.jsx(FacilityMap$LumiDocuments.SVG.Container.make, {
                              facilityMap: facilityMap,
                              children: Caml_option.some(JsxRuntime.jsx(FacilityMap$LumiDocuments.SVG.Spots.make, {
                                        facilityMap: facilityMap,
                                        sensorLabels: Caml_option.some(match.sensorLabels),
                                        sensorClasses: Caml_option.some(sensorClasses),
                                        onClickSpot: (function (_event, _svgId, mbSensorKey) {
                                            onSelectSensor(mbSensorKey);
                                          })
                                      })),
                              onDeselectAll: (function () {
                                  onSelectSensor(undefined);
                                }),
                              zoomFilter: FacilityMap$Dashboard.defaultZoomFilter
                            }) : JsxRuntime.jsx(Spinner.make, {}),
                      className: "column contents"
                    })
              ]
            });
}

var make = CurrentOccupation;

export {
  calcSensorClasses ,
  calcStats ,
  isUpperPlace ,
  Footer ,
  make ,
}
/* react Not a pure module */
