// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.bs.mjs";
import * as Client from "react-dom/client";
import * as JsxRuntime from "react/jsx-runtime";
import * as Stallingsnet$Dashboard from "./Stallingsnet.bs.mjs";

import '../../../src/css/main.sass'
;

import 'chartjs-adapter-date-fns';
import { Chart as ChartJS, registerables } from 'chart.js';
ChartJS.register(...registerables);
;

Core__Option.forEach(Caml_option.nullable_to_opt(document.querySelector("#root")), (function (element) {
        var root = Client.createRoot(element);
        root.render(JsxRuntime.jsx(Stallingsnet$Dashboard.make, {}));
      }));

export {
  
}
/*  Not a pure module */
