// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as FontAwesome from "lumi-rescript/lib/es6/src/FontAwesome.bs.mjs";
import * as Core__Result from "@rescript/core/lib/es6/src/Core__Result.bs.mjs";
import * as Schema$LumiStore from "lumi-store/lib/es6/src/Schema.bs.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Types$LumiDocuments from "./Types.bs.mjs";

var jsonCodec = Jzon.custom((function (v) {
        var tmp;
        switch (v) {
          case "Planned" :
              tmp = "Planned";
              break;
          case "Operational" :
              tmp = "Operational";
              break;
          case "Archived" :
              tmp = "Archived";
              break;
          
        }
        return Jzon.encodeWith(tmp, Jzon.string);
      }), (function (json) {
        return Core__Result.flatMap(Jzon.decodeWith(json, Jzon.string), (function (v) {
                      switch (v) {
                        case "Archived" :
                            return {
                                    TAG: "Ok",
                                    _0: "Archived"
                                  };
                        case "Operational" :
                            return {
                                    TAG: "Ok",
                                    _0: "Operational"
                                  };
                        case "Planned" :
                            return {
                                    TAG: "Ok",
                                    _0: "Planned"
                                  };
                        default:
                          return {
                                  TAG: "Error",
                                  _0: {
                                    NAME: "UnexpectedJsonValue",
                                    VAL: [
                                      [],
                                      v
                                    ]
                                  }
                                };
                      }
                    }));
      }));

function toString(value) {
  switch (value) {
    case "Planned" :
        return "Planned";
    case "Operational" :
        return "Operational";
    case "Archived" :
        return "Archived";
    
  }
}

function FacilityServer$ServerOperationalState(props) {
  var value = props.value;
  var tmp;
  switch (value) {
    case "Planned" :
        tmp = JsxRuntime.jsx("span", {
              children: JsxRuntime.jsx(FontAwesome.Solid.make, {
                    name: "person-digging"
                  }),
              className: "icon has-text-warning"
            });
        break;
    case "Operational" :
        tmp = JsxRuntime.jsx("span", {
              children: JsxRuntime.jsx(FontAwesome.Solid.make, {
                    name: "gears"
                  }),
              className: "icon has-text-success"
            });
        break;
    case "Archived" :
        tmp = JsxRuntime.jsx("span", {
              children: JsxRuntime.jsx(FontAwesome.Solid.make, {
                    name: "broom"
                  }),
              className: "icon has-text-grey-light"
            });
        break;
    
  }
  return JsxRuntime.jsxs("span", {
              children: [
                tmp,
                JsxRuntime.jsx("span", {
                      children: toString(value)
                    })
              ],
              className: "icon-text"
            });
}

var ServerOperationalState = {
  jsonCodec: jsonCodec,
  toString: toString,
  make: FacilityServer$ServerOperationalState
};

var jsonCodec$1 = Jzon.object6((function (param) {
        return [
                param.hostname,
                param.port,
                param.protocol,
                param.location,
                param.position,
                param.operationalState
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  hostname: param[0],
                  port: param[1],
                  protocol: param[2],
                  location: param[3],
                  position: param[4],
                  operationalState: param[5]
                }
              };
      }), Jzon.field("hostname", Jzon.string), Jzon.field("port", Jzon.$$int), Jzon.field("protocol", Types$LumiDocuments.Protocol.jsonCodec), Jzon.field("location", Jzon.string), Jzon.field("position", Types$LumiDocuments.GeoPosition.jsonCodec), Jzon.field("operationalState", jsonCodec));

var FacilityServer = {
  jsonCodec: jsonCodec$1
};

function documentName(param) {
  return param.hostname;
}

var icon = JsxRuntime.jsx(FontAwesome.Solid.make, {
      name: "server"
    });

var Schema = Schema$LumiStore.Make({
      documentName: documentName,
      tag: "facility-server",
      jsonCodec: jsonCodec$1,
      icon: icon
    });

export {
  ServerOperationalState ,
  FacilityServer ,
  Schema ,
}
/* jsonCodec Not a pure module */
