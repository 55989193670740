// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Future from "rescript-future/lib/es6/src/Future.bs.mjs";
import * as LumiCookie from "lumi-rescript/lib/es6/src/LumiCookie.bs.mjs";
import * as JsCookieJs from "lumi-rescript/src/js-cookie.js";

function getXSRFTokenCookie() {
  return JsCookieJs.get("XSRF-TOKEN");
}

function deleteXSRFTokenCookie() {
  JsCookieJs.remove("XSRF-TOKEN");
}

function setXSRFTokenCookie(token) {
  LumiCookie.set("XSRF-TOKEN", token, undefined, undefined, undefined, undefined, undefined, undefined);
}

function withXSRFToken(f) {
  var token = JsCookieJs.get("XSRF-TOKEN");
  if (token !== undefined) {
    return f(token);
  } else {
    return Future.value({
                TAG: "Error",
                _0: "NoXSRFTokenCookie"
              });
  }
}

export {
  getXSRFTokenCookie ,
  deleteXSRFTokenCookie ,
  setXSRFTokenCookie ,
  withXSRFToken ,
}
/* LumiCookie Not a pure module */
