// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as V2i$Linear from "./V2i.bs.mjs";
import * as Rect$Linear from "./Rect.bs.mjs";
import * as Caml_splice_call from "rescript/lib/es6/caml_splice_call.js";

var zero = {
  pos: V2i$Linear.zero,
  size: V2i$Linear.zero
};

function fromSize(size) {
  return {
          pos: [
            0,
            0
          ],
          size: size
        };
}

function boundingBoxFromPoints(points) {
  var xs = points.map(function (prim) {
        return prim[0];
      });
  var ys = points.map(function (prim) {
        return prim[1];
      });
  var min_0 = Caml_splice_call.spliceApply(Math.min, [xs]);
  var min_1 = Caml_splice_call.spliceApply(Math.min, [ys]);
  var min = [
    min_0,
    min_1
  ];
  var max_0 = Caml_splice_call.spliceApply(Math.max, [xs]);
  var max_1 = Caml_splice_call.spliceApply(Math.max, [ys]);
  var max = [
    max_0,
    max_1
  ];
  return {
          pos: min,
          size: V2i$Linear.minus(max, min)
        };
}

function bounds(param) {
  var pos = param.pos;
  return [
          pos,
          V2i$Linear.plus(pos, param.size)
        ];
}

function rotate90(param) {
  var match = param.size;
  var h = match[1];
  var match$1 = param.pos;
  return {
          pos: [
            Math.imul(-1, match$1[1] + h | 0),
            Math.imul(-1, match$1[0])
          ],
          size: [
            h,
            match[0]
          ]
        };
}

function rotate180(param) {
  var match = param.size;
  var h = match[1];
  var w = match[0];
  var match$1 = param.pos;
  return {
          pos: [
            Math.imul(-1, match$1[0] + w | 0),
            Math.imul(-1, match$1[1] + h | 0)
          ],
          size: [
            w,
            h
          ]
        };
}

function rotate270(param) {
  var match = param.size;
  var w = match[0];
  var match$1 = param.pos;
  return {
          pos: [
            match$1[1],
            match$1[0] - w | 0
          ],
          size: [
            match[1],
            w
          ]
        };
}

var jsonCodec = Rect$Linear.jsonCodec(Jzon.$$int);

export {
  zero ,
  fromSize ,
  boundingBoxFromPoints ,
  bounds ,
  rotate90 ,
  rotate180 ,
  rotate270 ,
  jsonCodec ,
}
/* jsonCodec Not a pure module */
