// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Common$LumiI18n from "lumi-i18n/lib/es6/src/Common.bs.mjs";
import * as Translation$LumiI18n from "lumi-i18n/lib/es6/src/Translation.bs.mjs";

function translate(locale, entry) {
  if (typeof entry === "object") {
    return Common$LumiI18n.Provider.translate(locale, entry._0);
  }
  switch (locale) {
    case "DEU" :
        if (typeof entry !== "object") {
          switch (entry) {
            case "PhraseBackToLogin" :
                return "Zurück zur Einloggen";
            case "PhraseChangePassword" :
                return "Passwort ändern";
            case "PhraseEmail" :
                return "E-Mail";
            case "PhraseEnterNewPassword" :
                return "Bitte geben Sie Ihr neues Passwort ein";
            case "PhraseEnterNewPasswordAgain" :
                return "Bitte geben Sie Ihr neues Passwort erneut ein";
            case "PhraseEnterOldPassword" :
                return "Bitte geben Sie Ihr altes Passwort ein";
            case "PhraseInvalidAPIKey" :
                return "Ungültiger API-Schlüssel";
            case "PhraseLoginFailed" :
                return "Einloggen fehlgeschlagen, sind ihre E-Mail und Passwort korrekt?";
            case "PhraseLoginFailedTryAgainLater" :
                return "Eine Anmeldung ist derzeit nicht möglich. Versuchen Sie es später erneut.";
            case "PhraseMailPasswordLinkSent" :
                return "Es wurde eine E-Mail mit einem Link zum Ändern des Passwort gesendet.";
            case "PhraseNotLoggedIn" :
                return "Sie sind nicht eingeloggt!";
            case "PhrasePassword" :
                return "Passwort";
            case "PhrasePasswordChanged" :
                return "Ihr Passwort wurde erfolgreich geändert.";
            case "PhrasePasswordForgotten" :
                return "Ich habe mein Passwort vergessen.";
            case "PhraseRetry" :
                return "Wiederholen";
            case "PhraseSendPasswordResetMail" :
                return "Senden Sie eine E-Mail, um Ihr Passwort zurückzusetzen.";
            case "PhraseToLogin" :
                return "Zur Einloggen";
            case "PhraseWaitingTimeExpired" :
                return "Der Link in der E-Mail zum Zurücksetzen des Passworts ist abgelaufen. Fordern Sie erneut eine Kennwortzurücksetzung an.";
            
          }
        }
        case "ENG" :
        if (typeof entry !== "object") {
          switch (entry) {
            case "PhraseBackToLogin" :
                return "Back to login";
            case "PhraseChangePassword" :
                return "Change password";
            case "PhraseEmail" :
                return "Email";
            case "PhraseEnterNewPassword" :
                return "Enter your new password";
            case "PhraseEnterNewPasswordAgain" :
                return "Re-enter your new password";
            case "PhraseEnterOldPassword" :
                return "Enter your old password";
            case "PhraseInvalidAPIKey" :
                return "Invalid API key";
            case "PhraseLoginFailed" :
                return "Login failed, are your email and password correct?";
            case "PhraseLoginFailedTryAgainLater" :
                return "Logging in is currently not possible. Please try again later.";
            case "PhraseMailPasswordLinkSent" :
                return "A mail has been sent with a password change link.";
            case "PhraseNotLoggedIn" :
                return "You're not logged in!";
            case "PhrasePassword" :
                return "Password";
            case "PhrasePasswordChanged" :
                return "Your password has been changed successfully.";
            case "PhrasePasswordForgotten" :
                return "I forgot my password";
            case "PhraseRetry" :
                return "Retry";
            case "PhraseSendPasswordResetMail" :
                return "Send password reset email";
            case "PhraseToLogin" :
                return "To login";
            case "PhraseWaitingTimeExpired" :
                return "The link in the password reset email has expired. Please request a password reset again.";
            
          }
        }
        case "FRA" :
        if (typeof entry !== "object") {
          switch (entry) {
            case "PhraseBackToLogin" :
                return "Retour à la connexion";
            case "PhraseChangePassword" :
                return "Changer le mot de passe";
            case "PhraseEmail" :
                return "E-mail";
            case "PhraseEnterNewPassword" :
                return "Veuillez entrer votre nouveau mot de passe";
            case "PhraseEnterNewPasswordAgain" :
                return "Veuillez ressaisir votre nouveau mot de passe";
            case "PhraseEnterOldPassword" :
                return "Veuillez votre ancien mot de passe";
            case "PhraseInvalidAPIKey" :
                return "Clé API non valide";
            case "PhraseLoginFailed" :
                return "La connexion a échoué, votre e-mail et votre mot de passe sont-ils corrects?";
            case "PhraseLoginFailedTryAgainLater" :
                return "La connexion n'est actuellement pas possible. Réessayez plus tard.";
            case "PhraseMailPasswordLinkSent" :
                return "Un e-mail a été envoyé avec un lien pour changer votre mot de passe.";
            case "PhraseNotLoggedIn" :
                return "Vous n'êtes pas connecté";
            case "PhrasePassword" :
                return "Mot de passe";
            case "PhrasePasswordChanged" :
                return "Votre mot de passe a été changé avec succès.";
            case "PhrasePasswordForgotten" :
                return "J'ai oublié mon mot de passe.";
            case "PhraseRetry" :
                return "Réessayez";
            case "PhraseSendPasswordResetMail" :
                return "Envoyez un e-mail pour réinitialiser votre mot de passe.";
            case "PhraseToLogin" :
                return "Retour à la connexion.";
            case "PhraseWaitingTimeExpired" :
                return "Le lien dans l'e-mail de réinitialisation du mot de passe a expiré. Demandez à nouveau une réinitialisation de mot de passe.";
            
          }
        }
        case "NLD" :
        if (typeof entry !== "object") {
          switch (entry) {
            case "PhraseBackToLogin" :
                return "Terug naar inloggen";
            case "PhraseChangePassword" :
                return "Verander wachtwoord";
            case "PhraseEmail" :
                return "E-mailadres";
            case "PhraseEnterNewPassword" :
                return "Voer uw nieuwe wachtwoord in";
            case "PhraseEnterNewPasswordAgain" :
                return "Voer uw nieuwe wachtwoord nogmaals in";
            case "PhraseEnterOldPassword" :
                return "Voer uw oude wachtwoord in";
            case "PhraseInvalidAPIKey" :
                return "Ongeldige API-key";
            case "PhraseLoginFailed" :
                return "Inloggen niet gelukt, zijn uw e-mail en wachtwoord juist?";
            case "PhraseLoginFailedTryAgainLater" :
                return "Inloggen is momenteel niet mogelijk. Probeer het later opnieuw.";
            case "PhraseMailPasswordLinkSent" :
                return "Er is een e-mail verstuurd met een link om uw wachtwoord te veranderen.";
            case "PhraseNotLoggedIn" :
                return "U bent niet ingelogd";
            case "PhrasePassword" :
                return "Wachtwoord";
            case "PhrasePasswordChanged" :
                return "Uw wachtwoord is succesvol veranderd.";
            case "PhrasePasswordForgotten" :
                return "Ik ben mijn wachtwoord vergeten";
            case "PhraseRetry" :
                return "Opnieuw proberen";
            case "PhraseSendPasswordResetMail" :
                return "Stuur wachtwoord reset e-mail";
            case "PhraseToLogin" :
                return "Naar inloggen";
            case "PhraseWaitingTimeExpired" :
                return "De link in de wachtwoordresete-mail is verlopen. Vraag opnieuw een wachtwoordreset aan.";
            
          }
        }
        default:
      return ;
  }
}

var T = Translation$LumiI18n.MakeProvider({
      defaultLocale: "ENG",
      translate: translate
    });

function entryFromApiError(err) {
  if (typeof err !== "object") {
    if (err === "NoXSRFTokenCookie") {
      return "PhraseNotLoggedIn";
    } else {
      return "PhraseLoginFailedTryAgainLater";
    }
  } else if (err.TAG === "ServerError" && err.status === 401) {
    return "PhraseNotLoggedIn";
  } else {
    return "PhraseLoginFailedTryAgainLater";
  }
}

var CT;

export {
  CT ,
  T ,
  entryFromApiError ,
}
/* T Not a pure module */
