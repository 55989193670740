// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DateFns from "lumi-rescript/lib/es6/src/DateFns.bs.mjs";
import * as LumiSVG from "lumi-rescript/lib/es6/src/LumiSVG.bs.mjs";
import * as LumiReact from "lumi-rescript/lib/es6/src/LumiReact.bs.mjs";
import * as V2$Linear from "lumi-linear/lib/es6/src/V2.bs.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Rect$Linear from "lumi-linear/lib/es6/src/Rect.bs.mjs";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.bs.mjs";
import * as Rectf$Linear from "lumi-linear/lib/es6/src/Rectf.bs.mjs";
import * as Locale$LumiI18n from "lumi-i18n/lib/es6/src/Locale.bs.mjs";
import Format from "date-fns/format";
import * as DateTime$LumiI18n from "lumi-i18n/lib/es6/src/DateTime.bs.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Document$LumiStore from "lumi-store/lib/es6/src/Document.bs.mjs";
import * as Translation$Dashboard from "./Translation.bs.mjs";
import * as RightAngle$LumiDocuments from "lumi-documents/lib/es6/src/Types/RightAngle.bs.mjs";
import FormatDistanceStrict from "date-fns/formatDistanceStrict";

function AbandonedVehicleReport$Field(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("label", {
                            children: JsxRuntime.jsx(Translation$Dashboard.Provider.$$Text.make, {
                                  entry: props.label
                                }),
                            className: "label"
                          }),
                      className: "field-label is-normal"
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx("div", {
                                  children: props.children,
                                  className: "control"
                                }),
                            className: "field is-expanded"
                          }),
                      className: "field-body"
                    })
              ],
              className: "field is-horizontal"
            });
}

var Field = {
  make: AbandonedVehicleReport$Field
};

function AbandonedVehicleReport$TextInput(props) {
  return JsxRuntime.jsx("input", {
              defaultValue: props.defaultValue,
              className: "input",
              type: "text"
            });
}

var TextInput = {
  make: AbandonedVehicleReport$TextInput
};

function AbandonedVehicleReport$TextAreaInput(props) {
  return JsxRuntime.jsx("textarea", {
              defaultValue: props.defaultValue,
              className: "textarea"
            });
}

var TextAreaInput = {
  make: AbandonedVehicleReport$TextAreaInput
};

function AbandonedVehicleReport$Image(props) {
  var match = props.image;
  var match$1 = Document$LumiStore.contents(match.snapshot);
  var revRot = RightAngle$LumiDocuments.RightAngle.reverse(match$1.imgRotation);
  var raabb = (function (__x) {
        return RightAngle$LumiDocuments.RightAngle.applyToRecti(revRot, __x);
      })(match.placeAABB);
  var viewBox = LumiSVG.ViewBox.Float.fromRect(Rectf$Linear.scaleCenter(Rect$Linear.map(raabb, (function (prim) {
                  return prim;
                })), 1.02));
  var transform = LumiSVG.Transform.Degree.rotate(RightAngle$LumiDocuments.RightAngle.toDegrees(revRot));
  var match$2 = V2$Linear.map(match$1.imgResolution, (function (prim) {
          return prim.toString();
        }));
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("header", {
                      children: JsxRuntime.jsx("p", {
                            children: JsxRuntime.jsx(DateTime$LumiI18n.Full.make, {
                                  date: match$1.timestamp
                                }),
                            className: "card-header-title"
                          }),
                      className: "card-header"
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("svg", {
                            children: JsxRuntime.jsx("g", {
                                  children: JsxRuntime.jsx("image", {
                                        height: match$2[1],
                                        href: match.imageUrl,
                                        width: match$2[0],
                                        x: "0",
                                        y: "0"
                                      }),
                                  transform: transform
                                }),
                            className: "image",
                            preserveAspectRatio: "xMidYMid meet",
                            viewBox: viewBox
                          }),
                      className: "card-image is-flex is-justify-content-center"
                    })
              ],
              className: "card"
            });
}

var $$Image = {
  make: AbandonedVehicleReport$Image
};

function AbandonedVehicleReport(props) {
  var occupationDetails = props.occupationDetails;
  var locale = Locale$LumiI18n.useContext();
  var now = LumiReact.CurrentTime.useCurrentTime();
  var match = Document$LumiStore.contents(occupationDetails.occupation);
  var effectiveEndTime = Core__Option.getOr(match.endTime, now);
  var occupationDuration = FormatDistanceStrict(match.beginTime, effectiveEndTime, {
        unit: "day",
        roundingMethod: "floor",
        locale: Caml_option.some(Locale$LumiI18n.toDateFnsLocale(locale))
      });
  var today = Format(now, DateFns.Formats.$$long, {
        locale: Caml_option.some(Locale$LumiI18n.toDateFnsLocale(locale))
      });
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: LumiReact.renderOption(undefined, occupationDetails.oldestSnapshot, (function (image) {
                                      return JsxRuntime.jsx(AbandonedVehicleReport$Image, {
                                                  image: image
                                                });
                                    })),
                              className: "column is-narrow"
                            }),
                        JsxRuntime.jsx("div", {
                              children: LumiReact.renderOption(undefined, occupationDetails.latestSnapshot, (function (image) {
                                      return JsxRuntime.jsx(AbandonedVehicleReport$Image, {
                                                  image: image
                                                });
                                    })),
                              className: "column is-narrow"
                            })
                      ],
                      className: "columns is-justify-content-center"
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(AbandonedVehicleReport$Field, {
                              label: "Facility",
                              children: JsxRuntime.jsx(AbandonedVehicleReport$TextInput, {
                                    defaultValue: props.facilityMap.name
                                  })
                            }),
                        JsxRuntime.jsx(AbandonedVehicleReport$Field, {
                              label: "Spot",
                              children: JsxRuntime.jsx(AbandonedVehicleReport$TextInput, {
                                    defaultValue: props.sensor.name
                                  })
                            }),
                        JsxRuntime.jsx(AbandonedVehicleReport$Field, {
                              label: "OccupationDuration",
                              children: JsxRuntime.jsx(AbandonedVehicleReport$TextInput, {
                                    defaultValue: occupationDuration
                                  })
                            }),
                        JsxRuntime.jsx(AbandonedVehicleReport$Field, {
                              label: "DateOfRemoval",
                              children: JsxRuntime.jsx(AbandonedVehicleReport$TextInput, {
                                    defaultValue: today
                                  })
                            }),
                        JsxRuntime.jsx(AbandonedVehicleReport$Field, {
                              label: "VehicleMake",
                              children: JsxRuntime.jsx(AbandonedVehicleReport$TextInput, {})
                            }),
                        JsxRuntime.jsx(AbandonedVehicleReport$Field, {
                              label: "VehicleType",
                              children: JsxRuntime.jsx(AbandonedVehicleReport$TextInput, {})
                            }),
                        JsxRuntime.jsx(AbandonedVehicleReport$Field, {
                              label: "VehicleColor",
                              children: JsxRuntime.jsx(AbandonedVehicleReport$TextInput, {})
                            }),
                        JsxRuntime.jsx(AbandonedVehicleReport$Field, {
                              label: "VehicleSubscriptionNumber",
                              children: JsxRuntime.jsx(AbandonedVehicleReport$TextInput, {})
                            }),
                        JsxRuntime.jsx(AbandonedVehicleReport$Field, {
                              label: "VehicleDescriptionAndDamages",
                              children: JsxRuntime.jsx(AbandonedVehicleReport$TextAreaInput, {})
                            }),
                        JsxRuntime.jsx(AbandonedVehicleReport$Field, {
                              label: "VehicleFrameNumber",
                              children: JsxRuntime.jsx(AbandonedVehicleReport$TextInput, {})
                            }),
                        JsxRuntime.jsx(AbandonedVehicleReport$Field, {
                              label: "Signature",
                              children: JsxRuntime.jsx(AbandonedVehicleReport$TextAreaInput, {})
                            })
                      ]
                    })
              ],
              className: "abandoned-vehicle-report"
            });
}

var make = AbandonedVehicleReport;

export {
  Field ,
  TextInput ,
  TextAreaInput ,
  $$Image ,
  make ,
}
/* LumiSVG Not a pure module */
