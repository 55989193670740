// Generated by ReScript, PLEASE EDIT WITH CARE

import * as V2f$Linear from "./V2f.bs.mjs";
import * as M33f$Linear from "./M33f.bs.mjs";

function toM33f(param) {
  return [
          param[0],
          param[1],
          [
            0,
            0,
            1
          ]
        ];
}

function fromM33f(param) {
  return [
          param[0],
          param[1]
        ];
}

var identity = fromM33f(M33f$Linear.identity);

function apply(v, m) {
  return V2f$Linear.asV3f(v, (function (v) {
                return M33f$Linear.Mult.v3f(toM33f(m), v);
              }));
}

function rotation(angle) {
  return [
          [
            Math.cos(angle),
            -1 * Math.sin(angle),
            0
          ],
          [
            Math.sin(angle),
            Math.cos(angle),
            0
          ]
        ];
}

function compose(m1, m2) {
  return fromM33f(M33f$Linear.Mult.m33f(toM33f(m1), toM33f(m2)));
}

function scale(param) {
  return [
          [
            param[0],
            0,
            0
          ],
          [
            0,
            param[1],
            0
          ]
        ];
}

function addTranslation(param, param$1) {
  var match = param[1];
  var match$1 = param[0];
  return [
          [
            match$1[0],
            match$1[1],
            match$1[2] + param$1[0]
          ],
          [
            match[0],
            match[1],
            match[2] + param$1[1]
          ]
        ];
}

function translation(param) {
  return [
          [
            1,
            0,
            param[0]
          ],
          [
            0,
            1,
            param[1]
          ]
        ];
}

function rotationAround(angle, point) {
  return compose(compose(translation(point), rotation(angle)), translation(V2f$Linear.scale(point, -1)));
}

var M23f = {
  toM33f: toM33f,
  fromM33f: fromM33f,
  identity: identity,
  apply: apply,
  rotation: rotation,
  compose: compose,
  scale: scale,
  addTranslation: addTranslation,
  translation: translation,
  rotationAround: rotationAround
};

function rotation$1(angle) {
  return toM33f(rotation(angle));
}

function scale$1(v) {
  return toM33f(scale(v));
}

function translation$1(v) {
  return toM33f(translation(v));
}

function addTranslation$1(m, v) {
  return toM33f(addTranslation(fromM33f(m), v));
}

function rotationAround$1(angle, point) {
  return toM33f(rotationAround(angle, point));
}

var M33f = {
  toM23f: fromM33f,
  fromM23f: toM33f,
  rotation: rotation$1,
  scale: scale$1,
  translation: translation$1,
  addTranslation: addTranslation$1,
  rotationAround: rotationAround$1
};

export {
  M23f ,
  M33f ,
}
/* identity Not a pure module */
