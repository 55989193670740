// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as LumiReact from "lumi-rescript/lib/es6/src/LumiReact.bs.mjs";
import * as V2$Linear from "lumi-linear/lib/es6/src/V2.bs.mjs";
import * as V2f$Linear from "lumi-linear/lib/es6/src/V2f.bs.mjs";
import * as V2i$Linear from "lumi-linear/lib/es6/src/V2i.bs.mjs";
import * as JsxRuntime from "react/jsx-runtime";

var jsonCodec = Jzon.object3((function (param) {
        return [
                param.topLeft,
                param.bottomLeft,
                param.bottomRight
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  topLeft: param[0],
                  bottomLeft: param[1],
                  bottomRight: param[2]
                }
              };
      }), Jzon.field("_topLeft", V2i$Linear.jsonCodec), Jzon.field("_bottomLeft", V2i$Linear.jsonCodec), Jzon.field("_bottomRight", V2i$Linear.jsonCodec));

var NNRoi = {
  jsonCodec: jsonCodec
};

var jsonCodec$1 = Jzon.object4((function (param) {
        return [
                param.topLeft,
                param.bottomLeft,
                param.bottomRight,
                param.topRight
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  topLeft: param[0],
                  bottomLeft: param[1],
                  bottomRight: param[2],
                  topRight: param[3]
                }
              };
      }), Jzon.field("_topLeft", V2f$Linear.jsonCodec), Jzon.field("_bottomLeft", V2f$Linear.jsonCodec), Jzon.field("_bottomRight", V2f$Linear.jsonCodec), Jzon.field("_topRight", V2f$Linear.jsonCodec));

function points(param) {
  return [
          param.topLeft,
          param.bottomLeft,
          param.bottomRight,
          param.topRight
        ];
}

function NNRoi$NNPolygon(props) {
  var __classList = props.classList;
  var classList = __classList !== undefined ? __classList : [];
  var className = LumiReact.classList([[
            "place-model-nn-polygon",
            true
          ]].concat(classList));
  return JsxRuntime.jsx("g", {
              children: JsxRuntime.jsx("polygon", {
                    fill: "none",
                    points: points(props.nnPolygon).map(function (__x) {
                              return V2$Linear.map(__x, (function (prim) {
                                            return prim.toString();
                                          }));
                            }).map(function (param) {
                            return param[0] + "," + param[1];
                          }).join(" "),
                    stroke: "orange",
                    strokeWidth: "1",
                    vectorEffect: "non-scaling-stroke"
                  }),
              className: className
            });
}

var NNPolygon = {
  jsonCodec: jsonCodec$1,
  points: points,
  make: NNRoi$NNPolygon
};

export {
  NNRoi ,
  NNPolygon ,
}
/* jsonCodec Not a pure module */
