// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Route$Dashboard from "./Route.bs.mjs";
import * as RescriptReactRouter from "@rescript/react/lib/es6/src/RescriptReactRouter.bs.mjs";

function navigate(route) {
  RescriptReactRouter.push("#" + Route$Dashboard.toString(route));
}

function useRoute() {
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  return Route$Dashboard.fromString(url.hash);
}

export {
  navigate ,
  useRoute ,
}
/* Route-Dashboard Not a pure module */
