// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as LumiReact from "lumi-rescript/lib/es6/src/LumiReact.bs.mjs";
import * as LumiCookie from "lumi-rescript/lib/es6/src/LumiCookie.bs.mjs";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.bs.mjs";
import * as Locale$LumiI18n from "./Locale.bs.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as JsCookieJs from "lumi-rescript/src/js-cookie.js";

function $$default(param) {
  
}

var SetLocale = LumiReact.Context.Make({
      $$default: $$default
    });

var $$default$1 = ["ENG"];

var Locales = LumiReact.Context.Make({
      $$default: $$default$1
    });

function Localization$CookieProvider(props) {
  var $$default = props.default;
  var match = React.useState(function () {
        return Core__Option.getOr(Core__Option.flatMap(JsCookieJs.get("language"), Locale$LumiI18n.fromString), $$default);
      });
  var setLocale = match[1];
  var locale = match[0];
  React.useEffect((function () {
          LumiCookie.set("language", Locale$LumiI18n.toString(locale), 3650, undefined, undefined, undefined, "Strict", undefined);
        }), [locale]);
  return JsxRuntime.jsx(Locale$LumiI18n.Provider.make, {
              value: locale,
              children: JsxRuntime.jsx(SetLocale.Provider.make, {
                    value: (function (locale) {
                        setLocale(function (param) {
                              return locale;
                            });
                      }),
                    children: JsxRuntime.jsx(Locales.Provider.make, {
                          value: props.locales,
                          children: props.children
                        })
                  })
            });
}

var CookieProvider = {
  make: Localization$CookieProvider
};

function Localization$FixedProvider(props) {
  var locale = props.locale;
  return JsxRuntime.jsx(Locale$LumiI18n.Provider.make, {
              value: locale,
              children: JsxRuntime.jsx(SetLocale.Provider.make, {
                    value: (function (param) {
                        
                      }),
                    children: JsxRuntime.jsx(Locales.Provider.make, {
                          value: [locale],
                          children: props.children
                        })
                  })
            });
}

var FixedProvider = {
  make: Localization$FixedProvider
};

export {
  SetLocale ,
  Locales ,
  CookieProvider ,
  FixedProvider ,
}
/* SetLocale Not a pure module */
