// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as V4$Linear from "./V4.bs.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Core__List from "@rescript/core/lib/es6/src/Core__List.bs.mjs";
import * as V2f$Linear from "./V2f.bs.mjs";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.bs.mjs";
import * as Rectf$Linear from "./Rectf.bs.mjs";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as Caml_splice_call from "rescript/lib/es6/caml_splice_call.js";

function sortOn(xs, f) {
  return Belt_SortArray.stableSortBy(xs, (function (a, b) {
                return Caml_obj.compare(f(a), f(b));
              }));
}

function adjacentPairs(xs) {
  if (xs.length !== 0) {
    return Belt_Array.zip(xs, xs.slice(1).concat([xs[0]]));
  } else {
    return [];
  }
}

function chain(points) {
  var clockwise = function (o, a, b) {
    return V2f$Linear.crossZ(V2f$Linear.minus(a, o), V2f$Linear.minus(b, o)) <= 0;
  };
  var go = function (_acc, _todo) {
    while(true) {
      var todo = _todo;
      var acc = _acc;
      if (acc) {
        var match = acc.tl;
        if (match && todo) {
          var x = todo.hd;
          var r2 = match.hd;
          if (clockwise(r2, acc.hd, x)) {
            _acc = {
              hd: r2,
              tl: match.tl
            };
            continue ;
          }
          _todo = todo.tl;
          _acc = {
            hd: x,
            tl: acc
          };
          continue ;
        }
        
      }
      if (!todo) {
        return Core__List.reverse(Core__Option.getOr(Core__List.tail(acc), /* [] */0));
      }
      _todo = todo.tl;
      _acc = {
        hd: todo.hd,
        tl: acc
      };
      continue ;
    };
  };
  return Core__List.toArray(go(/* [] */0, Core__List.fromArray(points)));
}

function convexHull(points) {
  var len = points.length;
  if (len === 1) {
    return [points[0]];
  }
  if (len === 0) {
    return [];
  }
  var sorted = Belt_SortArray.stableSortBy(points, Caml_obj.compare);
  var lower = chain(sorted);
  var upper = chain(sorted.toReversed());
  return lower.concat(upper);
}

function axisAlignedBoundingBox(points) {
  var xs = points.map(function (prim) {
        return prim[0];
      });
  var ys = points.map(function (prim) {
        return prim[1];
      });
  var min_0 = Caml_splice_call.spliceApply(Math.min, [xs]);
  var min_1 = Caml_splice_call.spliceApply(Math.min, [ys]);
  var min = [
    min_0,
    min_1
  ];
  var max_0 = Caml_splice_call.spliceApply(Math.max, [xs]);
  var max_1 = Caml_splice_call.spliceApply(Math.max, [ys]);
  var max = [
    max_0,
    max_1
  ];
  return {
          pos: min,
          size: V2f$Linear.minus(max, min)
        };
}

function orientedMinimumBoundingBox(points) {
  var hullPoints = convexHull(points);
  var hullEdges = adjacentPairs(hullPoints).filter(function (param) {
        return Caml_obj.notequal(param[0], param[1]);
      });
  if (hullEdges.length === 0) {
    return ;
  }
  var edgeAlignments = hullEdges.map(function (param) {
        var angle = V2f$Linear.angle([
              1,
              0
            ], V2f$Linear.minus(param[1], param[0]));
        return [
                angle,
                points.map(function (__x) {
                      return V2f$Linear.rotate(__x, angle);
                    })
              ];
      });
  var edgeBoxes = edgeAlignments.map(function (param) {
        return [
                param[0],
                axisAlignedBoundingBox(param[1])
              ];
      });
  return sortOn(edgeBoxes, (function (param) {
                  return param[1].size;
                }))[0];
}

function orientedBoundingBoxPoints(param) {
  var angle = param[0];
  return V4$Linear.map(Rectf$Linear.points(param[1]), (function (__x) {
                return V2f$Linear.rotate(__x, -1 * angle);
              }));
}

export {
  sortOn ,
  adjacentPairs ,
  chain ,
  convexHull ,
  axisAlignedBoundingBox ,
  orientedMinimumBoundingBox ,
  orientedBoundingBoxPoints ,
}
/* V2f-Linear Not a pure module */
