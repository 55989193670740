// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as Belt_Id from "rescript/lib/es6/belt_Id.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as LumiJzon from "lumi-rescript/lib/es6/src/LumiJzon.bs.mjs";
import * as Core__Int from "@rescript/core/lib/es6/src/Core__Int.bs.mjs";
import * as FontAwesome from "lumi-rescript/lib/es6/src/FontAwesome.bs.mjs";
import * as JsxRuntime from "react/jsx-runtime";

var Namespace = {
  jsonCodec: Jzon.$$int
};

var GroupId = {
  jsonCodec: Jzon.$$int
};

var SensorId = {
  jsonCodec: Jzon.$$int
};

function toString(param) {
  return param[0].toString() + "," + param[1].toString();
}

function fromString(str) {
  var parts = str.split(",", 2).map(function (extra) {
        return Core__Int.fromString(10, extra);
      });
  if (parts.length !== 2) {
    return {
            TAG: "Error",
            _0: "invalid groupFullId: " + str
          };
  }
  var namespace = parts[0];
  if (namespace === undefined) {
    return {
            TAG: "Error",
            _0: "invalid groupFullId: " + str
          };
  }
  var group = parts[1];
  if (group !== undefined) {
    return {
            TAG: "Ok",
            _0: [
              namespace,
              group
            ]
          };
  } else {
    return {
            TAG: "Error",
            _0: "invalid groupFullId: " + str
          };
  }
}

function cmp(param, param$1) {
  var c = Caml_obj.compare(param[0], param$1[0]);
  if (c !== 0) {
    return c;
  } else {
    return Caml_obj.compare(param[1], param$1[1]);
  }
}

var Cmp = Belt_Id.MakeComparableU({
      cmp: cmp
    });

var jsonCodec = LumiJzon.tuple2(Jzon.$$int, Jzon.$$int);

function SensorGroups$GroupFullId(props) {
  var match = props.groupFullId;
  return JsxRuntime.jsxs("span", {
              children: [
                JsxRuntime.jsx("span", {
                      children: JsxRuntime.jsx(FontAwesome.Solid.make, {
                            name: "database"
                          }),
                      className: "tag is-dark"
                    }),
                JsxRuntime.jsx("span", {
                      children: "(" + match[0].toString() + ", " + match[1].toString() + ")",
                      className: "tag is-light"
                    })
              ],
              className: "tags has-addons"
            });
}

var GroupFullId = {
  toString: toString,
  fromString: fromString,
  Cmp: Cmp,
  jsonCodec: jsonCodec,
  make: SensorGroups$GroupFullId
};

function toString$1(param) {
  return param[0].toString() + "," + param[1].toString();
}

function fromString$1(str) {
  var parts = str.split(",", 2).map(function (extra) {
        return Core__Int.fromString(10, extra);
      });
  if (parts.length !== 2) {
    return {
            TAG: "Error",
            _0: "invalid sensorFullId: " + str
          };
  }
  var namespace = parts[0];
  if (namespace === undefined) {
    return {
            TAG: "Error",
            _0: "invalid sensorFullId: " + str
          };
  }
  var group = parts[1];
  if (group !== undefined) {
    return {
            TAG: "Ok",
            _0: [
              namespace,
              group
            ]
          };
  } else {
    return {
            TAG: "Error",
            _0: "invalid sensorFullId: " + str
          };
  }
}

function cmp$1(param, param$1) {
  var c = Caml_obj.compare(param[0], param$1[0]);
  if (c !== 0) {
    return c;
  } else {
    return Caml_obj.compare(param[1], param$1[1]);
  }
}

var Cmp$1 = Belt_Id.MakeComparableU({
      cmp: cmp$1
    });

var jsonCodec$1 = LumiJzon.tuple2(Jzon.$$int, Jzon.$$int);

var SensorFullId = {
  toString: toString$1,
  fromString: fromString$1,
  Cmp: Cmp$1,
  jsonCodec: jsonCodec$1
};

export {
  Namespace ,
  GroupId ,
  SensorId ,
  GroupFullId ,
  SensorFullId ,
}
/* Cmp Not a pure module */
