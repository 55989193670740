// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Spinner from "lumi-rescript/lib/es6/src/Spinner.bs.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as FacilityMap$Dashboard from "./FacilityMap.bs.mjs";
import * as Translation$Dashboard from "./Translation.bs.mjs";
import * as FacilityMap$LumiDocuments from "lumi-documents/lib/es6/src/FacilityMap.bs.mjs";

function seconds(pd) {
  var day = 24 * 3600;
  var week = 7 * day;
  switch (pd) {
    case "OneHour" :
        return 3600;
    case "TwoHours" :
        return 2 * 3600;
    case "SixHours" :
        return 6 * 3600;
    case "TwelveHours" :
        return 12 * 3600;
    case "OneDay" :
        return day;
    case "OneWeek" :
        return week;
    case "TwoWeeks" :
        return 2 * week;
    case "OneMonth" :
        return 30 * day;
    
  }
}

function calcSensorClasses(placeClassClasses, parkingDuration, sensorStates) {
  var cutoff = Date.now() - seconds(parkingDuration) * 1000;
  return FacilityMap$LumiDocuments.SVG.Spots.SensorClasses.merge(placeClassClasses, FacilityMap$LumiDocuments.SVG.Spots.SensorClasses.fromArray(sensorStates.map(function (sensorState) {
                      return [
                              sensorState.sensorKey,
                              [[
                                  "facility-map-spot-abandoned",
                                  sensorState.measurement.free !== 0 ? false : sensorState.changeTime.getTime() < cutoff
                                ]]
                            ];
                    })));
}

var parkingDurations = [
  [
    "OneHour",
    {
      TAG: "HoursOrLonger",
      _0: 1
    }
  ],
  [
    "TwoHours",
    {
      TAG: "HoursOrLonger",
      _0: 2
    }
  ],
  [
    "SixHours",
    {
      TAG: "HoursOrLonger",
      _0: 6
    }
  ],
  [
    "TwelveHours",
    {
      TAG: "HoursOrLonger",
      _0: 12
    }
  ],
  [
    "OneDay",
    {
      TAG: "DaysOrLonger",
      _0: 1
    }
  ],
  [
    "OneWeek",
    {
      TAG: "WeeksOrLonger",
      _0: 1
    }
  ],
  [
    "TwoWeeks",
    {
      TAG: "WeeksOrLonger",
      _0: 2
    }
  ],
  [
    "OneMonth",
    {
      TAG: "MonthsOrLonger",
      _0: 1
    }
  ]
];

function spd(s) {
  switch (s) {
    case "1" :
        return "OneHour";
    case "2" :
        return "TwoHours";
    case "3" :
        return "SixHours";
    case "4" :
        return "TwelveHours";
    case "5" :
        return "OneDay";
    case "6" :
        return "OneWeek";
    case "7" :
        return "TwoWeeks";
    case "8" :
        return "OneMonth";
    default:
      return "OneWeek";
  }
}

function pds(p) {
  switch (p) {
    case "OneHour" :
        return "1";
    case "TwoHours" :
        return "2";
    case "SixHours" :
        return "3";
    case "TwelveHours" :
        return "4";
    case "OneDay" :
        return "5";
    case "OneWeek" :
        return "6";
    case "TwoWeeks" :
        return "7";
    case "OneMonth" :
        return "8";
    
  }
}

function AbandonedVehicles$Footer(props) {
  var setParkingDuration = props.setParkingDuration;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(Translation$Dashboard.Provider.$$Text.make, {
                            entry: "ParkingDuration"
                          }),
                      className: "panel-block has-text-weight-bold"
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("span", {
                            children: JsxRuntime.jsx("select", {
                                  children: parkingDurations.map(function (param) {
                                        var duration = param[0];
                                        return JsxRuntime.jsx("option", {
                                                    children: JsxRuntime.jsx(Translation$Dashboard.Provider.$$Text.make, {
                                                          entry: param[1]
                                                        }),
                                                    value: pds(duration)
                                                  }, pds(duration));
                                      }),
                                  value: pds(props.parkingDuration),
                                  onChange: (function (e) {
                                      var pd = spd(e.target.value);
                                      setParkingDuration(function (param) {
                                            return pd;
                                          });
                                    })
                                }),
                            className: "select is-fullwidth"
                          }),
                      className: "panel-block"
                    })
              ]
            });
}

var Footer = {
  make: AbandonedVehicles$Footer
};

function AbandonedVehicles(props) {
  var facilityInfos = props.facilityInfos;
  var buildRoute = function (x) {
    return {
            TAG: "AbandonedVehicles",
            _0: x
          };
  };
  var facilityMaps = FacilityMap$Dashboard.useFacilityMaps(props.route, facilityInfos, buildRoute);
  var dispatch = facilityMaps.dispatch;
  var match = facilityMaps.state;
  var sensorStates = match.sensorStates;
  var parentSensorClasses = match.sensorClasses;
  var facilityMap = match.facilityMap;
  var match$1 = React.useState(function () {
        return "OneWeek";
      });
  var parkingDuration = match$1[0];
  var sensorClasses = React.useMemo((function () {
          return calcSensorClasses(parentSensorClasses, parkingDuration, sensorStates);
        }), [
        parentSensorClasses,
        parkingDuration,
        sensorStates
      ]);
  var onSelectSensor = React.useCallback((function (mbSensorKey) {
          dispatch({
                TAG: "SelectSensor",
                _0: mbSensorKey
              });
        }), []);
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(FacilityMap$Dashboard.Sidebar.make, {
                      facilityMaps: facilityMaps,
                      facilityInfos: facilityInfos,
                      toApplication: props.toApplication,
                      buildRoute: buildRoute,
                      children: Caml_option.some(JsxRuntime.jsx(AbandonedVehicles$Footer, {
                                parkingDuration: parkingDuration,
                                setParkingDuration: match$1[1]
                              }))
                    }),
                JsxRuntime.jsx("div", {
                      children: facilityMap !== undefined ? JsxRuntime.jsx(FacilityMap$LumiDocuments.SVG.Container.make, {
                              facilityMap: facilityMap,
                              children: Caml_option.some(JsxRuntime.jsx(FacilityMap$LumiDocuments.SVG.Spots.make, {
                                        facilityMap: facilityMap,
                                        sensorLabels: Caml_option.some(match.sensorLabels),
                                        sensorClasses: Caml_option.some(sensorClasses),
                                        onClickSpot: (function (_event, _svgId, mbSensorKey) {
                                            onSelectSensor(mbSensorKey);
                                          })
                                      })),
                              onDeselectAll: (function () {
                                  onSelectSensor(undefined);
                                }),
                              zoomFilter: FacilityMap$Dashboard.defaultZoomFilter
                            }) : JsxRuntime.jsx(Spinner.make, {}),
                      className: "column contents"
                    })
              ]
            });
}

var make = AbandonedVehicles;

export {
  seconds ,
  calcSensorClasses ,
  parkingDurations ,
  spd ,
  pds ,
  Footer ,
  make ,
}
/* react Not a pure module */
