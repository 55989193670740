// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as Core__Result from "@rescript/core/lib/es6/src/Core__Result.bs.mjs";
import * as Translation$LumiI18n from "lumi-i18n/lib/es6/src/Translation.bs.mjs";

var options = [
  "OnePlace",
  "TwoPlaces"
];

var jsonCodec = Jzon.custom((function (v) {
        var tmp;
        tmp = v === "OnePlace" ? "OnePlace" : "TwoPlaces";
        return Jzon.encodeWith(tmp, Jzon.string);
      }), (function (json) {
        return Core__Result.flatMap(Jzon.decodeWith(json, Jzon.string), (function (v) {
                      switch (v) {
                        case "OnePlace" :
                            return {
                                    TAG: "Ok",
                                    _0: "OnePlace"
                                  };
                        case "TwoPlaces" :
                            return {
                                    TAG: "Ok",
                                    _0: "TwoPlaces"
                                  };
                        default:
                          return {
                                  TAG: "Error",
                                  _0: {
                                    NAME: "UnexpectedJsonValue",
                                    VAL: [
                                      [],
                                      v
                                    ]
                                  }
                                };
                      }
                    }));
      }));

function translate(locale, entry) {
  switch (locale) {
    case "ENG" :
        if (entry === "OnePlace") {
          return "One place";
        } else {
          return "Two places";
        }
    case "NLD" :
        if (entry === "OnePlace") {
          return "Één plaats";
        } else {
          return "Twee plaatsen";
        }
    default:
      return ;
  }
}

var T = Translation$LumiI18n.MakeProvider({
      defaultLocale: "ENG",
      translate: translate
    });

export {
  options ,
  jsonCodec ,
  T ,
}
/* jsonCodec Not a pure module */
