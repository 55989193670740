// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Future from "rescript-future/lib/es6/src/Future.bs.mjs";
import * as LumiReact from "./LumiReact.bs.mjs";
import * as LumiOption from "./LumiOption.bs.mjs";
import * as LumiResult from "./LumiResult.bs.mjs";
import * as LumiString from "./LumiString.bs.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.bs.mjs";
import * as Core__Result from "@rescript/core/lib/es6/src/Core__Result.bs.mjs";
import * as FuturePromise from "rescript-future/lib/es6/src/FuturePromise.bs.mjs";
import * as Webapi__Fetch from "rescript-webapi/lib/es6/src/Webapi/Webapi__Fetch.bs.mjs";
import * as JsxRuntime from "react/jsx-runtime";

function toString(err) {
  var shorten = function (str) {
    var parts = str.split(" ");
    if (parts.length <= 3) {
      return str;
    } else {
      return parts[0] + " (...) " + parts[parts.length - 1 | 0];
    }
  };
  if (typeof err !== "object") {
    if (err === "NoXSRFTokenCookie") {
      return "No XSRF token cookie";
    } else {
      return "Network request failed";
    }
  } else if (err.TAG === "ServerError") {
    return "HTTP " + err.status.toString() + " " + err.statusText + Core__Option.mapOr(err.response, "", (function (response) {
                  return ": " + shorten(response);
                }));
  } else {
    return "Invalid response: " + shorten(err._0);
  }
}

function invalidResponse(response) {
  return {
          TAG: "InvalidResponse",
          _0: response
        };
}

function LumiRequest$Error$Notification(props) {
  var __classList = props.classList;
  var error = props.error;
  var classList = __classList !== undefined ? __classList : [[
        "is-danger",
        true
      ]];
  var className = LumiReact.classList([[
            "notification",
            true
          ]].concat(classList));
  var tmp;
  tmp = typeof error !== "object" ? toString(error) : (
      error.TAG === "ServerError" ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("span", {
                      children: "HTTP " + error.status.toString() + " " + error.statusText
                    }),
                LumiReact.renderOption(undefined, error.response, (function (response) {
                        return JsxRuntime.jsx("pre", {
                                    children: response,
                                    style: {
                                      whiteSpace: "pre-wrap"
                                    }
                                  });
                      }))
              ]
            }) : JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("span", {
                      children: "Invalid response"
                    }),
                JsxRuntime.jsx("pre", {
                      children: error._0,
                      style: {
                        whiteSpace: "pre-wrap"
                      }
                    })
              ]
            })
    );
  return JsxRuntime.jsx("div", {
              children: tmp,
              className: className,
              onClick: props.onClick
            });
}

var $$Notification = {
  make: LumiRequest$Error$Notification
};

var $$Error = {
  toString: toString,
  invalidResponse: invalidResponse,
  $$Notification: $$Notification
};

function getBodyFromResponse(bodyFromResponse, response) {
  return Future.mapError(FuturePromise.fromPromise(bodyFromResponse(response)), true, (function (param) {
                return {
                        TAG: "InvalidResponse",
                        _0: "Error reading body"
                      };
              }));
}

function make(url, methodOpt, headersOpt, body, param) {
  var method = methodOpt !== undefined ? methodOpt : "GET";
  var headers = headersOpt !== undefined ? Caml_option.valFromOption(headersOpt) : ({});
  return {
          url: url,
          method: method === "GET" ? "Get" : (
              method === "PUT" ? "Put" : (
                  method === "DELETE" ? "Delete" : (
                      method === "POST" ? "Post" : (
                          method === "PATCH" ? "Patch" : "Options"
                        )
                    )
                )
            ),
          headers: headers,
          body: Core__Option.map(body, (function (prim) {
                  return prim;
                })),
          parseResponse: (function (extra) {
              return getBodyFromResponse((function (prim) {
                            return prim.text();
                          }), extra);
            })
        };
}

function sendJSON(req, value, codec) {
  req.headers["Content-Type"] = "application/json;charset=utf-8";
  return {
          url: req.url,
          method: req.method,
          headers: req.headers,
          body: Caml_option.some(Jzon.encodeStringWith(value, codec)),
          parseResponse: req.parseResponse
        };
}

function expectBody(req, bodyFromResponse) {
  return {
          url: req.url,
          method: req.method,
          headers: req.headers,
          body: req.body,
          parseResponse: (function (extra) {
              return getBodyFromResponse(bodyFromResponse, extra);
            })
        };
}

function flatMapWithResponse(req, f) {
  return {
          url: req.url,
          method: req.method,
          headers: req.headers,
          body: req.body,
          parseResponse: (function (response) {
              return Future.mapResult(req.parseResponse(response), true, (function (extra) {
                            return Curry._2(f, response, extra);
                          }));
            })
        };
}

function mapWithResponse(req, f) {
  return flatMapWithResponse(req, (function (response, intermediate) {
                return {
                        TAG: "Ok",
                        _0: f(response, intermediate)
                      };
              }));
}

function flatMapResponse(req, f) {
  return flatMapWithResponse(req, (function (param, intermediate) {
                return f(intermediate);
              }));
}

function mapResponse(req, f) {
  return flatMapWithResponse(req, (function (param, intermediate) {
                return {
                        TAG: "Ok",
                        _0: f(intermediate)
                      };
              }));
}

function expectBlob(req) {
  return expectBody(req, (function (prim) {
                return prim.blob();
              }));
}

function expectJSON(req, codec) {
  req.headers["Accept"] = "application/json;charset=utf-8";
  return flatMapResponse(expectBody(req, (function (prim) {
                    return prim.json();
                  })), (function (json) {
                return Core__Result.mapError(Core__Result.mapError(Jzon.decodeWith(json, codec), Jzon.DecodingError.toString), invalidResponse);
              }));
}

function expectJSONAsAny(req) {
  req.headers["Accept"] = "application/json;charset=utf-8";
  return mapResponse(expectBody(req, (function (prim) {
                    return prim.json();
                  })), (function (x) {
                return x;
              }));
}

function expectText(req, text) {
  return flatMapResponse(expectBody(req, (function (prim) {
                    return prim.text();
                  })), (function (body) {
                if (body === text) {
                  return {
                          TAG: "Ok",
                          _0: undefined
                        };
                } else {
                  return {
                          TAG: "Error",
                          _0: {
                            TAG: "InvalidResponse",
                            _0: body
                          }
                        };
                }
              }));
}

function expectNoContent(req) {
  return expectText(req, "");
}

function parseResponseHeader(response, header, parseValue) {
  return Core__Result.flatMap(LumiOption.okOr(Caml_option.null_to_opt(response.headers.get(header)), {
                  TAG: "InvalidResponse",
                  _0: "Missing response header: " + header
                }), (function (str) {
                return Core__Result.mapError(parseValue(str), (function (err) {
                              return {
                                      TAG: "InvalidResponse",
                                      _0: "Invalid value for response header " + header + ": " + err
                                    };
                            }));
              }));
}

function parseResponseHeaderJzon(response, header, codec) {
  return parseResponseHeader(response, header, (function (str) {
                return Core__Result.mapError(Jzon.decodeStringWith(str, codec), Jzon.DecodingError.toString);
              }));
}

function checkStatus(response) {
  var status = response.status;
  if (status >= 200 && status < 400) {
    return Future.value({
                TAG: "Ok",
                _0: response
              });
  } else {
    return Future.map(FuturePromise.fromPromise(response.text()), true, (function (body) {
                  return {
                          TAG: "Error",
                          _0: {
                            TAG: "ServerError",
                            status: status,
                            statusText: response.statusText,
                            response: Core__Option.flatMap(LumiResult.ok(body), LumiString.nonEmpty)
                          }
                        };
                }));
  }
}

function send(param) {
  var body = param.body;
  var headers = param.headers;
  var method = param.method;
  var url = param.url;
  return Future.flatMapOk(Future.flatMapOk(Future.make(function (resolve) {
                      var abortController = new AbortController();
                      (function (__x) {
                              return fetch(url, __x);
                            })(Webapi__Fetch.RequestInit.make(method, Caml_option.some(headers), body, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(abortController.signal), undefined)).then((function (ok) {
                              resolve({
                                    TAG: "Ok",
                                    _0: ok
                                  });
                            }), (function (_exn) {
                              resolve({
                                    TAG: "Error",
                                    _0: "NetworkRequestFailed"
                                  });
                            }));
                      return (function () {
                                abortController.abort();
                              });
                    }), true, checkStatus), true, param.parseResponse);
}

function resultFutureEffect(req, onSuccess, onError) {
  return LumiReact.resultFutureEffect(send(req), onSuccess, onError);
}

export {
  $$Error ,
  getBodyFromResponse ,
  make ,
  sendJSON ,
  expectBody ,
  flatMapWithResponse ,
  mapWithResponse ,
  flatMapResponse ,
  mapResponse ,
  expectBlob ,
  expectJSON ,
  expectJSONAsAny ,
  expectText ,
  expectNoContent ,
  parseResponseHeader ,
  parseResponseHeaderJzon ,
  checkStatus ,
  send ,
  resultFutureEffect ,
}
/* LumiReact Not a pure module */
