// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as LumiReact from "lumi-rescript/lib/es6/src/LumiReact.bs.mjs";
import * as V2$Linear from "lumi-linear/lib/es6/src/V2.bs.mjs";
import * as N2f$Linear from "lumi-linear/lib/es6/src/N2f.bs.mjs";
import * as V2f$Linear from "lumi-linear/lib/es6/src/V2f.bs.mjs";
import * as M23f$Linear from "lumi-linear/lib/es6/src/M23f.bs.mjs";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.bs.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as PlaceClass$LumiDocuments from "./PlaceClass.bs.mjs";

import '../../../src/css/place-model.sass'
;

function up(param, placeClass) {
  var up$1 = param.up;
  if (typeof placeClass !== "object" && placeClass === "PlaceClassVertical") {
    return N2f$Linear.normalize(V2f$Linear.minus(param.baseBack, param.baseFront));
  } else {
    return up$1;
  }
}

function transform(param, hmirror, vflip, param$1) {
  var height = param[1];
  var width = param[0];
  var transformAbs = function (param) {
    var y = param[1];
    var x = param[0];
    return [
            hmirror ? width - x : x,
            vflip ? height - y : y
          ];
  };
  var transformRel = function (param) {
    var y = param[1];
    var x = param[0];
    return [
            hmirror ? -1 * x : x,
            vflip ? -1 * y : y
          ];
  };
  return {
          baseFront: transformAbs(param$1.baseFront),
          baseBack: transformAbs(param$1.baseBack),
          up: Core__Option.map(param$1.up, (function (n2) {
                  return N2f$Linear.map(n2, transformRel);
                })),
          side: transformAbs(param$1.side),
          sideLength: param$1.sideLength
        };
}

function warpAffine(am, m23) {
  return {
          baseFront: M23f$Linear.Mult.v2f(m23, am.baseFront),
          baseBack: M23f$Linear.Mult.v2f(m23, am.baseBack),
          up: Core__Option.map(am.up, (function (up) {
                  return M23f$Linear.Mult.n2f(m23, up);
                })),
          side: M23f$Linear.Mult.v2f(m23, am.side),
          sideLength: am.sideLength
        };
}

function elongateBase(am, s) {
  var frontToBack = V2f$Linear.minus(am.baseBack, am.baseFront);
  var baseMid = V2f$Linear.plus(am.baseFront, V2f$Linear.scale(frontToBack, 0.5));
  var midToFront = V2f$Linear.minus(am.baseFront, baseMid);
  var midToBack = V2f$Linear.minus(am.baseBack, baseMid);
  var front$p = V2f$Linear.plus(baseMid, V2f$Linear.scale(midToFront, s));
  var back$p = V2f$Linear.plus(baseMid, V2f$Linear.scale(midToBack, s));
  return {
          baseFront: front$p,
          baseBack: back$p,
          up: am.up,
          side: am.side,
          sideLength: am.sideLength
        };
}

var jsonCodec = Jzon.object5((function (param) {
        return [
                param.baseFront,
                param.baseBack,
                param.up,
                param.side,
                param.sideLength
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  baseFront: param[0],
                  baseBack: param[1],
                  up: param[2],
                  side: param[3],
                  sideLength: param[4]
                }
              };
      }), Jzon.field("baseFront", V2f$Linear.jsonCodec), Jzon.field("baseBack", V2f$Linear.jsonCodec), Jzon.field("up", Jzon.nullable(N2f$Linear.jsonCodec)), Jzon.field("side", V2f$Linear.jsonCodec), Jzon.field("sideLength", Jzon.$$float));

function getScale(param) {
  return V2f$Linear.length(V2f$Linear.minus(param.side, param.baseFront)) / param.sideLength;
}

function toPoly(placeModel, placeClass) {
  var up = placeModel.up;
  var baseBack = placeModel.baseBack;
  var baseFront = placeModel.baseFront;
  var pxPerM = getScale(placeModel);
  var dimensions = PlaceClass$LumiDocuments.PlaceClass.axesModelDimensions(placeClass);
  if (up !== undefined) {
    var normBase = V2f$Linear.scaleTo(V2f$Linear.minus(baseBack, baseFront), 1);
    var perpCW = V2f$Linear.perpendicularCW(normBase);
    var perpBack = V2f$Linear.scale(perpCW, 0.5 * dimensions.widthBack * pxPerM);
    var perpFront = V2f$Linear.scale(perpCW, 0.5 * dimensions.widthFront * pxPerM);
    var upBack = V2f$Linear.scaleTo(N2f$Linear.asV2f(up), 0.5 * dimensions.heightBack * pxPerM);
    var upFront = V2f$Linear.scaleTo(N2f$Linear.asV2f(up), 0.5 * dimensions.heightFront * pxPerM);
    var front = V2f$Linear.minus(baseFront, V2f$Linear.scale(normBase, dimensions.baseFrontExtra * pxPerM));
    var back = V2f$Linear.plus(baseBack, V2f$Linear.scale(normBase, dimensions.baseBackExtra * pxPerM));
    return {
            topLeft: V2f$Linear.plus(V2f$Linear.plus(back, perpBack), upBack),
            bottomLeft: V2f$Linear.plus(V2f$Linear.plus(front, perpFront), upFront),
            bottomRight: V2f$Linear.plus(V2f$Linear.minus(front, perpFront), upFront),
            topRight: V2f$Linear.plus(V2f$Linear.minus(back, perpBack), upBack)
          };
  }
  var halfFrontWidth = V2f$Linear.scaleTo(V2f$Linear.minus(placeModel.side, baseFront), 0.5 * dimensions.widthFront * pxPerM);
  return {
          topLeft: V2f$Linear.plus(baseBack, halfFrontWidth),
          bottomLeft: V2f$Linear.plus(baseFront, halfFrontWidth),
          bottomRight: V2f$Linear.minus(baseFront, halfFrontWidth),
          topRight: V2f$Linear.minus(baseBack, halfFrontWidth)
        };
}

function extraPoints(param) {
  return [
          param.baseFront,
          param.baseBack
        ];
}

function mainPoint(param) {
  return param.baseFront;
}

function scale(baseFront, side, sideLength) {
  return V2f$Linear.length(V2f$Linear.minus(side, baseFront)) / sideLength;
}

function AxesModel$Svg$Base(props) {
  var __classList = props.classList;
  var classList = __classList !== undefined ? __classList : [];
  var className = LumiReact.classList([[
            "place-model-base",
            true
          ]].concat(classList));
  var match = V2$Linear.map(props.baseFront, (function (prim) {
          return prim.toString();
        }));
  var match$1 = V2$Linear.map(props.baseBack, (function (prim) {
          return prim.toString();
        }));
  return JsxRuntime.jsx("line", {
              className: className,
              vectorEffect: "non-scaling-stroke",
              x1: match[0],
              x2: match$1[0],
              y1: match[1],
              y2: match$1[1]
            });
}

var Base = {
  make: AxesModel$Svg$Base
};

function AxesModel$Svg$Side(props) {
  var __classList = props.classList;
  var classList = __classList !== undefined ? __classList : [];
  var className = LumiReact.classList([[
            "place-model-side",
            true
          ]].concat(classList));
  var match = V2$Linear.map(props.baseFront, (function (prim) {
          return prim.toString();
        }));
  var match$1 = V2$Linear.map(props.side, (function (prim) {
          return prim.toString();
        }));
  return JsxRuntime.jsx("line", {
              className: className,
              vectorEffect: "non-scaling-stroke",
              x1: match[0],
              x2: match$1[0],
              y1: match[1],
              y2: match$1[1]
            });
}

var Side = {
  make: AxesModel$Svg$Side
};

function AxesModel$Svg$Up(props) {
  var __classList = props.classList;
  var baseFront = props.baseFront;
  var classList = __classList !== undefined ? __classList : [];
  var scale$1 = scale(baseFront, props.side, props.sideLength);
  var absoluteUp = V2f$Linear.plus(baseFront, V2f$Linear.scaleTo(N2f$Linear.asV2f(props.up), scale$1 * 0.5));
  var className = LumiReact.classList([[
            "place-model-up",
            true
          ]].concat(classList));
  var match = V2$Linear.map(baseFront, (function (prim) {
          return prim.toString();
        }));
  var match$1 = V2$Linear.map(absoluteUp, (function (prim) {
          return prim.toString();
        }));
  return JsxRuntime.jsx("line", {
              className: className,
              vectorEffect: "non-scaling-stroke",
              x1: match[0],
              x2: match$1[0],
              y1: match[1],
              y2: match$1[1]
            });
}

var Up = {
  make: AxesModel$Svg$Up
};

function AxesModel$Svg(props) {
  var __upClassList = props.upClassList;
  var __sideClassList = props.sideClassList;
  var __baseClassList = props.baseClassList;
  var match = props.axesModel;
  var sideLength = match.sideLength;
  var side = match.side;
  var baseBack = match.baseBack;
  var baseFront = match.baseFront;
  var baseClassList = __baseClassList !== undefined ? __baseClassList : [];
  var sideClassList = __sideClassList !== undefined ? __sideClassList : [];
  var upClassList = __upClassList !== undefined ? __upClassList : [];
  var match$1 = V2$Linear.map(baseFront, (function (prim) {
          return prim.toString();
        }));
  var match$2 = V2$Linear.map(baseBack, (function (prim) {
          return prim.toString();
        }));
  return JsxRuntime.jsxs("g", {
              children: [
                JsxRuntime.jsx("line", {
                      className: "place-model-click-target",
                      vectorEffect: "non-scaling-stroke",
                      x1: match$1[0],
                      x2: match$2[0],
                      y1: match$1[1],
                      y2: match$2[1]
                    }),
                JsxRuntime.jsx(AxesModel$Svg$Base, {
                      baseFront: baseFront,
                      baseBack: baseBack,
                      classList: baseClassList
                    }),
                JsxRuntime.jsx(AxesModel$Svg$Side, {
                      baseFront: baseFront,
                      side: side,
                      classList: sideClassList
                    }),
                LumiReact.renderOption(undefined, match.up, (function (up) {
                        return JsxRuntime.jsx(AxesModel$Svg$Up, {
                                    baseFront: baseFront,
                                    side: side,
                                    sideLength: sideLength,
                                    up: up,
                                    classList: upClassList
                                  });
                      }))
              ],
              className: "axes-model"
            });
}

var Svg = {
  scale: scale,
  Base: Base,
  Side: Side,
  Up: Up,
  make: AxesModel$Svg
};

export {
  up ,
  transform ,
  warpAffine ,
  elongateBase ,
  jsonCodec ,
  getScale ,
  toPoly ,
  extraPoints ,
  mainPoint ,
  Svg ,
}
/*  Not a pure module */
