import Cookie from "js-cookie";

// Proxy bindings because of https://github.com/evanw/esbuild/issues/1668

const get = Cookie.get
const set = Cookie.set
const remove = Cookie.remove

export {
  get,
  remove,
  set
}
