// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as LumiReact from "lumi-rescript/lib/es6/src/LumiReact.bs.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Application$Dashboard from "./Application.bs.mjs";
import * as Localization$LumiI18n from "lumi-i18n/lib/es6/src/Localization.bs.mjs";
import * as Barrier$LumiAuthentication from "lumi-authentication/lib/es6/src/Barrier.bs.mjs";

function Stallingsnet(props) {
  return JsxRuntime.jsx(React.StrictMode, {
              children: JsxRuntime.jsx(LumiReact.CurrentTime.Provider.make, {
                    children: JsxRuntime.jsx(Localization$LumiI18n.CookieProvider.make, {
                          locales: [
                            "NLD",
                            "ENG",
                            "DEU",
                            "FRA"
                          ],
                          default: "ENG",
                          children: JsxRuntime.jsx(Barrier$LumiAuthentication.make, {
                                children: JsxRuntime.jsx(Application$Dashboard.make, {})
                              })
                        })
                  })
            });
}

var make = Stallingsnet;

export {
  make ,
}
/* react Not a pure module */
