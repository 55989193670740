// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";
import * as LocalePicker$LumiI18n from "lumi-i18n/lib/es6/src/LocalePicker.bs.mjs";
import * as I18n$LumiAuthentication from "./I18n.bs.mjs";

function Modal$Form(props) {
  var onSubmit = props.onSubmit;
  return JsxRuntime.jsxs("form", {
              children: [
                JsxRuntime.jsx("div", {
                      className: "modal-background"
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs("header", {
                              children: [
                                JsxRuntime.jsx("p", {
                                      children: JsxRuntime.jsx(I18n$LumiAuthentication.T.$$Text.make, {
                                            entry: props.title
                                          }),
                                      className: "modal-card-title"
                                    }),
                                JsxRuntime.jsx(LocalePicker$LumiI18n.make, {})
                              ],
                              className: "modal-card-head"
                            }),
                        props.children
                      ],
                      className: "modal-card"
                    })
              ],
              className: "modal is-active",
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  onSubmit($$event);
                })
            });
}

var Form = {
  make: Modal$Form
};

function Modal(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      className: "modal-background"
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs("header", {
                              children: [
                                JsxRuntime.jsx("p", {
                                      children: JsxRuntime.jsx(I18n$LumiAuthentication.T.$$Text.make, {
                                            entry: props.title
                                          }),
                                      className: "modal-card-title"
                                    }),
                                JsxRuntime.jsx(LocalePicker$LumiI18n.make, {})
                              ],
                              className: "modal-card-head"
                            }),
                        props.children
                      ],
                      className: "modal-card"
                    })
              ],
              className: "modal is-active"
            });
}

var T;

var make = Modal;

export {
  T ,
  Form ,
  make ,
}
/* react/jsx-runtime Not a pure module */
