// Generated by ReScript, PLEASE EDIT WITH CARE

import * as D3 from "d3";

function toM23f(transform) {
  return [
          [
            transform.k,
            0,
            transform.x
          ],
          [
            0,
            transform.k,
            transform.y
          ]
        ];
}

function toM33f(transform) {
  return [
          [
            transform.k,
            0,
            transform.x
          ],
          [
            0,
            transform.k,
            transform.y
          ],
          [
            0,
            0,
            1
          ]
        ];
}

function fromM23f(param) {
  var match = param[0];
  return new D3.ZoomTransform(match[0], match[2], param[1][2]);
}

function fromM33f(param) {
  var match = param[0];
  return new D3.ZoomTransform(match[0], match[2], param[1][2]);
}

var Transform = {
  toM23f: toM23f,
  toM33f: toM33f,
  fromM23f: fromM23f,
  fromM33f: fromM33f
};

var Zoom = {
  Transform: Transform
};

export {
  Zoom ,
}
/* d3 Not a pure module */
