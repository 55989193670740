// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DateFns from "lumi-rescript/lib/es6/src/DateFns.bs.mjs";
import * as LumiReact from "lumi-rescript/lib/es6/src/LumiReact.bs.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Locale$LumiI18n from "./Locale.bs.mjs";
import Format from "date-fns/format";
import * as JsxRuntime from "react/jsx-runtime";
import FormatDistance from "date-fns/formatDistance";
import FormatDistanceStrict from "date-fns/formatDistanceStrict";

function useContext() {
  var locale = Locale$LumiI18n.useContext();
  return Locale$LumiI18n.toDateFnsLocale(locale);
}

var DateFnsLocale = {
  useContext: useContext
};

function DateTime$Format(props) {
  var locale = useContext();
  return Format(props.date, props.format, {
              locale: Caml_option.some(locale),
              weekStartsOn: props.weekStartsOn,
              firstWeekContainsDate: props.firstWeekContainsDate,
              useAdditionalWeekYearTokens: props.useAdditionalWeekYearTokens,
              useAdditionalDayOfYearTokens: props.useAdditionalDayOfYearTokens
            });
}

var Format$1 = {
  make: DateTime$Format
};

function DateTime$DateOnly(props) {
  return JsxRuntime.jsx(DateTime$Format, {
              date: props.date,
              format: DateFns.Formats.dateOnly
            });
}

var DateOnly = {
  make: DateTime$DateOnly
};

function DateTime$Short(props) {
  return JsxRuntime.jsx(DateTime$Format, {
              date: props.date,
              format: DateFns.Formats.$$short
            });
}

var Short = {
  make: DateTime$Short
};

function DateTime$Long(props) {
  return JsxRuntime.jsx(DateTime$Format, {
              date: props.date,
              format: DateFns.Formats.$$long
            });
}

var Long = {
  make: DateTime$Long
};

function DateTime$Full(props) {
  return JsxRuntime.jsx(DateTime$Format, {
              date: props.date,
              format: DateFns.Formats.full
            });
}

var Full = {
  make: DateTime$Full
};

function DateTime$DistanceToNow(props) {
  var locale = useContext();
  var now = LumiReact.CurrentTime.useCurrentTime();
  return FormatDistance(props.date, now, {
              includeSeconds: props.includeSeconds,
              addSuffix: props.addSuffix,
              locale: Caml_option.some(locale)
            });
}

var DistanceToNow = {
  make: DateTime$DistanceToNow
};

function DateTime$Distance(props) {
  var locale = useContext();
  return FormatDistance(props.date, props.baseDate, {
              includeSeconds: props.includeSeconds,
              addSuffix: props.addSuffix,
              locale: Caml_option.some(locale)
            });
}

var Distance = {
  make: DateTime$Distance
};

function DateTime$DistanceStrict(props) {
  var locale = useContext();
  return FormatDistanceStrict(props.date, props.baseDate, {
              addSuffix: props.addSuffix,
              unit: props.unit,
              roundingMethod: props.roundingMethod,
              locale: Caml_option.some(locale)
            });
}

var DistanceStrict = {
  make: DateTime$DistanceStrict
};

export {
  DateFnsLocale ,
  Format$1 as Format,
  DateOnly ,
  Short ,
  Long ,
  Full ,
  DistanceToNow ,
  Distance ,
  DistanceStrict ,
}
/* LumiReact Not a pure module */
