// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as V2f$Linear from "./V2f.bs.mjs";
import * as Rect$Linear from "./Rect.bs.mjs";

var zero = {
  pos: V2f$Linear.zero,
  size: V2f$Linear.zero
};

function fromSize(size) {
  return {
          pos: [
            0,
            0
          ],
          size: size
        };
}

function contains(param, point) {
  var match = param.size;
  var match$1 = V2f$Linear.minus(point, param.pos);
  var y = match$1[1];
  var x = match$1[0];
  if (x >= 0 && x <= match[0] && y >= 0) {
    return y <= match[1];
  } else {
    return false;
  }
}

function bounds(param) {
  var pos = param.pos;
  return [
          pos,
          V2f$Linear.plus(pos, param.size)
        ];
}

function clamp(rect, param) {
  var match = bounds(rect);
  var match$1 = match[1];
  var match$2 = match[0];
  return [
          Math.max(match$2[0], Math.min(match$1[0], param[0])),
          Math.max(match$2[1], Math.min(match$1[1], param[1]))
        ];
}

function scaleCenter(param, factor) {
  var size = param.size;
  return {
          pos: V2f$Linear.minus(param.pos, V2f$Linear.scale(size, (factor - 1) / 2)),
          size: V2f$Linear.scale(size, factor)
        };
}

function rotate90(param) {
  var match = param.size;
  var w = match[0];
  var match$1 = param.pos;
  return {
          pos: [
            match$1[1],
            match$1[0] - w
          ],
          size: [
            match[1],
            w
          ]
        };
}

function rotate180(param) {
  var match = param.size;
  var h = match[1];
  var w = match[0];
  var match$1 = param.pos;
  return {
          pos: [
            -1 * (match$1[0] + w),
            -1 * (match$1[1] + h)
          ],
          size: [
            w,
            h
          ]
        };
}

function rotate270(param) {
  var match = param.size;
  var h = match[1];
  var match$1 = param.pos;
  return {
          pos: [
            -1 * (match$1[1] + h),
            -1 * match$1[0]
          ],
          size: [
            h,
            match[0]
          ]
        };
}

function center(param) {
  return V2f$Linear.plus(param.pos, V2f$Linear.scale(param.size, 0.5));
}

function points(param) {
  var match = param.size;
  var h = match[1];
  var w = match[0];
  var match$1 = param.pos;
  var y = match$1[1];
  var x = match$1[0];
  return [
          [
            x,
            y
          ],
          [
            x + w,
            y
          ],
          [
            x + w,
            y + h
          ],
          [
            x,
            y + h
          ]
        ];
}

var jsonCodec = Rect$Linear.jsonCodec(Jzon.$$float);

export {
  zero ,
  fromSize ,
  contains ,
  bounds ,
  clamp ,
  scaleCenter ,
  rotate90 ,
  rotate180 ,
  rotate270 ,
  center ,
  points ,
  jsonCodec ,
}
/* jsonCodec Not a pure module */
