// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as LumiRequest from "lumi-rescript/lib/es6/src/LumiRequest.bs.mjs";
import * as LumiReactReducer from "lumi-rescript/lib/es6/src/LumiReactReducer.bs.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as API$LumiAuthentication from "./API.bs.mjs";
import * as Form$LumiAuthentication from "./Form.bs.mjs";
import * as I18n$LumiAuthentication from "./I18n.bs.mjs";
import * as Modal$LumiAuthentication from "./Modal.bs.mjs";

function apiKeyFromUrl(url) {
  return Caml_option.nullable_to_opt(new URLSearchParams(url.search).get("apiKey"));
}

function APIKeyLogin$Error(props) {
  var dispatch = props.dispatch;
  return JsxRuntime.jsxs(Modal$LumiAuthentication.make, {
              title: "PhraseInvalidAPIKey",
              children: [
                JsxRuntime.jsx("section", {
                      children: JsxRuntime.jsx(Form$LumiAuthentication.$$Error.make, {
                            error: props.error
                          }),
                      className: "modal-card-body"
                    }),
                JsxRuntime.jsx("footer", {
                      children: JsxRuntime.jsxs("div", {
                            children: [
                              props.forceAPIKey ? null : JsxRuntime.jsx("p", {
                                      children: JsxRuntime.jsx("button", {
                                            children: JsxRuntime.jsx(I18n$LumiAuthentication.T.$$Text.make, {
                                                  entry: "PhraseToLogin"
                                                }),
                                            className: "button",
                                            type: "button",
                                            onClick: (function (param) {
                                                dispatch("ToPasswordLogin");
                                              })
                                          }),
                                      className: "control"
                                    }),
                              JsxRuntime.jsx("p", {
                                    children: JsxRuntime.jsx("button", {
                                          children: JsxRuntime.jsx(I18n$LumiAuthentication.T.$$Text.make, {
                                                entry: "PhraseRetry"
                                              }),
                                          className: "button is-success",
                                          type: "button",
                                          onClick: (function (param) {
                                              dispatch("Retry");
                                            })
                                        }),
                                    className: "control"
                                  })
                            ],
                            className: "field is-grouped is-grouped-right is-flex-1"
                          }),
                      className: "modal-card-foot"
                    })
              ]
            });
}

var $$Error = {
  make: APIKeyLogin$Error
};

function APIKeyLogin(props) {
  var toParent = props.toParent;
  var apiKey = props.apiKey;
  var tryPerformLogin = function () {
    return {
            TAG: "InitWithEffect",
            _0: {
              lastError: undefined
            },
            _1: (function (param) {
                var dispatch = param.dispatch;
                return LumiRequest.resultFutureEffect(API$LumiAuthentication.cookieLoginApiKey(apiKey), (function (account) {
                              toParent({
                                    TAG: "Authenticated",
                                    _0: account
                                  });
                            }), (function (err) {
                              dispatch({
                                    TAG: "APIKeyLoginFailed",
                                    _0: err
                                  });
                            }));
              })
          };
  };
  var match = LumiReactReducer.useReducer((function () {
          return tryPerformLogin();
        }), undefined, (function (_state, action) {
          if (typeof action !== "object") {
            if (action === "ToPasswordLogin") {
              return {
                      TAG: "Effect",
                      _0: (function (param) {
                          toParent({
                                TAG: "ShowPasswordLogin",
                                initialEmail: "",
                                initialError: undefined
                              });
                        })
                    };
            } else {
              return LumiReactReducer.initToUpdate(tryPerformLogin());
            }
          }
          console.error("API key login failed", action._0);
          return {
                  TAG: "Update",
                  _0: {
                    lastError: "PhraseInvalidAPIKey"
                  }
                };
        }));
  var lastError = match[0].lastError;
  if (lastError !== undefined) {
    return JsxRuntime.jsx(APIKeyLogin$Error, {
                error: lastError,
                forceAPIKey: props.forceAPIKey,
                dispatch: match[1]
              });
  } else {
    return null;
  }
}

var T;

var make = APIKeyLogin;

export {
  T ,
  apiKeyFromUrl ,
  $$Error ,
  make ,
}
/* LumiRequest Not a pure module */
