// Generated by ReScript, PLEASE EDIT WITH CARE

import * as LumiOption from "./LumiOption.bs.mjs";
import * as Dom_storage from "rescript/lib/es6/dom_storage.js";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.bs.mjs";

function remove(key) {
  Dom_storage.removeItem(key, localStorage);
}

function clear() {
  localStorage.clear();
}

function get(storable) {
  return Core__Option.getOr(Core__Option.flatMap(Dom_storage.getItem(storable.key, localStorage), (function (str) {
                    return LumiOption.forNone(storable.stringCodec.fromString(str), (function () {
                                  console.error("Failed to parse item at " + storable.key + ": " + str);
                                }));
                  })), storable.default);
}

function set(storable, value) {
  Dom_storage.setItem(storable.key, storable.stringCodec.toString(value), localStorage);
}

function update(storable, f) {
  set(storable, f(get(storable)));
}

export {
  remove ,
  clear ,
  get ,
  set ,
  update ,
}
/* No side effect */
