// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as LumiDate from "lumi-rescript/lib/es6/src/LumiDate.bs.mjs";
import * as LumiJzon from "lumi-rescript/lib/es6/src/LumiJzon.bs.mjs";
import * as LumiSort from "lumi-rescript/lib/es6/src/LumiSort.bs.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as LumiRequest from "lumi-rescript/lib/es6/src/LumiRequest.bs.mjs";
import * as Document$LumiStore from "lumi-store/lib/es6/src/Document.bs.mjs";
import * as Prelude$LumiCentral from "./Prelude.bs.mjs";
import * as Sensor$LumiDocuments from "lumi-documents/lib/es6/src/Sensor.bs.mjs";
import * as Facility$LumiDocuments from "lumi-documents/lib/es6/src/Facility.bs.mjs";
import * as Statistics$LumiCentral from "./Statistics.bs.mjs";
import * as PlaceClass$LumiDocuments from "lumi-documents/lib/es6/src/PlaceClass.bs.mjs";
import * as FacilityMap$LumiDocuments from "lumi-documents/lib/es6/src/FacilityMap.bs.mjs";
import * as SensorState$LumiDocuments from "lumi-documents/lib/es6/src/SensorState.bs.mjs";
import * as Request$LumiAuthentication from "lumi-authentication/lib/es6/src/Request.bs.mjs";
import * as SensorGroups$LumiStaticData from "lumi-static-data/lib/es6/src/SensorGroups.bs.mjs";
import * as OccupationDetails$LumiCentral from "./OccupationDetails.bs.mjs";

var jsonCodec = Jzon.object3((function (param) {
        return [
                param.facility,
                param.maps,
                param.groups
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  facility: param[0],
                  maps: param[1],
                  groups: param[2]
                }
              };
      }), Jzon.field("facility", Prelude$LumiCentral.documentCodec(Facility$LumiDocuments.Facility.jsonCodec)), Jzon.field("maps", LumiJzon.map(Document$LumiStore.Key.keyCodec, FacilityMap$LumiDocuments.FacilityMapInfo.jsonCodec, FacilityMap$LumiDocuments.Schema.CmpKey)), Jzon.field("groups", Jzon.array(Statistics$LumiCentral.JsonCodecs.namedGroup)));

function sortedMaps(maps) {
  return LumiSort.stableSortOn(Belt_Map.toArray(maps), (function (param) {
                return FacilityMap$LumiDocuments.FacilityMapInfo.sortKey(param[1]);
              }));
}

var FacilityInfo = {
  jsonCodec: jsonCodec,
  sortedMaps: sortedMaps
};

var jsonCodec$1 = Jzon.object2((function (param) {
        return [
                param.name,
                param.placeClass
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  name: param[0],
                  placeClass: param[1]
                }
              };
      }), Jzon.field("name", Jzon.string), Jzon.field("placeClass", PlaceClass$LumiDocuments.PlaceClass.jsonCodec));

var SensorInfo = {
  jsonCodec: jsonCodec$1
};

function encode(d) {
  return d.toISOString();
}

function decode(str) {
  var x = new Date(str);
  if (isNaN(x.getTime())) {
    return {
            TAG: "Error",
            _0: {
              NAME: "UnexpectedJsonValue",
              VAL: [
                [],
                str
              ]
            }
          };
  } else {
    return {
            TAG: "Ok",
            _0: x
          };
  }
}

var dateCodec = LumiJzon.keyCustom({
      TAG: "ToJsonKeyString",
      _0: encode
    }, {
      TAG: "FromJsonKeyString",
      _0: decode
    });

var getOccupationImagesResponse = LumiJzon.map(dateCodec, Jzon.string, LumiDate.Cmp);

var Codecs = {
  dateCodec: dateCodec,
  getOccupationImagesResponse: getOccupationImagesResponse
};

function getFacilities() {
  return LumiRequest.expectJSON(Request$LumiAuthentication.make("/api/stallingsnet/facilities", "GET", undefined, undefined, undefined), Jzon.array(jsonCodec));
}

function getFacilityMap(facilityMapKey) {
  var facilityMapKeyString = Document$LumiStore.Key.toString(facilityMapKey);
  return LumiRequest.expectJSON(Request$LumiAuthentication.make("/api/stallingsnet/facility-map/" + facilityMapKeyString, "GET", undefined, undefined, undefined), LumiJzon.tuple2(Prelude$LumiCentral.documentCodec(FacilityMap$LumiDocuments.FacilityMap.jsonCodec), LumiJzon.map(Document$LumiStore.Key.keyCodec, jsonCodec$1, Sensor$LumiDocuments.Schema.CmpKey)));
}

function getFacilityMapStatus(facilityMapKey) {
  var facilityMapKeyString = Document$LumiStore.Key.toString(facilityMapKey);
  return LumiRequest.expectJSON(Request$LumiAuthentication.make("/api/stallingsnet/facility-map/" + facilityMapKeyString + "/status", "GET", undefined, undefined, undefined), Jzon.array(SensorState$LumiDocuments.SensorState.jsonCodec));
}

function getOccupationHistory(sensorKey) {
  var sensorKeyString = Document$LumiStore.Key.toString(sensorKey);
  return LumiRequest.expectJSON(Request$LumiAuthentication.make("/api/stallingsnet/sensor/" + sensorKeyString + "/occupation-history", "GET", undefined, undefined, undefined), OccupationDetails$LumiCentral.OccupationHistory.jsonCodec);
}

function getOccupationSnapshots(occupationKey) {
  var occupationKeyString = Document$LumiStore.Key.toString(occupationKey);
  return LumiRequest.expectJSON(Request$LumiAuthentication.make("/api/stallingsnet/occupation/" + occupationKeyString + "/snapshots", "GET", undefined, undefined, undefined), Jzon.array(OccupationDetails$LumiCentral.OccupationDetailsSnapshot.jsonCodec));
}

function formatGroupStatisticsParams(groupFullId, begin, end, interval) {
  return [
          SensorGroups$LumiStaticData.GroupFullId.toString(groupFullId),
          begin.toISOString(),
          end.toISOString(),
          interval === "week" ? "1W" : (
              interval === "day" ? "1D" : (
                  interval === "month" ? "1M" : (
                      interval === "quarterHour" ? "15m" : "1h"
                    )
                )
            )
        ];
}

function getSensorGroupStateHistoryAggregates(groupFullId, begin, end, interval) {
  var match = formatGroupStatisticsParams(groupFullId, begin, end, interval);
  return LumiRequest.expectJSON(Request$LumiAuthentication.make("/api/stallingsnet/stats/group/" + match[0] + "/" + match[1] + "/" + match[2] + "/" + match[3], "GET", undefined, undefined, undefined), Jzon.array(Statistics$LumiCentral.JsonCodecs.sensorGroupStateHistoryAggregateRow));
}

function downloadSensorGroupStateHistoryAggregates(groupFullId, begin, end, interval, contentType) {
  var match = formatGroupStatisticsParams(groupFullId, begin, end, interval);
  var accept = contentType === "JSON" ? "application/json;charset=utf-8" : (
      contentType === "XLSX" ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" : "text/csv;charset=utf-8"
    );
  return LumiRequest.expectBody(Request$LumiAuthentication.make("/api/stallingsnet/stats/group/" + match[0] + "/" + match[1] + "/" + match[2] + "/" + match[3], "GET", Caml_option.some(Object.fromEntries([[
                            "Accept",
                            accept
                          ]])), undefined, undefined), (function (prim) {
                return prim.blob();
              }));
}

export {
  FacilityInfo ,
  SensorInfo ,
  Codecs ,
  getFacilities ,
  getFacilityMap ,
  getFacilityMapStatus ,
  getOccupationHistory ,
  getOccupationSnapshots ,
  formatGroupStatisticsParams ,
  getSensorGroupStateHistoryAggregates ,
  downloadSensorGroupStateHistoryAggregates ,
}
/* jsonCodec Not a pure module */
