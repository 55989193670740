// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as LumiJzon from "lumi-rescript/lib/es6/src/LumiJzon.bs.mjs";
import * as RightAngle$LumiDocuments from "./Types/RightAngle.bs.mjs";

var jsonCodec = Jzon.object5((function (param) {
        return [
                param.frameRate,
                param.colorDepth,
                param.brightness,
                param.backlightBrightness,
                param.smallestSubFrameExposure
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  frameRate: param[0],
                  colorDepth: param[1],
                  brightness: param[2],
                  backlightBrightness: param[3],
                  smallestSubFrameExposure: param[4]
                }
              };
      }), Jzon.field("framerate", Jzon.nullable(Jzon.$$int)), Jzon.field("color_depth", Jzon.$$int), Jzon.field("brightness", Jzon.$$int), Jzon.field("backlight_brightness", Jzon.$$int), Jzon.field("smallest_sub_frame_exposure", Jzon.$$int));

var ScreenSettings = {
  jsonCodec: jsonCodec
};

var jsonCodec$1 = Jzon.object4((function (param) {
        return [
                param.name,
                param.x,
                param.y,
                param.rotation
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  name: param[0],
                  x: param[1],
                  y: param[2],
                  rotation: param[3]
                }
              };
      }), Jzon.default(Jzon.field("name", Jzon.string), ""), Jzon.field("x", Jzon.$$int), Jzon.field("y", Jzon.$$int), Jzon.field("rotate", RightAngle$LumiDocuments.RightAngle.jsonCodec));

var Panel = {
  jsonCodec: jsonCodec$1
};

function panelSize(param) {
  switch (param.panelType) {
    case "P2" :
    case "P2-bgr" :
    case "P2_Neude" :
        return [
                128,
                64
              ];
    case "P3" :
    case "P4-bgr" :
    case "P4-bgr-slow" :
    case "P4-rgb" :
    case "P4-rgb-JXS-P4-2018-C" :
    case "P5" :
    case "P5-longcable" :
    case "P5-outdoor" :
        return [
                64,
                32
              ];
    case "P5-sawed-in-half" :
    case "P5-sawed-in-half-bgr" :
    case "P5-square" :
        return [
                32,
                32
              ];
    case "P8" :
        return [
                32,
                16
              ];
    default:
      return ;
  }
}

var jsonCodec$2 = Jzon.object3((function (param) {
        return [
                param.panelType,
                param.screenSettings,
                param.panels
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  panelType: param[0],
                  screenSettings: param[1],
                  panels: param[2]
                }
              };
      }), Jzon.field("panel_type", Jzon.string), Jzon.field("screen_settings", jsonCodec), Jzon.field("panels", LumiJzon.mapString(Jzon.array(jsonCodec$1))));

var SignSettings = {
  panelSize: panelSize,
  jsonCodec: jsonCodec$2
};

export {
  ScreenSettings ,
  Panel ,
  SignSettings ,
}
/* jsonCodec Not a pure module */
