// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as React from "react";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as LumiDate from "lumi-rescript/lib/es6/src/LumiDate.bs.mjs";
import * as LumiReact from "lumi-rescript/lib/es6/src/LumiReact.bs.mjs";
import * as Caml_int32 from "rescript/lib/es6/caml_int32.js";
import * as LumiOption from "lumi-rescript/lib/es6/src/LumiOption.bs.mjs";
import * as FileSaver from "file-saver";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/lib/es6/src/Core__Array.bs.mjs";
import * as FontAwesome from "lumi-rescript/lib/es6/src/FontAwesome.bs.mjs";
import * as LumiRequest from "lumi-rescript/lib/es6/src/LumiRequest.bs.mjs";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.bs.mjs";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as Locale$LumiI18n from "lumi-i18n/lib/es6/src/Locale.bs.mjs";
import * as ReactChartjs from "react-chartjs-2";
import * as LumiReactReducer from "lumi-rescript/lib/es6/src/LumiReactReducer.bs.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Document$LumiStore from "lumi-store/lib/es6/src/Document.bs.mjs";
import * as Translation$Dashboard from "./Translation.bs.mjs";
import * as Statistics$LumiCentral from "lumi-central/lib/es6/src/Statistics.bs.mjs";
import * as Stallingsnet$LumiCentral from "lumi-central/lib/es6/src/Stallingsnet.bs.mjs";
import * as StatisticsTypes$Dashboard from "./StatisticsTypes.bs.mjs";
import * as Barrier$LumiAuthentication from "lumi-authentication/lib/es6/src/Barrier.bs.mjs";
import * as SensorGroups$LumiStaticData from "lumi-static-data/lib/es6/src/SensorGroups.bs.mjs";

function getSelectedGroups(selectedGroups, groupsSettings) {
  return Belt_Map.keep(groupsSettings, (function (param, _settings) {
                return Belt_Set.has(selectedGroups, param.groupFullId);
              }));
}

function updateGroup(groupsSettings, group, f) {
  return Belt_Map.update(groupsSettings, group, (function (entry) {
                return Core__Option.map(entry, f);
              }));
}

var colors = [
  "#e41a1c",
  "#377eb8",
  "#4daf4a",
  "#984ea3",
  "#ff7f00",
  "#a65628",
  "#f781bf",
  "#999999"
];

function getNextColor(state) {
  var groupsWithColors = Belt_Map.size(Belt_Map.keep(state.groupsSettings, (function (param, param$1) {
              return Core__Option.isSome(param$1.color);
            })));
  var index = Caml_int32.mod_(groupsWithColors, colors.length);
  return colors[index];
}

function Statistics$GroupList(props) {
  var dispatch = props.dispatch;
  var selectedGroups = props.selectedGroups;
  var groupsSettings = props.groupsSettings;
  return JsxRuntime.jsxs("nav", {
              children: [
                JsxRuntime.jsx("p", {
                      children: JsxRuntime.jsx(Translation$Dashboard.Provider.$$Text.make, {
                            entry: "Facilities"
                          }),
                      className: "panel-heading"
                    }),
                JsxRuntime.jsx("div", {
                      children: Belt_SortArray.stableSortBy(props.facilityInfos, (function (param, param$1) {
                                var a = Document$LumiStore.contents(param.facility);
                                var b = Document$LumiStore.contents(param$1.facility);
                                return Caml.string_compare(a.name, b.name);
                              })).map(function (param) {
                            var facilityDoc = param.facility;
                            var facility = Document$LumiStore.contents(facilityDoc);
                            return [JsxRuntime.jsx("div", {
                                            children: facility.name,
                                            className: LumiReact.classList([
                                                  [
                                                    "panel-block",
                                                    true
                                                  ],
                                                  [
                                                    "is-unselectable",
                                                    true
                                                  ],
                                                  [
                                                    "has-text-weight-bold",
                                                    true
                                                  ]
                                                ])
                                          }, Document$LumiStore.Key.toString(facilityDoc.key))].concat(Belt_SortArray.stableSortBy(param.groups, (function (param, param$1) {
                                                return Caml.string_compare(param.name, param$1.name);
                                              })).map(function (group) {
                                            var selected = Belt_Set.has(selectedGroups, group.groupFullId);
                                            var action = selected ? ({
                                                  TAG: "DeselectGroup",
                                                  _0: group
                                                }) : ({
                                                  TAG: "SelectGroup",
                                                  _0: group
                                                });
                                            var color = Core__Option.getOr(Core__Option.flatMap(Belt_Map.get(groupsSettings, group), (function (param) {
                                                        return param.color;
                                                      })), "black");
                                            return JsxRuntime.jsxs("a", {
                                                        children: [
                                                          selected ? JsxRuntime.jsx("span", {
                                                                  children: JsxRuntime.jsx(FontAwesome.Solid.make, {
                                                                        name: "check-square"
                                                                      }),
                                                                  className: "panel-icon",
                                                                  style: {
                                                                    color: color
                                                                  }
                                                                }) : JsxRuntime.jsx("span", {
                                                                  children: JsxRuntime.jsx(FontAwesome.Regular.make, {
                                                                        name: "square"
                                                                      }),
                                                                  className: "panel-icon"
                                                                }),
                                                          group.name
                                                        ],
                                                        className: LumiReact.classList([
                                                              [
                                                                "panel-block",
                                                                true
                                                              ],
                                                              [
                                                                "is-unselectable",
                                                                true
                                                              ],
                                                              [
                                                                "is-active",
                                                                selected
                                                              ]
                                                            ]),
                                                        onClick: (function (param) {
                                                            dispatch(action);
                                                          })
                                                      }, SensorGroups$LumiStaticData.GroupFullId.toString(group.groupFullId));
                                          }));
                          }),
                      className: "facility-list-members scrollable"
                    })
              ],
              className: "panel has-scrollable-members"
            });
}

var GroupList = {
  make: Statistics$GroupList
};

function Statistics$Chart(props) {
  var metric = props.metric;
  var interval = props.interval;
  var locale = Locale$LumiI18n.useContext();
  var options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: "time",
        time: {
          tooltipFormat: interval === "week" ? "w (PPP)" : (
              interval === "day" ? "PPPP" : (
                  interval === "month" ? "MMMM yyyy" : "PPPPp"
                )
            )
        },
        adapters: {
          date: {
            locale: Locale$LumiI18n.toDateFnsLocale(locale)
          }
        }
      },
      y: metric === "occupancyPctMean" || metric === "occupancyPctMin" || metric === "occupancyPctMax" ? ({
            type: "linear",
            min: 0,
            max: 100
          }) : ({
            type: "linear"
          })
    },
    interaction: {
      mode: "nearest",
      axis: "x",
      intersect: false
    },
    plugins: {
      tooltip: {
        position: "nearest"
      }
    },
    pointHoverRadius: 10
  };
  var data = {
    datasets: Core__Array.filterMap(Belt_Map.toArray(getSelectedGroups(props.selectedGroups, props.groupsSettings)), (function (param) {
            var match = param[1];
            var color = match.color;
            var namedGroup = param[0];
            return Core__Option.map(match.statistics, (function (statistics) {
                          return {
                                  label: namedGroup.name,
                                  backgroundColor: color,
                                  borderColor: color,
                                  stepped: "before",
                                  data: statistics.map(function (param) {
                                        var agg = param[1];
                                        var capacity = Math.round(agg.freeMean + agg.occupiedMean + agg.errorMean);
                                        var occupiedMin = (capacity | 0) - agg.freeMax | 0;
                                        var occupiedMean = capacity - agg.freeMean;
                                        var occupiedMax = (capacity | 0) - agg.freeMin | 0;
                                        return [
                                                param[0],
                                                metric === "occupancyPctMin" ? occupiedMin / capacity * 100 : (
                                                    metric === "occupiedMean" ? occupiedMean : (
                                                        metric === "occupancyPctMean" ? occupiedMean / capacity * 100 : (
                                                            metric === "occupiedMax" ? occupiedMax : (
                                                                metric === "freeMean" ? agg.freeMean : (
                                                                    metric === "freeMin" ? agg.freeMin : occupiedMax / capacity * 100
                                                                  )
                                                              )
                                                          )
                                                      )
                                                  )
                                              ];
                                      })
                                };
                        }));
          }))
  };
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(ReactChartjs.Line, {
                    data: data,
                    options: options
                  }),
              className: "is-flex-1",
              style: {
                minWidth: "0",
                position: "relative"
              }
            });
}

var Chart = {
  make: Statistics$Chart
};

function Statistics$Controls$Field(props) {
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("label", {
                            children: JsxRuntime.jsx(Translation$Dashboard.Provider.$$Text.make, {
                                  entry: props.label
                                }),
                            className: "label"
                          }),
                      className: "panel-block"
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("div", {
                            children: props.children,
                            className: "control"
                          }),
                      className: "panel-block"
                    })
              ]
            });
}

var Field = {
  make: Statistics$Controls$Field
};

function Statistics$Controls(props) {
  var dispatch = props.dispatch;
  var singleGroup = props.singleGroup;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("p", {
                      children: JsxRuntime.jsx(Translation$Dashboard.Provider.$$Text.make, {
                            entry: "Criteria"
                          }),
                      className: "panel-heading"
                    }),
                JsxRuntime.jsx(Statistics$Controls$Field, {
                      label: "StartDate",
                      children: JsxRuntime.jsx("input", {
                            className: "input",
                            type: "date",
                            value: Core__Option.getOr(Core__Option.map(props.begin, LumiDate.dateWireFormat), ""),
                            onChange: (function (e) {
                                dispatch({
                                      TAG: "SetBegin",
                                      _0: Caml_option.nullable_to_opt(e.target.valueAsDate)
                                    });
                              })
                          })
                    }),
                JsxRuntime.jsx(Statistics$Controls$Field, {
                      label: "EndDate",
                      children: JsxRuntime.jsx("input", {
                            className: "input",
                            type: "date",
                            value: Core__Option.getOr(Core__Option.map(props.end, LumiDate.dateWireFormat), ""),
                            onChange: (function (e) {
                                dispatch({
                                      TAG: "SetEnd",
                                      _0: Caml_option.nullable_to_opt(e.target.valueAsDate)
                                    });
                              })
                          })
                    }),
                JsxRuntime.jsx(Statistics$Controls$Field, {
                      label: "Resolution",
                      children: JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx("select", {
                                  children: StatisticsTypes$Dashboard.intervals.map(function (interval) {
                                        return JsxRuntime.jsx("option", {
                                                    children: JsxRuntime.jsx(Translation$Dashboard.Provider.$$Text.make, {
                                                          entry: {
                                                            TAG: "Interval",
                                                            _0: interval
                                                          }
                                                        }),
                                                    value: interval
                                                  }, interval);
                                      }),
                                  value: props.interval,
                                  onChange: (function (e) {
                                      dispatch({
                                            TAG: "SetInterval",
                                            _0: e.target.value
                                          });
                                    })
                                }),
                            className: "select is-fullwidth"
                          })
                    }),
                JsxRuntime.jsx(Statistics$Controls$Field, {
                      label: "Data",
                      children: JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx("select", {
                                  children: StatisticsTypes$Dashboard.metrics.map(function (metric) {
                                        return JsxRuntime.jsx("option", {
                                                    children: JsxRuntime.jsx(Translation$Dashboard.Provider.$$Text.make, {
                                                          entry: {
                                                            TAG: "Metric",
                                                            _0: metric
                                                          }
                                                        }),
                                                    value: metric
                                                  }, metric);
                                      }),
                                  value: props.metric,
                                  onChange: (function (e) {
                                      dispatch({
                                            TAG: "SetMetric",
                                            _0: e.target.value
                                          });
                                    })
                                }),
                            className: "select is-fullwidth"
                          })
                    }),
                props.kiosk ? null : JsxRuntime.jsx(Statistics$Controls$Field, {
                        label: "Download",
                        children: singleGroup !== undefined ? JsxRuntime.jsxs("div", {
                                children: [
                                  JsxRuntime.jsxs("button", {
                                        children: [
                                          JsxRuntime.jsx("span", {
                                                children: JsxRuntime.jsx(FontAwesome.Solid.make, {
                                                      name: "file-csv"
                                                    }),
                                                className: "icon"
                                              }),
                                          JsxRuntime.jsx("span", {
                                                children: JsxRuntime.jsx(Translation$Dashboard.Provider.$$Text.make, {
                                                      entry: "CSV"
                                                    })
                                              })
                                        ],
                                        className: "button is-primary",
                                        type: "button",
                                        onClick: (function (param) {
                                            dispatch({
                                                  TAG: "Download",
                                                  _0: singleGroup,
                                                  _1: "CSV"
                                                });
                                          })
                                      }),
                                  JsxRuntime.jsxs("button", {
                                        children: [
                                          JsxRuntime.jsx("span", {
                                                children: JsxRuntime.jsx(FontAwesome.Solid.make, {
                                                      name: "file-excel"
                                                    }),
                                                className: "icon"
                                              }),
                                          JsxRuntime.jsx("span", {
                                                children: JsxRuntime.jsx(Translation$Dashboard.Provider.$$Text.make, {
                                                      entry: "Excel"
                                                    })
                                              })
                                        ],
                                        className: "button is-primary",
                                        type: "button",
                                        onClick: (function (param) {
                                            dispatch({
                                                  TAG: "Download",
                                                  _0: singleGroup,
                                                  _1: "XLSX"
                                                });
                                          })
                                      })
                                ],
                                className: "buttons"
                              }) : JsxRuntime.jsx(Translation$Dashboard.Provider.$$Text.make, {
                                entry: "SelectOneFacilityToDownloadStatistics"
                              })
                      })
              ],
              className: "panel"
            });
}

var Controls = {
  Field: Field,
  make: Statistics$Controls
};

function Statistics$Aggregates(props) {
  var groups = Core__Array.filterMap(Belt_Map.toArray(getSelectedGroups(props.selectedGroups, props.groupsSettings)), (function (param) {
          var namedGroup = param[0];
          return Core__Option.map(param[1].aggregate, (function (agg) {
                        return [
                                namedGroup,
                                agg
                              ];
                      }));
        }));
  var match = React.useState(function () {
        return Core__Option.map(groups[0], (function (param) {
                      return param[0].groupFullId;
                    }));
      });
  var setSelectedTab = match[1];
  var selectedTab = match[0];
  var displayed = groups.find(function (param) {
        return Caml_obj.equal(selectedTab, param[0].groupFullId);
      });
  var displayed$1 = displayed !== undefined ? displayed : groups[0];
  return LumiReact.renderOption(undefined, displayed$1, (function (param) {
                var agg = param[1];
                var namedGroup = param[0];
                return JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("p", {
                                    children: JsxRuntime.jsx(Translation$Dashboard.Provider.$$Text.make, {
                                          entry: "Aggregates"
                                        }),
                                    className: "panel-heading"
                                  }),
                              JsxRuntime.jsx("p", {
                                    children: groups.map(function (param) {
                                          var match = param[0];
                                          var groupFullId = match.groupFullId;
                                          return JsxRuntime.jsx("a", {
                                                      children: match.name,
                                                      className: LumiReact.classList([[
                                                              "is-active",
                                                              Caml_obj.equal(namedGroup.groupFullId, groupFullId)
                                                            ]]),
                                                      onClick: (function (param) {
                                                          setSelectedTab(function (param) {
                                                                return groupFullId;
                                                              });
                                                        })
                                                    }, SensorGroups$LumiStaticData.GroupFullId.toString(groupFullId));
                                        }),
                                    className: "panel-tabs"
                                  }),
                              JsxRuntime.jsxs("p", {
                                    children: [
                                      JsxRuntime.jsx("span", {
                                            children: JsxRuntime.jsx(FontAwesome.Solid.make, {
                                                  name: "traffic-light"
                                                }),
                                            className: "panel-icon has-text-free"
                                          }),
                                      JsxRuntime.jsx(Translation$Dashboard.Provider.$$Text.make, {
                                            entry: "FreePlaces"
                                          })
                                    ],
                                    className: "panel-block"
                                  }),
                              JsxRuntime.jsx("p", {
                                    children: JsxRuntime.jsx(Translation$Dashboard.Provider.$$Text.make, {
                                          entry: {
                                            TAG: "MinMaxMean",
                                            _0: agg.freeMin,
                                            _1: agg.freeMax,
                                            _2: agg.freeMean
                                          }
                                        }),
                                    className: "panel-block"
                                  }),
                              JsxRuntime.jsxs("p", {
                                    children: [
                                      JsxRuntime.jsx("span", {
                                            children: JsxRuntime.jsx(FontAwesome.Solid.make, {
                                                  name: "traffic-light"
                                                }),
                                            className: "panel-icon has-text-occupied"
                                          }),
                                      JsxRuntime.jsx(Translation$Dashboard.Provider.$$Text.make, {
                                            entry: "OccupiedPlaces"
                                          })
                                    ],
                                    className: "panel-block"
                                  }),
                              JsxRuntime.jsx("p", {
                                    children: JsxRuntime.jsx(Translation$Dashboard.Provider.$$Text.make, {
                                          entry: {
                                            TAG: "MinMaxMean",
                                            _0: agg.occupiedMin,
                                            _1: agg.occupiedMax,
                                            _2: agg.occupiedMean
                                          }
                                        }),
                                    className: "panel-block"
                                  })
                            ],
                            className: "panel"
                          });
              }));
}

var Aggregates = {
  make: Statistics$Aggregates
};

function Statistics(props) {
  var facilityInfos = props.facilityInfos;
  var toAuth = Barrier$LumiAuthentication.Dispatch.useContext();
  var loadGroupStatistics = function (param, group, dispatch) {
    var end = param.end;
    var begin = param.begin;
    if (begin !== undefined && end !== undefined) {
      return LumiRequest.resultFutureEffect(Stallingsnet$LumiCentral.getSensorGroupStateHistoryAggregates(group.groupFullId, Caml_option.valFromOption(begin), Caml_option.valFromOption(end), param.interval), (function (data) {
                    dispatch({
                          TAG: "GroupStatisticsReceived",
                          _0: group,
                          _1: data
                        });
                  }), (function (err) {
                    console.error("getGroupStatistics failed", err);
                    Barrier$LumiAuthentication.checkServerError(err, toAuth);
                  }));
    }
    
  };
  var reloadAll = function (param) {
    var dispatch = param.dispatch;
    var state = param.state;
    return LumiReact.effects(Belt_Map.keysToArray(getSelectedGroups(state.selectedGroups, state.groupsSettings)).map(function (group) {
                    return loadGroupStatistics(state, group, dispatch);
                  }));
  };
  var match = LumiReactReducer.useReducer((function () {
          var end = new Date();
          var begin = new Date(end.getTime());
          begin.setMonth(end.getMonth() - 1 | 0);
          return {
                  TAG: "Init",
                  _0: {
                    selectedGroups: Belt_Set.make(SensorGroups$LumiStaticData.GroupFullId.Cmp),
                    begin: Caml_option.some(begin),
                    end: Caml_option.some(end),
                    interval: "hour",
                    metric: "occupiedMax",
                    groupsSettings: Belt_Map.fromArray(facilityInfos.map(function (param) {
                                  return param.groups;
                                }).flat().map(function (group) {
                              return [
                                      group,
                                      {
                                        statistics: undefined,
                                        aggregate: undefined,
                                        color: undefined
                                      }
                                    ];
                            }), Statistics$LumiCentral.NamedGroupCmp)
                  }
                };
        }), undefined, (function (state, action) {
          switch (action.TAG) {
            case "SelectGroup" :
                var group = action._0;
                return {
                        TAG: "UpdateWithEffect",
                        _0: {
                          selectedGroups: Belt_Set.add(state.selectedGroups, group.groupFullId),
                          begin: state.begin,
                          end: state.end,
                          interval: state.interval,
                          metric: state.metric,
                          groupsSettings: updateGroup(state.groupsSettings, group, (function (settings) {
                                  return {
                                          statistics: settings.statistics,
                                          aggregate: settings.aggregate,
                                          color: LumiOption.orElse(settings.color, (function () {
                                                  return getNextColor(state);
                                                }))
                                        };
                                }))
                        },
                        _1: (function (param) {
                            var state = param.state;
                            if (Core__Option.isNone(Core__Option.flatMap(Belt_Map.get(state.groupsSettings, group), (function (settings) {
                                          return settings.statistics;
                                        })))) {
                              return loadGroupStatistics(state, group, param.dispatch);
                            }
                            
                          })
                      };
            case "DeselectGroup" :
                return {
                        TAG: "Update",
                        _0: {
                          selectedGroups: Belt_Set.remove(state.selectedGroups, action._0.groupFullId),
                          begin: state.begin,
                          end: state.end,
                          interval: state.interval,
                          metric: state.metric,
                          groupsSettings: state.groupsSettings
                        }
                      };
            case "GroupStatisticsReceived" :
                var data = action._1;
                return {
                        TAG: "Update",
                        _0: {
                          selectedGroups: state.selectedGroups,
                          begin: state.begin,
                          end: state.end,
                          interval: state.interval,
                          metric: state.metric,
                          groupsSettings: updateGroup(state.groupsSettings, action._0, (function (settings) {
                                  return {
                                          statistics: data,
                                          aggregate: Statistics$LumiCentral.aggregate(data.map(function (prim) {
                                                    return prim[1];
                                                  })),
                                          color: settings.color
                                        };
                                }))
                        }
                      };
            case "SetBegin" :
                return {
                        TAG: "UpdateWithEffect",
                        _0: {
                          selectedGroups: state.selectedGroups,
                          begin: action._0,
                          end: state.end,
                          interval: state.interval,
                          metric: state.metric,
                          groupsSettings: state.groupsSettings
                        },
                        _1: reloadAll
                      };
            case "SetEnd" :
                return {
                        TAG: "UpdateWithEffect",
                        _0: {
                          selectedGroups: state.selectedGroups,
                          begin: state.begin,
                          end: action._0,
                          interval: state.interval,
                          metric: state.metric,
                          groupsSettings: state.groupsSettings
                        },
                        _1: reloadAll
                      };
            case "SetInterval" :
                return {
                        TAG: "UpdateWithEffect",
                        _0: {
                          selectedGroups: state.selectedGroups,
                          begin: state.begin,
                          end: state.end,
                          interval: action._0,
                          metric: state.metric,
                          groupsSettings: state.groupsSettings
                        },
                        _1: reloadAll
                      };
            case "SetMetric" :
                return {
                        TAG: "Update",
                        _0: {
                          selectedGroups: state.selectedGroups,
                          begin: state.begin,
                          end: state.end,
                          interval: state.interval,
                          metric: action._0,
                          groupsSettings: state.groupsSettings
                        }
                      };
            case "Download" :
                var contentType = action._1;
                var group$1 = action._0;
                return {
                        TAG: "Effect",
                        _0: (function (param) {
                            var match = param.state;
                            var end = match.end;
                            var begin = match.begin;
                            if (begin !== undefined && end !== undefined) {
                              return LumiRequest.resultFutureEffect(Stallingsnet$LumiCentral.downloadSensorGroupStateHistoryAggregates(group$1.groupFullId, Caml_option.valFromOption(begin), Caml_option.valFromOption(end), match.interval, contentType), (function (data) {
                                            var match = contentType === "JSON" ? [
                                                "export.json",
                                                "application/json;charset=utf-8"
                                              ] : (
                                                contentType === "XLSX" ? [
                                                    "export.xlsx",
                                                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                  ] : [
                                                    "export.csv",
                                                    "text/csv;charset=utf-8"
                                                  ]
                                              );
                                            var blob = new Blob([data], {
                                                  type: match[1]
                                                });
                                            FileSaver.saveAs(blob, match[0]);
                                          }), (function (err) {
                                            console.error("downloadSensorGroupStateHistoryAggregates failed", err);
                                            Barrier$LumiAuthentication.checkServerError(err, toAuth);
                                          }));
                            }
                            
                          })
                      };
            
          }
        }));
  var dispatch = match[1];
  var match$1 = match[0];
  var groupsSettings = match$1.groupsSettings;
  var metric = match$1.metric;
  var interval = match$1.interval;
  var selectedGroups = match$1.selectedGroups;
  var selected = Belt_Map.keysToArray(getSelectedGroups(selectedGroups, groupsSettings));
  var singleGroup = selected.length !== 1 ? undefined : selected[0];
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(Statistics$GroupList, {
                            facilityInfos: facilityInfos,
                            groupsSettings: groupsSettings,
                            selectedGroups: selectedGroups,
                            dispatch: dispatch
                          }),
                      className: "column is-narrow sidebar"
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(Statistics$Chart, {
                            selectedGroups: selectedGroups,
                            groupsSettings: groupsSettings,
                            interval: interval,
                            metric: metric
                          }),
                      className: "column is-flex fill-available"
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(Statistics$Controls, {
                              kiosk: props.kiosk,
                              singleGroup: singleGroup,
                              begin: match$1.begin,
                              end: match$1.end,
                              interval: interval,
                              metric: metric,
                              dispatch: dispatch
                            }),
                        JsxRuntime.jsx(Statistics$Aggregates, {
                              selectedGroups: selectedGroups,
                              groupsSettings: groupsSettings
                            })
                      ],
                      className: "column is-2 is-flex-direction-column scrollable"
                    })
              ]
            });
}

var make = Statistics;

export {
  getSelectedGroups ,
  updateGroup ,
  colors ,
  getNextColor ,
  GroupList ,
  Chart ,
  Controls ,
  Aggregates ,
  make ,
}
/* react Not a pure module */
